import React, {useContext, useEffect, useState} from "react";
import {useHistory, withRouter} from "react-router";
import InfiniteScroll from 'react-infinite-scroller';

import {Spinner} from "@blueprintjs/core";

import VehicleOfferListItem from "../../components/VehicleOfferListItem/VehicleOfferListItem";

import {CustomerTypeContext} from "../../context/customer-type-context";

import {goVehisService} from "../../utils/axios";
import {sortTypes} from "./SortTypes";
import ConvertUtils from "../../utils/ConvertUtils";
import NewUsedButtons from "./NewUsedButtons";
import NotFoundOfferListItem from "../../components/VehicleOfferListItem/NotFoundOfferListItem";

function OfferList(props) {

    const initialSearchParams = () => {
        let urlSearchParams = new URLSearchParams(props.location.search);

        if (urlSearchParams.get("sortingType") == null) {
            urlSearchParams.set("sortingType", sortTypes[0].value);
        }

        return urlSearchParams;
    }

    const [offers, setOffers] = useState([]);
    const [newOffers, setNewOffers] = useState([]);
    const [searchParams, setSearchParams] = useState(() => {
        return initialSearchParams()
    });
    const [hasMoreOffers, setHasMoreOffers] = useState(true);
    const [customerType] = useContext(CustomerTypeContext);

    const history = useHistory();

    let infiniteScrollRef = null;

    const limit = 10;

    const loadOffers = (page) => {

        let start = (page - 1) * limit;
        let localSearchParams = searchParams;

        localSearchParams.set("start", start);
        localSearchParams.set("limit", limit);
        localSearchParams.set("customerType", customerType);

        goVehisService.get("/vehicles?" + localSearchParams.toString()).then(response => {
            let receivedOffers = response.data;

            setNewOffers(receivedOffers);

            if (offers.length === 0 && receivedOffers.length === 0) {
                setHasMoreOffers(false);
            }
        });
    };

    useEffect(() => {
        let urlSearchParams = initialSearchParams();
        setSearchParams(urlSearchParams);

        if (infiniteScrollRef != null) {

            infiniteScrollRef.pageLoaded = 0;
        }
        window.scrollTo(0, 0);

        setOffers([]);
        setHasMoreOffers(true);

    }, [props.location.search, customerType]);

    useEffect(() => {

        if (offers.length > 0 && newOffers.length < limit) {
            setHasMoreOffers(false);
        }

        if (newOffers.length > 0) {
            // set models without duplicates - filtering removes duplicates
            setOffers(prevOffers => [...prevOffers, ...newOffers].filter((v, i, a) => a.findIndex(t => (t.uuid === v.uuid)) === i));
        }
    }, [newOffers]);

    let separatelyOffers = offers.map(offer => (
            <VehicleOfferListItem
                isSliderOffer={false}
                showRibbons={props.viewType === "grid-view"}
                key={offer.uuid}
                url={ConvertUtils.stringToUrl(offer.brandName + "/" + offer.versionName + "/" + offer.vehicleIds[0])}
                offer={offer}
            />
        )
    );

    let offerItems = (
        <React.Fragment>
            {separatelyOffers}
            <NotFoundOfferListItem />
        </React.Fragment>
    )

    if (hasMoreOffers === false && offers.length === 0) {
        offerItems = (
            <h4>Niestety nie znaleziono samochodu dla podanych kryteriów.<br/>
                <a href={props.location.pathname}>Zobacz wszystkie oferty</a>
            </h4>
        );
    }

    return (
        <div id="content">
            <div className="container">
                <NewUsedButtons setSearchParameters={(params) => props.setSearchParameters(params)}
                                searchParameters={props.searchParameters}/>

                <InfiniteScroll
                    ref={(scroll) => {
                        infiniteScrollRef = scroll;
                    }}
                    pageStart={0}
                    initialLoad={true}
                    loadMore={loadOffers}
                    hasMore={hasMoreOffers}
                    loader={<Spinner key={0}/>}
                >
                    <div id="offers-list" className={props.viewType}>
                        {offerItems}
                        {/*{hasMoreOffers === false && offers.length === 0 ? '' : <NotFoundOfferListItem />}*/}
                    </div>
                </InfiniteScroll>


            </div>
        </div>
    );
}

export default withRouter(OfferList);