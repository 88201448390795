import React, {useEffect} from "react";
import {goVehisService} from "../../utils/axios";
import ConvertUtils from "../../utils/ConvertUtils";
import {useHistory} from "react-router";

export default function AkolRedirectPage(props) {

    const history = useHistory();

    useEffect(() => {

        const queryParams = new URLSearchParams(window.location.search)
        let prefix = queryParams.get("akol_id").substring(0, 2);
        let id = parseInt(queryParams.get("akol_id").replace("o_", "").replace("d_", ""));
        if(prefix === "d_") {
            id = id + 2200000000;
        } else {
            id = id + 2100000000;
        }
        goVehisService("/vehicles/offers/" + id).then(response => {
            let vehicleOffer = response.data;
            if (vehicleOffer !== undefined) {

                let urlToOffer = ConvertUtils.stringToUrl(vehicleOffer.brandName + "/" + vehicleOffer.versionName + "/" + id);

                window.location.pathname = urlToOffer;
            }
        }).catch(error => {
            history.replace("/lista-ofert");
        })

    }, []);

    return (
        <React.Fragment>
            Trwa przekierowywanie...
        </React.Fragment>
    )
}
