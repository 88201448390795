import React, {Fragment, useContext, useEffect, useRef, useState} from "react";
import BaseInstallment from "./BaseInstallment";
import {initialAllowedCalculationParametersForCredit} from "./initialAllowedParametersForCredit";
import {goKredytService} from "../../../utils/axios";
import ContractLengthChooser from "./ContractLengthChooser";
import FeeSlider from "./FeeSlider";
import {CustomerTypeContext, isCustomerCompany} from "../../../context/customer-type-context";
import MathUtils from "../../../utils/MathUtils";
import InstallmentSubmitBox from "./InstallmentSubmitBox";
import analytics from "../../../utils/analytics";
import * as axios from "axios";
import ContactDialog from "../../../components/ContactDialog/ContactDialog";
import CreditCalculationSummary from "./CreditCalculationSummary";

function CreditCalculator(props) {

    const customerContext = useContext(CustomerTypeContext);
    const { forwardRef, useRef, useImperativeHandle } = React;
    const feeSliderRef= useRef();
    const [stepSize, setStepSize] = useState(500);

    useEffect(() => {
        setStepSize(feeSliderRef.current.getStepSize())
    },[]);

    let contractLengthRef = useRef(null);
    let initialFeeRef = useRef(null);
    const source = axios.CancelToken.source();

    let vehiclePrice = isCustomerCompany(customerContext) ? props.offer.minimumPriceGross : props.offer.minimumPriceGross;

    const [calculationResponse, setCalculationResponse] = useState({
            installment: props.offer.installmentGross,
            rrso: 0,
            loanCost: 0
    });

    const [isGwarancjaZwrotuActive, setIsGwarancjaZwrotuActive] = useState(props.offer.hasReturnGuarantee);
    let onGwarancjaZwrotuChange = props.onGwarancjaZwrotuChange;

    let defaultContractLength = props.initialCalculation ? props.initialCalculation.contractLength : 120;

    const [calculationParameters, setCalculationParameters] = useState(
        {
            financingAmount: props.offer.minimumPriceGross,
            contractLength: defaultContractLength,
            initialFeeValue: props.initialCalculation ? MathUtils.percentageOfValue(vehiclePrice, props.initialCalculation.initialFeeValueNet) : 10,
            productionYear: props.offer.manufactureYear
        }
    );

    useEffect(() => {
        goKredytService.post(props.calculatorUrl, calculationParameters, {
            headers: {
                'Content-Type': 'application/json',
            },
            cancelToken: source.token
        }).then(response => {
            let calculationResult = response.data;

            if (calculationResult !== undefined) {
                setCalculationResponse(calculationResult);
            }

        }).catch(error => {
            if (axios.isCancel(error)) {
                console.log("request cancelled: " + error.message);
            } else {
                console.log("another error happened: " + error.message);
            }
        });

        let currentState = (calculationParameters.insuranceLength !== 0)
            && calculationParameters.includeGapInsurance;

        setIsGwarancjaZwrotuActive(props.offer.hasReturnGuarantee && currentState);
        onGwarancjaZwrotuChange(props.offer.hasReturnGuarantee && currentState);

        return () => {
            source.cancel();
        };
    }, [calculationParameters, props.offer.hasReturnGuarantee, onGwarancjaZwrotuChange, isGwarancjaZwrotuActive]);


    const [allowedCalculationParameters, setAllowedCalculationParameters] = useState(initialAllowedCalculationParametersForCredit);

    useEffect(() => {
        goKredytService(props.allowedCalculationParametersUrl).then(response => {
            let allowedParameters = response.data;

            if (allowedParameters !== undefined) {
                setAllowedCalculationParameters(allowedParameters);
                contractLengthChangeHandler(allowedParameters.contractLengthMonths.at(-1))
            }
        });
    }, [props.offer.minimumPriceGross]);

    const contractLengthChangeHandler = (newContractLength) => {
        setCalculationParameters(prevState => {

            if (prevState.contractLength !== newContractLength) {
                analytics.sendEvent({
                    category: 'OL - zmiana miesiecy',
                    action: 'Klikniecie',
                    label: 'Kliknieto w ' + newContractLength,
                    value: 1
                });
            }

            return {
                ...prevState,
                contractLength: newContractLength,
            };
        });
    };

    const initialFeeChangeHandler = (initialFee) => {
            let initialFeePercentage = MathUtils.percentageOfValue(vehiclePrice, initialFee);
            setCalculationParameters(prevState => ({...prevState, initialFeeValue: initialFeePercentage}));
    };

    const goToContractLengthHandler = () => {
        analytics.sendEvent({
            category: 'OL',
            action: 'Klikniecie',
            label: 'Zmieniono OL',
            value: 1
        });

        window.scrollTo({
            behavior: 'smooth',
            top: contractLengthRef.current.offsetTop - 50
        });
    };

    const goToInitialFeeHandler = () => {
        analytics.sendEvent({
            category: 'OW',
            action: 'Klikniecie',
            label: 'Zmieniono OW',
            value: 1
        });

        window.scrollTo({
            behavior: 'smooth',
            top: initialFeeRef.current.offsetTop - 50
        });
    }

    const [isOpenContactDialog, setIsOpenContactDialog] = useState(false);

    const reservationClickHandler = () => {
        analytics.sendEvent({
            category: 'Zarezerwuj',
            action: 'Klikniecie',
            label: 'Kliknieto w Zarezerwuj',
            value: 1
        });

        let calculationOffer = {
            vehicle: props.offer,
            calculation: {
                vehicleValueNet: props.offer.minimumPriceNet,
                vehicleValueGross: props.offer.minimumPriceGross,
                initialFeeValueNet: 0,
                initialFeeValueGross: calculationParameters.initialFeeValue,
                installmentNet: 0,
                installmentGross: calculationResponse.installment,
                contractLength: calculationParameters.contractLength,
            }
        };

        props.reservationStrategy(true, calculationOffer);
    }

    const contactClickHandler = () => {
        setIsOpenContactDialog(true);

        analytics.sendEvent({
            category: 'Zapytaj',
            action: 'Klikniecie',
            label: 'Kliknieto w Zapytaj',
            value: 1
        });
    }

    return (
        <Fragment>
            <BaseInstallment
                installment={
                    {
                        net: calculationResponse.installment,
                        gross: calculationResponse.installment
                    }
                }
                offer={props.offer}
                label="Rata kredytu"
                isCreditCalculation={true}
            />

            <hr/>

            <form action="">

                <span ref={contractLengthRef}></span>
                <ContractLengthChooser
                    items={allowedCalculationParameters.contractLengthMonths}
                    defaultLength={allowedCalculationParameters.contractLengthMonths.at(-1)}
                    onChange={contractLengthChangeHandler}
                    label="Okres kredytu (ilość miesięcznych rat)"
                />

                <div ref={initialFeeRef} id="formOplata" className="form-item form-oplata">
                    <FeeSlider
                        ref={feeSliderRef}
                        infoCode="oplata-wstepna"
                        onChange={initialFeeChangeHandler}
                        defaultValue={MathUtils.valueOfPercentage(vehiclePrice, calculationParameters.initialFeeValue)}
                        value={MathUtils.valueOfPercentage(vehiclePrice, calculationParameters.initialFeeValue)}
                        brutto={!isCustomerCompany(customerContext)}
                        isCreditCalculation={true}
                        min={isCustomerCompany(customerContext) ?
                            allowedCalculationParameters.initialFeeValues.minimumInitialFeeGross : allowedCalculationParameters.initialFeeValues.minimumInitialFeeGross}
                        max={isCustomerCompany(customerContext) ?
                            allowedCalculationParameters.initialFeeValues.maximumInitialFeeGross - stepSize : allowedCalculationParameters.initialFeeValues.maximumInitialFeeGross - stepSize}
                    />
                </div>

                <hr/>
                <CreditCalculationSummary
                    isNew={props.offer.isNew}
                    calculationParameters={calculationParameters}
                    calculationResult={calculationResponse}
                    changeContractLength={goToContractLengthHandler}
                    changeInitialFee={goToInitialFeeHandler}
                    initialFeeValue={MathUtils.valueOfPercentage(vehiclePrice, calculationParameters.initialFeeValue)}
                />

                <InstallmentSubmitBox
                    installment={{
                        net: calculationResponse.installment,
                        gross: calculationResponse.installment
                    }}
                    onReservationClick={reservationClickHandler}
                    onSendMessageClick={contactClickHandler}
                    isCreditCalculation={true}
                />
            </form>
            <ContactDialog isOpen={isOpenContactDialog}
                           onClose={() => setIsOpenContactDialog(false)}
                           additionalMessage={"Dotyczy oferty: " + window.location.href}
            />
        </Fragment>
    );
}

export default CreditCalculator;