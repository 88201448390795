import React, {useEffect, useState} from "react";

function ButtonChooser({items, defaultValue, onChange, label, unit=""}){

    const transformValues = () => {
        return items.map(value => ({
                value: value,
                active: defaultValue === value
            })
        );
    }

    const [values, setValues] = useState(transformValues());

    let buttonWidthPercentage = Math.floor(100 / items.length);
    buttonWidthPercentage = buttonWidthPercentage < 33 && unit != "" ? 33 : buttonWidthPercentage;

    const updateValues = (newValue) => {
        let updatedValues = [...values].map(length => ({
            value: length.value,
            active: newValue === length.value
        }));


        setValues(updatedValues);
    }

    useEffect(() => {
        setValues(transformValues());

    }, [items]);


    const valueButtonClickHandler = (chosenValue) => {
        updateValues(chosenValue.value);
        onChange(chosenValue.value);
    };

    let buttons = values.map(buttonValue => (
        <button key={buttonValue.value}
                type="button"
                onClick={() => valueButtonClickHandler(buttonValue)}
                style={{flexBasis: "calc(" + buttonWidthPercentage + "% - 1rem)"}}
                className={(buttonValue.active ? "active" : "") + " form-button"}>
            {buttonValue.value} {unit}
        </button>
    ));

    return (
        <div className="form-item form-okres">
            <p><strong>{label}</strong></p>
            <div className="form-options flex flex-wrap">
                {buttons}
            </div>

        </div>
    );
}

export default ButtonChooser;