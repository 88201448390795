import React from 'react';
import Skeleton from "@material-ui/lab/Skeleton";

function BlogBanner({banner}) {

    return (
        <div className="blogBanner">
            <hr />
            <a href={banner ? banner.link : ""}>
                {banner ?
                    <img src={banner.bannerUrl} alt="" style={{width:"100%", height:"300px"}} /> :
                        <Skeleton variant="rect" width={"100%"} height={300} />
                }
            </a>
        </div>

    );
}

export default BlogBanner;