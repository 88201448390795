import React from 'react';
import {Icon} from "@blueprintjs/core";

function MobileCallButton(props) {
    return(
        <div className="mobile-phone-call-wrapper" id="mobile-phone-button">
            <button
                className="mobile-phone-button"
                onClick={props.clicked}
                style={{
                    borderRadius:"45px",
                    height:"50px",
                    width:"50px",
                }}
            >
                <Icon icon="phone" iconSize={28} style={{marginTop:"0.55rem", marginLeft:"10px"}}/>
            </button>
        </div>
    )
}

export default MobileCallButton;