import React, {Fragment, useRef, useState} from "react";
import {MenuItem, PopoverInteractionKind} from "@blueprintjs/core";
import {MultiSelect} from "@blueprintjs/select";

import arrowDown from "../../assets/images/icons/arrow_circle_bottom.svg";
import "./MultiSelect.css"
import MenuCheckbox from "../ProductMenu/MenuCheckbox";

function SimpleMultiSelect(props) {

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const selectRef = useRef(null);

    let showItemImage = true;
    if(props.showItemImage != null){
        showItemImage = props.showItemImage;
    }

    const itemRenderer = (item, {modifiers, handleClick, index}) => {
        if (!modifiers.matchesPredicate) {
            return null;
        }

        let isSelected = props.selectedItems.findIndex(selectedItem => selectedItem.name === item.name) > -1;

        let selectAllCheckbox = null;
        if (index === 0 ) {
            let isAllSelected = props.selectedItems.length === props.items.length;

            selectAllCheckbox = (
                <MenuCheckbox
                    key="all"
                    name="all"
                    checked={isAllSelected}
                    selected={props.allItemsSelected}>
                    Zaznacz wszystkie
                </MenuCheckbox>
            );

        }

        return (
            <Fragment key={item.name}>
                {selectAllCheckbox}
                <MenuCheckbox
                    name={item.name}
                    checked={isSelected}
                    image={showItemImage ? (<img src={item.image} alt={item.name}/>) : null}
                    selected={handleClick}>
                    {item.name}
                </MenuCheckbox>
            </Fragment>
        );
    };

    const filterItemHandler = (query, item, _index, exactMatch) => {
        const normalizedTitle = item.name.toLowerCase();
        const normalizedQuery = query.toLowerCase();

        if (exactMatch) {
            return normalizedTitle === normalizedQuery;
        } else {
            return `${normalizedTitle}`.indexOf(normalizedQuery) >= 0;
        }
    };

    const popoverInteractionHandler = (nextOpenState, event) => {
        requestAnimationFrame(() => {

            const isInputFocused = selectRef.current.input === document.activeElement;

            if (!isInputFocused || nextOpenState === false) {
                // input is no longer focused, we should close the popover
                setDropdownOpen(false);
            } else {
                setDropdownOpen(true);
            }

        });
    }

    const tagRendered = (item) => item.name;

    return (
        <MultiSelect
            ref={selectRef}
            className="multiSelect"
            itemRenderer={props.itemRenderer == null ? itemRenderer : props.itemRenderer}
            itemPredicate={props.itemPredicate == null ? filterItemHandler : props.itemPredicate}
            onItemSelect={props.itemSelected}
            onQueryChange={() => setDropdownOpen(true)}
            items={props.items}
            tagRenderer={tagRendered}
            noResults={<MenuItem disabled={true} text="Najpierw wybierz markę"/>}
            popoverProps={{
                isOpen: dropdownOpen,
                minimal: true,
                fill: true,
                usePortal: false,
                interactionKind: PopoverInteractionKind.CLICK,
                onInteraction: popoverInteractionHandler,
                onOpening: () => setDropdownOpen(true),
                onClosing: () => setDropdownOpen(false)
            }}
            placeholder={props.placeholder}
            tagInputProps={{
                className: "like-btn",
                onRemove: props.itemDeselected,
                rightElement: (<img className={"multiSelectDownArrow " + (dropdownOpen ? "open" : "")} src={arrowDown}
                                    onClick={() => setDropdownOpen(!dropdownOpen)} alt="open/close"/>),
                tagProps: {minimal: true},
                inputProps: {
                    readOnly:true
                }
            }}
            selectedItems={props.selectedItems}
            activeItem={null}

        />
    );
}

export default SimpleMultiSelect;