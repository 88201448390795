import React, {Fragment} from "react";

function InputErrorMessage({error, message}) {

    let errorMessage = "Pole jest wymagane";
    if (message) {
        errorMessage = message;
    }

    return (
        <Fragment>
            {error && <span className="input-error">{errorMessage}</span>}
        </Fragment>

    );
}

export default InputErrorMessage;