import React, {useState, useEffect} from 'react';

import {Container} from "@material-ui/core";

import PageTitle from "../../components/PageTitle/PageTitle";
import BlogDataAccess from "../../utils/BlogDataAccess";
import BlogPostTeaser from "../../components/BlogPost/BlogPostTeaser";
import BlogPostsGrid from "../../components/BlogPost/BlogPostsGrid";
import BlogBanner from "../../components/BlogPost/BlogBanner";


function BlogPage({backgroundImage}) {
    const [blogDataAccess, setBlogDataAccess] = useState(new BlogDataAccess())
    const [availableCategories, setAvailableCategories] = useState(new Map())
    const [banners, setBanners] = useState(new Array())

    useEffect(() => {
        blogDataAccess.getAvailableCategories().then(setAvailableCategories)
        blogDataAccess.getBanners().then(setBanners)
    }, [])

    const mapValuesToArray = (map) => {
        const values = new Array();

        for (const [key, value] of map.entries()) {
            values.push(value);
        }

        return values;
    }

    const getKeyByValue = (map, searchValue) => {
        for (let [key, value] of map.entries()) {
            if (value === searchValue)
                return key;
        }
    }

    return (
        <div id="content">
            <PageTitle title={'Blog'} content={null} backgroundImage={backgroundImage}/>

            <Container fixed className="container" style={{padding:"25px", width: "90%"}}>

                <BlogPostTeaser blogPost={blogDataAccess.getNewestPost()} />
                <BlogPostsGrid categoryName="najnowsze" categoryLink="najnowsze" blogPosts={blogDataAccess.getNewPostsLimited()} />
                <BlogBanner banner={banners[0]}/>

                {availableCategories ? mapValuesToArray(availableCategories).map(category => {
                    const categoryLink = getKeyByValue(availableCategories, category)
                    const banner = banners[categoryLink];

                    return (<div>
                        <BlogPostsGrid categoryName={category} categoryLink={categoryLink} blogPosts={blogDataAccess.getPostsByCategoryLimited(category)} />
                        {banner ? <BlogBanner banner={banner}/> : null}
                    </div>);
                }) : null}

            </Container>

        </div>
    );
}

export default BlogPage;