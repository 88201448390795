import MomentLocaleUtils from "react-day-picker/moment";
import "moment/locale/pl";
import moment from "moment";

class MomentFormatter {

    static DATE_FORMAT = "YYYY-MM-DD";
    static LOCALE = "pl";

    static datePickerFormatProps(format=this.DATE_FORMAT) {
        return {
            formatDate: (date, locale) => moment(date).locale(locale).format(format),
            parseDate: (str, locale) => moment(str, format).locale(locale).toDate(),
            locale: this.LOCALE,
            localeUtils: MomentLocaleUtils
        }
    }

    static dateFromString(dateString){
        return moment(dateString, this.DATE_FORMAT).locale(this.LOCALE).toDate();
    }
}

export default MomentFormatter;