import React, {useEffect, useRef, useState} from "react";
import closeIcon from "../../assets/images/icons/close.svg";
import {cmsService} from "../../utils/axios";
import {Link, useHistory} from "react-router-dom";
import URLSearchParamsConverter from "../../utils/URLSearchParamsConverter";

function Navigation(props) {

    const [menuItems, setMenuItems] = useState([
        {
            "ID": 1,
            "MenuTitle": "Strona główna",
            "URLSegment": "home"
        }
    ]);

    const ulubione = {
        "ID": 1920,
        "MenuTitle": "Ulubione pojazdy",
        "URLSegment": 'ulubione'
    };

    useEffect(() => {
        cmsService.get("/Page?ParentID=0&ShowInMenus=1&sort=Sort&dir=asc&fields=ID,MenuTitle,URLSegment")
            .then(response => {
                let output = response.data.items;
                output.push(ulubione);
                setMenuItems(output);
            })
    }, []);

    let menuRef = useRef(null);

    /**
     * Hook that alerts clicks outside of the passed ref
     */
    function useOutsideAlerter(ref) {
        useEffect(() => {
            /**
             * Alert if clicked on outside of element
             */
            function handleClickOutside(event) {
                if (ref.current && !ref.current.parentElement.contains(event.target) && event.target.parentElement != null) {
                    props.closeNavigationClicked();
                }
            }

            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    useOutsideAlerter(menuRef);

    const menuLinks = menuItems.map(item => (<li key={item.ID} onClick={props.closeNavigationClicked}><Link
        to={'/' + item.URLSegment}>{item.MenuTitle}</Link></li>));

    return (
        <div ref={menuRef} id="main-menu" className={props.show ? 'visible' : 'hidden'}>
            <a href="#" onClick={props.closeNavigationClicked} className="mobileMainMenuClose"><img src={closeIcon}
                                                                                                    alt="zamknij"/></a>
            <ul className="menu">
                {menuLinks}
            </ul>
        </div>
    );
}

export default Navigation;