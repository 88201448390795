import React from "react";

function CheckboxButton(props) {

    const buttonClickHandler = () => props.onChange();

    const getClassName = () => {
        if(props.disabled) {
            return "disabled" + " form-button formButtonIcon"
        }

        if(props.checked) {
            return "active" + " form-button formButtonIcon"
        }else{
            return "" + " form-button formButtonIcon"
        }
    }

    return (
        <button type="button"
                onClick={buttonClickHandler}
                className={getClassName()}
                disabled={props.disabled}>
            <span className="flex">
                <span className="buttonIcon"></span>
                <span>{props.title}</span>
                <span>{props.label}</span>
            </span>
        </button>
    );
}

export default CheckboxButton;