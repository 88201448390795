import React, {Fragment, useContext, useEffect, useState} from "react";
import {cmsService} from "../../utils/axios";

import fbIcon from '../../assets/images/icons/social/fb.svg';
import linkedIcon from '../../assets/images/icons/social/linked.svg';
import ytIcon from '../../assets/images/icons/social/yt.svg';
import SellerCodeReader from "../../utils/SellerCodeReader";
import {SellerContext} from "../../context/seller-context";
import phoneIcon from "../../assets/images/icons/phone_gold.svg";
import analyitics from "../../utils/analytics";
import trustCompanyIcon from "../../assets/images/fwf_logo_szare.png";
import realiableCompanyIcon from "../../assets/images/rzetelna_firma.png";
import pearls from "../../assets/images/perlu_silver_v2.png"

function Footer(props) {
    const [footerData, setFooterData] = useState({
        "Footer1Header": null,
        "Footer1Column": null,
        "Footer2Header": null,
        "Footer2Column": null,
        "Footer3Header": null,
        "Footer3Column": null,
        "FooterBottomText": null,
        "FacebookURL": "https://www.facebook.com/ekspercileasingu/",
        "YoutubeURL": "https://www.youtube.com/channel/UCqQprAhU9WR_WrKJJl16-Ow",
        "LinkedInURL": "https://pl.linkedin.com/company/go-leasing"
    });

    const phoneClickHandler = () => {
        analyitics.sendEvent({
            category: 'Telefon',
            action: 'Klikniecie',
            label: 'Kliknieto w nr telefonu',
            value: 1
        });
    }

    useEffect(() => {

        cmsService.get('/FooterConfig/1/')
            .then( response => {
                setFooterData(response.data)})
            .catch( error => console.log("Cannot get footer data"))
    },[]);


    const firstAndLastName = props.seller.firstName ? (<Fragment>{props.seller.firstName} {props.seller.lastName}<br/></Fragment>) : null;
    const sellerCodeReader = new SellerCodeReader();
    const seller = useContext(SellerContext);

    return (
        <Fragment>
            <footer>
                <div className="container flex">
                    <div className="footer-column">
                        <h4>{footerData.Footer1Header}</h4>
                        <div dangerouslySetInnerHTML={{ __html: footerData.Footer1Column }} />

                    </div>
                    <div className="footer-column">
                        <h4>Nasze Marki</h4>
                        <div>
                            <p><a title="GO-leasing" href={seller.webpageUrl}>GO-leasing</a></p>
                            <p><a title="GO-auto" href={seller.webpageUrl + "/oferta/leasing-all-inclusive/"}>GO-auto</a></p>
                            <p><a title="GO-gap" href="https://go-auto.pl/ubezpieczenie-gap">GO-gap</a></p>
                            <p><a title="GO-bot" href="https://go-bot.pl">GO-bot</a></p>
                            <p><a title="LeasinGOnline" href={"https://go-leasing.pl/leasing-online/" + sellerCodeReader.readSellerCodeForLeasingOnline()}>LeasinGOnline</a></p>
                        </div>
                    </div>
                    <div className="footer-column">
                        <h4>{footerData.Footer3Header}</h4>
                        <div dangerouslySetInnerHTML={{ __html: footerData.Footer3Column }} />
                    </div>
                    <div className="footer-column">
                        <h4>Zadzwoń do nas
                            <a href={"tel:" + props.seller.phone}
                               id="footer-phone"
                               className="flex"
                               onClick={phoneClickHandler}>
                                <img src={phoneIcon} alt=""/>
                                <span className="gold" id="footer-phone">{props.seller.phone}</span>
                            </a>
                        </h4>
                        <div className="contact-data">
                            <p><strong>{props.seller.name}</strong><br/>
                                {firstAndLastName}
                                {props.seller.street}<br/>
                                {props.seller.postalCode} {props.seller.city}<br/>
                                <a href={"mailto:" + props.seller.email}>{props.seller.email}</a>
                            </p>
                        </div>
                        <div className="hours">
                            <div className="hour-line">
                                <div className="day">Pon - Pt</div>
                                <div className="hour"> 08:00 - 20:00</div>
                            </div>
                        </div>
                        <div className="social">
                            <a href={footerData.FacebookURL} target="_blank" rel="noopener noreferrer"><img src={fbIcon} alt="Facebook"/></a>
                            <a href={footerData.LinkedInURL} target="_blank" rel="noopener noreferrer"><img src={linkedIcon} alt="LinkedIn"/></a>
                            <a href={footerData.YoutubeURL} target="_blank" rel="noopener noreferrer"><img src={ytIcon} alt="YouTube"/></a>
                        </div>
                    </div>
                </div>
                <div className="container seo-text-certs" >
                    <div className="pearls">
                        <a href="https://go-leasing.pl/nowosci/spolka-go-leasing-z-tytulem-perly-polskiego-leasingu-2021/" >
                            <img title="Kliknij i sprawdź status certyfikatu" src={pearls} />
                        </a>
                    </div>
                    <div className="trustCompany">
                        <a href="https://wizytowka.rzetelnafirma.pl/JB00OR9P" >
                            <img src={trustCompanyIcon} alt="Firma Wiarygodna Finansowo" />
                        </a>
                    </div>
                    <div className="reliableCompany">
                        <a href="http://www.big.pl/certyfikat-fwf?nip=7393879888">
                            <img title="Kliknij i sprawdź status certyfikatu" src={realiableCompanyIcon} />
                        </a>
                    </div>
                </div>
                <div className="container seo-text" dangerouslySetInnerHTML={{ __html: footerData.FooterBottomText }} />
            </footer>

            <div id="copyright">
                <p>&copy; Copyright {new Date().getFullYear()} GO-auto. Wszelkie prawa zastrzeżone.</p>
            </div>
        </Fragment>
    );
}

export default Footer;