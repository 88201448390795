import React, {useEffect, useState} from "react";
import {cmsService} from "../../utils/axios";

function ExternalArticles() {

    const [externalArticles, setExternalArticles] = useState([]);

    useEffect(() => {

        cmsService("/ExternalArticle?add_fields=LogoURL").then(response => {
            setExternalArticles(response.data.items);
        })
    }, []);


    const mainArticles = externalArticles.slice(0,4).map(article => (
        <div className="quote-item" key={article.ID}>
            <div className="quote-content" dangerouslySetInnerHTML={{ __html: article.Content }} />
            <div className="quote-author">
                <img className="author-logo" src={article.LogoURL} alt="" />
            </div>

        </div>
    ));


    return (
            <div id="quotes">
                <h3 className="center">Mówią o nas</h3>
                <div className="container flex">
                    {mainArticles}
                </div>
            </div>
    );
}

export default ExternalArticles;