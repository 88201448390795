import React, {Fragment, useContext} from "react";
import {SearchOptionsContext} from "../../context/search-options-context";
import CheckboxColumnList from "../../components/CheckboxColumnList/CheckboxColumnList";
import BodyTypeIcons from "../../utils/BodyTypeIcons";

function StepForBodyType(props) {

    const searchOptions = useContext(SearchOptionsContext);

    const bodyTypes =  searchOptions.bodyTypes.map(type => ({name:type, image: BodyTypeIcons[type].icon}));

    return (
        <Fragment>

            <div className="config-modal-body">

                <h5 className="center">Jakim nadwoziem jesteś zainteresowany?</h5>
                <form id="segment-konfig">
                    <CheckboxColumnList
                        selectedItems={props.bodyTypes}
                        columnCount={2}
                        items={bodyTypes}
                        onChange={props.onChange}
                    />
                </form>

            </div>

            <div className="config-modal-footer">

                <div className="flex">
                    <button type="button" className="back" onClick={props.onBackClick}><i
                        className="fa fa-angle-left"></i>Wstecz
                    </button>
                    <button type="button" className="endFormButton" onClick={props.onSearchClick}>Zobacz wybrane</button>
                </div>

            </div>
        </Fragment>
    );
}

export default StepForBodyType;