import React, {useEffect, useState} from "react";
import SimpleMultiSelect from "../MultiSelect/SimpleMultiSelect";

function MultipleItemsSearchSelect(props) {

    const [selectedItems, setSelectedItems] = useState(props.selectedItems ? props.selectedItems : []);
    const {onChange} = props;

    let items = [];
    if(props.items.length > 0) {
        items = props.items.map(item => ({name: item}));
    }

    useEffect(() => {
       if(props.resetSelected) {
           setSelectedItems([]);
       }}, [props.resetSelected]);

    useEffect(() => {
        onChange(selectedItems);
    }, [selectedItems, onChange]);


    const deselectItemHandler = (itemName) => {
        let deselectedItemIndex = selectedItems.map((item) => item.name).indexOf(itemName);

        if (deselectedItemIndex > -1) {
            let updatedSelectedItems = [...selectedItems];
            updatedSelectedItems.splice(deselectedItemIndex, 1);
            setSelectedItems(updatedSelectedItems);
        }
    }

    const selectItemHandler = (item) => {
        let selectedItemIndex = selectedItems.findIndex(selectedItem => item.name === selectedItem.name);

        if (selectedItemIndex === -1) {
            setSelectedItems(prevSelectedItems => [...prevSelectedItems, item]);
        } else {
            deselectItemHandler(item.name);
        }
    }

    const selectAllItemsHandler = (event) => {
        if (event.target.checked) {
            setSelectedItems(items);
        } else {
            setSelectedItems([]);
        }
    }

    return (

        <SimpleMultiSelect
            itemSelected={selectItemHandler}
            itemDeselected={deselectItemHandler}
            items={items}
            selectedItems={selectedItems}
            allItemsSelected={selectAllItemsHandler}
            placeholder={props.placeholder}
            showItemImage={false}
        />
    );
}

export default MultipleItemsSearchSelect;