import React, {Suspense, useEffect} from 'react';
import Layout from './containers/Layout/Layout';
import {BrowserRouter} from "react-router-dom";
import analytics from "./utils/analytics";
import {Route, Switch} from "react-router";
import Sitemap from "./Sitemap"
import {Spinner} from "@blueprintjs/core";
import FaqPage from "./containers/FaqPage/FaqPage";

function App() {

    useEffect(() => {
        const timer = setTimeout(() => {
            analytics.sendEvent({
                category: 'Czas',
                action: 'Czas spędzony na stronie, powyżej 30 s'
            });
        }, 1000 * 30);
        return () => clearTimeout(timer);
    }, []);

    return (
        <BrowserRouter>
            <div>
                <Layout/>
            </div>
        </BrowserRouter>
    );
}

export default App;
