import React, {useContext, useEffect} from "react";
import {CustomerTypeContext} from "../../context/customer-type-context";
import analyitics from "../../utils/analytics";
import {useLocation, useParams} from "react-router";
import UrlDecorator from "../../utils/UrlDecorator";

function CustomerTypeSwitch() {
    const [customerType, setCustomerType, availableCustomerTypes, companyOnly] = useContext(CustomerTypeContext);
    const location = useLocation();
    const params = useParams();

    const changeCustomerTypeHandler = (event) => {
        if (event.target.checked) {
            setCustomerType(availableCustomerTypes.person);
            analyitics.sendEvent({
                category: 'Zmiana umowy - prywatnie',
                action: 'Klikniecie',
                label: 'Prywatnie',
                value: 1
            });

        } else {
            setCustomerType(availableCustomerTypes.company);
            analyitics.sendEvent({
                category: 'Zmiana umowy - firma',
                action: 'Klikniecie',
                label: 'Firma',
                value: 1
            });

        }

    }

    function isOfferListPage() {
        return location.pathname === '/lista-ofert';
    }

    function isOfferPage() {
        return params.brand && params.version && params.id;
    }

    useEffect(() => {
        if (isOfferListPage() || isOfferPage()) {
            UrlDecorator.addCustomerType(customerType);
        }
    }, [customerType, location.pathname]);

    useEffect(() => {
        if (isOfferListPage() || isOfferPage()) {
            const urlSearchParams = new URLSearchParams(location.search);
            const customerTypeFromUrl = urlSearchParams.get("customerType");

            if (customerTypeFromUrl === availableCustomerTypes.person
                || customerTypeFromUrl === availableCustomerTypes.company) {

                setCustomerType(customerTypeFromUrl);
            }

        }

    }, [location]);


    return (
        <div id="switch">
            <div className="can-toggle">
                <form action="">
                    <input id="a"
                           type="checkbox"
                           value={customerType}
                           disabled={companyOnly}
                           checked={customerType === availableCustomerTypes.person}
                           onChange={changeCustomerTypeHandler}/>
                    <label htmlFor="a">
                        <div className="can-toggle__switch" data-checked="Prywatnie"
                             data-unchecked="Firma"></div>
                    </label>
                </form>
            </div>
        </div>
    );
}

export default CustomerTypeSwitch;