import React, {Fragment, useContext, useState} from "react";
import {SearchOptionsContext} from "../../context/search-options-context";

function StepForGearbox(props) {

    const searchOptions = useContext(SearchOptionsContext);

    const [gearboxTypes, setGearboxTypes] = useState(() => searchOptions.gearboxTypes.map(typeName => {
        return {name: typeName, active: typeName === props.gearboxType};
    }));


    const gearboxClickHandler = gearboxTypeName => {
        let gearboxTypesForUpdate = [...gearboxTypes];

        for (let gearboxTypeForUpdate of gearboxTypesForUpdate) {
            if (gearboxTypeName === gearboxTypeForUpdate.name) {
                gearboxTypeForUpdate.active = true;
            } else {
                gearboxTypeForUpdate.active = false;
            }
        }

        setGearboxTypes(gearboxTypesForUpdate);
        props.onChange(gearboxTypeName);
    };

    let gearboxButtons = gearboxTypes.map(type => (
        <button key={type.name} type="button" onClick={() => gearboxClickHandler(type.name)}
                className={"fullwidth" + (type.active ? " active" : "")}>{type.name}
        </button>
    ));

    return (
        <Fragment>

            <div className="config-modal-body">

                <h5 className="center">Szukasz auta w automacie czy manualu?</h5>
                <form>
                    {gearboxButtons}
                </form>

            </div>

            <div className="config-modal-footer">

                <div className="flex">
                    <button type="button" className="back" onClick={props.onBackClick}><i
                        className="fa fa-angle-left"></i>Wstecz
                    </button>
                    <button type="button" onClick={props.onNextClick}>Dalej</button>
                </div>

            </div>
        </Fragment>
    );
}

export default StepForGearbox;