import React from "react";

function MenuCheckbox(props){

    return (
        <label htmlFor={props.name} className="checkbox-label">
            <input id={props.name} type="checkbox" value={props.children} onChange={props.selected} checked={props.checked}/>
            {props.image}{props.children}
        </label>
    );
}

export default MenuCheckbox;