import React from "react";
import {Intent, Overlay, Spinner} from "@blueprintjs/core";

function SpinnerOverlay(props){

    return (
        <Overlay {...props}>
            <Spinner className="fixed-spinner" intent={Intent.PRIMARY} />
        </Overlay>
    );
}

export default SpinnerOverlay;