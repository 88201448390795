import React, {useContext} from "react";
import {SearchOptionsContext} from "../../context/search-options-context";
import {Link} from "react-router-dom";

function Brands(props) {

    const searchOptions = useContext(SearchOptionsContext);

    let brands = searchOptions.brandsAndModels.map(brand => (
        <Link to={{pathname: "lista-ofert", search: "brands=" + brand.name}} key={brand.identifier}><img src={brand.image} alt={brand.identifier} />{brand.name}</Link>
    ));

    return (
        <div id="marki">
            <h3 className="center">Szukaj ulubionej marki</h3>
            <div className="container">
                <div className="marki-line flex flex-wrap">
                    {brands}
                </div>
            </div>
        </div>
    );
}

export default Brands;
    
    