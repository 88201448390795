import React, {Fragment, useState} from "react";
import MediaQuery from "react-responsive";
import {Collapse} from "@blueprintjs/core";

function VehicleEquipment(props) {

    const [isOpen, setIsOpen] = useState(false);
    const [currentStandard, setCurrentStandard] = useState(props.offer.standardEquipment.length > 0 ? "current" : "");
    const [currentAdditional, setCurrentAdditional] = useState(props.offer.standardEquipment.length === 0 ? "current" : "");

    const openClickHandler = () => setIsOpen(prevState => !prevState);

    const changeStandardTabHandler = () => {
        setCurrentStandard("current");
        setCurrentAdditional("");
    };

    const changeAdditionalTabHandler = () => {
        setCurrentStandard("");
        setCurrentAdditional("current");
    };

    let standardEquipmentTab = props.offer.standardEquipment.length > 0 ?
        (<li className={currentStandard + " tab-link"} onClick={changeStandardTabHandler}>Wyposażenie standardowe</li>) : null;

    let addEquipmentTab = props.offer.additionalEquipment.length > 0 ?
        (<li className={currentAdditional + " tab-link"} onClick={changeAdditionalTabHandler}>Wyposażenie dodatkowe</li>) : null;

    const createEquipmentItems = equipment => (
        equipment.map((equipmentItem, index) => (<div key={index} className="line">{equipmentItem}</div>))
    );

    const createEquipmentList = (equipment) => {
        let halfLength = Math.ceil(equipment.length / 2);

        let firstColumn = createEquipmentItems(equipment.slice(0, halfLength));
        let secondColumn = createEquipmentItems(equipment.slice(halfLength, equipment.length));

        return (
            <Fragment>
                <div className="column">
                    {firstColumn}
                </div>
                <div className="column">
                    {secondColumn}
                </div>
            </Fragment>
        );
    }

    let contentBox = (
        <div className="offerBoxContent">
            <div id="single-tabs">
                <ul className="tabs flex" id="equipment-type-switch">
                    {standardEquipmentTab}
                    {addEquipmentTab}
                </ul>
                <br/>
                <div id="tab-standardowe" className={currentStandard + " tab-content"}>
                    <div className="flex">
                        <strong>Wyposażenie standardowe</strong>
                        <p />
                        {createEquipmentList(props.offer.standardEquipment)}
                    </div>
                </div>

                <div id="tab-dodatkowe" className={currentAdditional + " tab-content"}>
                    <div className="flex">
                        <br/>
                        <strong>Wyposażenie dodatkowe</strong>
                        <p />
                        {createEquipmentList(props.offer.additionalEquipment)}
                    </div>
                </div>
            </div>
        </div>
    );

    if (props.offer.standardEquipment.length > 0 || props.offer.additionalEquipment.length > 0) {
        return (
            <div className="single-equip single-box">
                <h3 className="onlymobile">Wyposażenie</h3>

                <MediaQuery maxDeviceWidth={767}>
                <span className="onlymobile openBox btn" onClick={openClickHandler}><i
                    className="fa fa-navicon"></i> Szczegóły</span>
                    <Collapse isOpen={isOpen} keepChildrenMounted={true}>
                        {contentBox}
                    </Collapse>
                </MediaQuery>

                <MediaQuery minDeviceWidth={768}>
                    {contentBox}
                </MediaQuery>
                {props.offer.availability !== "IN_STOCK" &&
                <div>
                    <br/>
                    <em>Prezentowana oferta zawiera wyposażenie podstawowe samochodu.
                        Termin dostawy auta oraz ostateczna cena zostanie określona po uwzględnieniu wybranego
                        wyposażenia dodatkowego oraz rabatu GO-leasing. Zapraszamy do kontaktu z naszym doradcą. </em>
                </div>
                }
            </div>
        );
    }

    return null;
}

export default VehicleEquipment;