import React from "react";

import menuIcon from '../../assets/images/icons/menu_white.svg';
import searchIcon from '../../assets/images/icons/search_white.svg';


function MobileMenu(props) {

    return (
        <div id="mobile-menu-trigger-wrapper" className="flex">
            <div>
                <div  onClick={props.menuClicked} className="mobileMainMenuToggle">
                    <img className="" src={menuIcon} alt=""/>
                    <span>Menu</span>
                </div>
            </div>
            <div style={{borderLeft: "1px solid #555"}}>
                <div onClick={props.searchClicked} className="mobileMainMenuToggle">
                    <img className="" src={searchIcon} alt=""/>
                    <span>Szukaj auta</span>
                </div>
            </div>
        </div>
    );
}

export default MobileMenu;