import React, {Fragment, useContext, useEffect, useRef, useState} from "react";
import BaseInstallment from "./BaseInstallment";

import {initialAllowedCalculationParameters} from "./initialAllowedParameters";
import {goVehisService} from "../../../utils/axios";
import ContractLengthChooser from "./ContractLengthChooser";
import FeeSlider from "./FeeSlider";
import {CustomerTypeContext, isCustomerCompany} from "../../../context/customer-type-context";
import MathUtils from "../../../utils/MathUtils";
import InsuranceOptions from "./InsuranceOptions";
import InstallmentSubmitBox from "./InstallmentSubmitBox";
import GapCheckbox from "./GapCheckbox";
import CalculationSummary from "./CalculationSummary";
import BuyoutValueSlider from "./BuyoutValueSlider";
import AppToaster from "../../../components/AppToaster";
import contractLengthBuyoutMap from "./contractLengthBuyoutMap";
import analytics from "../../../utils/analytics";
import * as axios from "axios";
import ContactDialog from "../../../components/ContactDialog/ContactDialog";
import {Intent, Overlay, Spinner} from "@blueprintjs/core";

function InstallmentCalculator(props) {

    const MAX_PRICE_NET = 300000;
    const customerContext = useContext(CustomerTypeContext);

    let contractLengthRef = useRef(null);
    let initialFeeRef = useRef(null);
    let buyoutValueRef = useRef(null);
    const source = axios.CancelToken.source();

    let vehiclePrice = isCustomerCompany(customerContext) ? props.offer.minimumPriceNet : props.offer.minimumPriceGross;

    const [calculationResponse, setCalculationResponse] = useState({
        "calculationResult": {
            "vehicleValueNet": props.offer.minimumPriceNet,
            "vehicleValueGross": props.offer.minimumPriceGross,
            "buyoutValueNet": 0,
            "buyoutValueGross": 0,
            "initialFeeValueNet": 0,
            "initialFeeValueGross": 0,
            "installmentNet": props.offer.installmentNet,
            "installmentGross": props.offer.installmentGross,
            "numberOfInstallments": 60,
            "ocAcInsuranceNet": 0,
            "ocAcInsuranceGross": 0,
            "gapInsuranceNet": 0,
            "gapInsuranceGross": 0,
            "installmentWithInsuranceNet": props.offer.installmentNet,
            "installmentWithInsuranceGross": props.offer.installmentGross
        },
        "insuranceFees": {
            "ocAcInsuranceFor12MonthsNet": 0,
            "ocAcInsuranceFor12MonthsGross": 0,
            "ocAcInsuranceForAllMonthsNet": 0,
            "ocAcInsuranceForAllMonthsGross": 0,
            "gapInsuranceNet": 0,
            "gapInsuranceGross": 0
        }
    });

    const defaultContractLength = props.initialCalculation ? props.initialCalculation.contractLength : 60;
    const defaultInsuranceLength = props.ocAcInsurance12MonthsVisible ? 12 : defaultContractLength;

    const [calculationParameters, setCalculationParameters] = useState(
        {
            vehicleValueNet: props.offer.minimumPriceNet,
            vehicleType: props.offer.vehicleType,
            initialFeePercentage: props.initialCalculation ? MathUtils.percentageOfValue(vehiclePrice, props.initialCalculation.initialFeeValueNet) : 10,
            buyoutValuePercentage: props.initialCalculation ? MathUtils.percentageOfValue(vehiclePrice, props.initialCalculation.buyoutValueNet) : 25,
            contractLength: defaultContractLength,
            insuranceLength: props.initialCalculation ? props.initialCalculation.insuranceLength : ((props.offer.minimumPriceNet > MAX_PRICE_NET || props.offer.hasReturnGuarantee === false) ? 0 : defaultInsuranceLength),
            includeGapInsurance: props.initialCalculation ? props.initialCalculation.includeGapInsurance : true,
            customerType: customerContext[0],
            manufactureYear: props.offer.manufactureYear,
            decimalPrecision: 0
        }
    );

    const [defaultBuyoutPercentage, setDefaultBuyoutPercentage] = useState(calculationParameters.buyoutValuePercentage);

    let isIndividualInsurance = props.offer.minimumPriceNet >= MAX_PRICE_NET;

    const [isGwarancjaZwrotuActive, setIsGwarancjaZwrotuActive] = useState(props.offer.hasReturnGuarantee);

    const isSpinnerEnabled = props.spinnerEnabled ? props.spinnerEnabled : false;

    const [isOpenSpinner, setIsOpenSpinner] = useState(isSpinnerEnabled);

    useEffect(() => {
        setCalculationParameters(prevState => ({...prevState, vehicleValueNet: props.offer.minimumPriceNet}))
    }, [props.offer.minimumPriceNet]);

    useEffect(() => {
        console.log(props.offer)
    }, [props.offer])

    let onGwarancjaZwrotuChange = props.onGwarancjaZwrotuChange;

    useEffect(() => {
        setIsOpenSpinner(true);

        goVehisService.post(props.calculatorUrl, calculationParameters, {
            cancelToken: source.token
        }).then(response => {
            let calculationResult = response.data;

            if (calculationResult !== undefined) {
                setCalculationResponse(calculationResult);
                setIsOpenSpinner(false);
            }

        }).catch(error => {
            if (axios.isCancel(error)) {
                console.log("request cancelled: " + error.message);
            } else {
                console.log("another error happened: " + error.message);
            }
        });

        let currentState = (isIndividualInsurance || calculationParameters.insuranceLength !== 0)
            && calculationParameters.includeGapInsurance;

        let showToaster = isGwarancjaZwrotuActive && currentState === false;

        setIsGwarancjaZwrotuActive(props.offer.hasReturnGuarantee && currentState);
        onGwarancjaZwrotuChange(props.offer.hasReturnGuarantee && currentState);

        if (showToaster) {
            let message = (<p className="app-toaster">
                Rezygnując z ubezpieczenia tracisz możliwość skorzystania z bezpiecznego zwrotu samochodu w każdej
                chwili umowy,
                jakie daje Ci Gwarancja Zwrotu.&nbsp;&nbsp;
                <a href="/gwarancja-zwrotu" target="_blank" className="black">Zobacz więcej</a></p>);
            AppToaster.show({message: message, intent: "warning", icon: "warning-sign", timeout: 15000});
        }

        return () => {
            source.cancel();
        };
    }, [calculationParameters, isIndividualInsurance, onGwarancjaZwrotuChange, isGwarancjaZwrotuActive, props.offer.hasReturnGuarantee]);


    const [allowedCalculationParameters, setAllowedCalculationParameters] = useState(initialAllowedCalculationParameters);

    useEffect(() => {
        setIsOpenSpinner(true);
        goVehisService(props.allowedCalculationParametersUrl).then(response => {
            let allowedParameters = response.data;

            if (allowedParameters !== undefined) {
                setAllowedCalculationParameters(allowedParameters);
                setIsOpenSpinner(false);
            }



        });
    }, [props.offer.minimumPriceNet]);

    const contractLengthChangeHandler = (newContractLength) => {
        setCalculationParameters(prevState => {

            if (prevState.contractLength !== newContractLength) {
                analytics.sendEvent({
                    category: 'OL - zmiana miesiecy',
                    action: 'Klikniecie',
                    label: 'Kliknieto w ' + newContractLength,
                    value: 1
                });
            }

            return {
                ...prevState,
                contractLength: newContractLength,
                insuranceLength: prevState.insuranceLength > 12 ? newContractLength : prevState.insuranceLength,
                buyoutValuePercentage: contractLengthBuyoutMap[newContractLength]
            };
        });
        setDefaultBuyoutPercentage(contractLengthBuyoutMap[newContractLength]);
    };

    const initialFeeChangeHandler = (initialFee) => {
        let initialFeePercentage = MathUtils.percentageOfValue(vehiclePrice, initialFee);
        setCalculationParameters(prevState => ({...prevState, initialFeePercentage: initialFeePercentage}));
    };

    const buyoutValueChangeHandler = (buyoutValue) => {
        let buyoutValuePercentage = MathUtils.percentageOfValue(vehiclePrice, buyoutValue);
        setCalculationParameters(prevState => ({...prevState, buyoutValuePercentage: buyoutValuePercentage}));
    };

    const ocAcInsuranceChangeHandler = (insuranceLength) => {
        setCalculationParameters(prevState => ({...prevState, insuranceLength: insuranceLength}))

    };

    const gapInsuranceChangeHandler = () => {
        setCalculationParameters(prevState => ({...prevState, includeGapInsurance: !prevState.includeGapInsurance}))

    };

    const goToContractLengthHandler = () => {
        analytics.sendEvent({
            category: 'OL',
            action: 'Klikniecie',
            label: 'Zmieniono OL',
            value: 1
        });

        window.scrollTo({
            behavior: 'smooth',
            top: contractLengthRef.current.offsetTop - 50
        });
    };

    const goToInitialFeeHandler = () => {
        analytics.sendEvent({
            category: 'OW',
            action: 'Klikniecie',
            label: 'Zmieniono OW',
            value: 1
        });

        window.scrollTo({
            behavior: 'smooth',
            top: initialFeeRef.current.offsetTop - 50
        });
    }

    const [showBuyoutValue, setShowBuyoutValue] = useState(false);
    const showBuyoutValueHandler = () => {
        setShowBuyoutValue(prevState => !prevState);

        analytics.sendEvent({
            category: 'Wykup',
            action: 'Klikniecie',
            label: 'Zmieniono wykup',
            value: 1
        });

        window.scrollTo({behavior: 'smooth', top: buyoutValueRef.current.offsetTop - 50});
    }

    const [isOpenContactDialog, setIsOpenContactDialog] = useState(false);


    const reservationClickHandler = () => {
        analytics.sendEvent({
            category: 'Zarezerwuj',
            action: 'Klikniecie',
            label: 'Kliknieto w Zarezerwuj',
            value: 1
        });

        let calculationOffer = {
            vehicle: props.offer,
            financingCompany: props.financingCompany,
            calculation: {
                vehicleValueNet: calculationResponse.calculationResult.vehicleValueNet,
                vehicleValueGross: calculationResponse.calculationResult.vehicleValueGross,
                buyoutValueNet: calculationResponse.calculationResult.buyoutValueNet,
                buyoutValueGross: calculationResponse.calculationResult.buyoutValueGross,
                initialFeeValueNet: calculationResponse.calculationResult.initialFeeValueNet,
                initialFeeValueGross: calculationResponse.calculationResult.initialFeeValueGross,
                installmentNet: calculationResponse.calculationResult.installmentWithInsuranceNet,
                installmentGross: calculationResponse.calculationResult.installmentWithInsuranceGross,
                contractLength: calculationParameters.contractLength,
                insuranceLength: calculationParameters.insuranceLength,
                includeGapInsurance: calculationParameters.includeGapInsurance,
                installmentWithoutInsurance: calculationResponse.calculationResult.installmentNet,
                ocAcInsuranceInstallment: calculationResponse.calculationResult.ocAcInsuranceNet,
                gapInstallment: calculationResponse.calculationResult.gapInsuranceNet
            }
        };

        props.reservationStrategy(true, calculationOffer);

    }

    const contactClickHandler = () => {
        setIsOpenContactDialog(true);

        analytics.sendEvent({
            category: 'Zapytaj',
            action: 'Klikniecie',
            label: 'Kliknieto w Zapytaj',
            value: 1
        });
    }


    return (
        <Fragment>
            <BaseInstallment
                installment={
                    {
                        net: calculationResponse.calculationResult.installmentNet,
                        gross: calculationResponse.calculationResult.installmentGross
                    }
                }
                offer={props.offer}
                label="Rata leasingu"
            />

            <hr/>

            <form action="">

                <span ref={contractLengthRef}></span>
                <ContractLengthChooser
                    items={allowedCalculationParameters.contractLengthMonths}
                    defaultLength={calculationParameters.contractLength}
                    onChange={contractLengthChangeHandler}
                    label="Okres leasingu (ilość miesięcznych rat)"
                />

                <div ref={initialFeeRef} id="formOplata" className="form-item form-oplata">
                    <FeeSlider
                        infoCode="oplata-wstepna"
                        onChange={initialFeeChangeHandler}
                        defaultValue={MathUtils.valueOfPercentage(vehiclePrice, calculationParameters.initialFeePercentage)}
                        value={MathUtils.valueOfPercentage(vehiclePrice, calculationParameters.initialFeePercentage)}
                        brutto={!isCustomerCompany(customerContext)}
                        min={isCustomerCompany(customerContext) ?
                            allowedCalculationParameters.initialFeeValues.minimumInitialFeeNet : allowedCalculationParameters.initialFeeValues.minimumInitialFeeGross}
                        max={isCustomerCompany(customerContext) ?
                            allowedCalculationParameters.initialFeeValues.maximumInitialFeeNet : allowedCalculationParameters.initialFeeValues.maximumInitialFeeGross}
                        calculationResult={calculationResponse.calculationResult}
                    />
                </div>


                {props.offer.hasReturnGuarantee && <InsuranceOptions
                    allMonthsVisible={props.ocAcInsuranceAllMonthsVisible}
                    oneYearVisible={props.ocAcInsurance12MonthsVisible}
                    onChange={ocAcInsuranceChangeHandler}
                    isIndividual={isIndividualInsurance}
                    contractLength={calculationParameters.contractLength}
                    insuranceLength={calculationParameters.insuranceLength}
                    insuranceFees={calculationResponse.insuranceFees}
                    disabled={props.offer.financialProduct === "NAJLEASING"}
                />
                }

                <GapCheckbox
                    visible={props.gapInsuranceVisible}
                    checked={calculationParameters.includeGapInsurance}
                    insuranceFees={calculationResponse.insuranceFees}
                    onChange={gapInsuranceChangeHandler}
                    disabled={props.offer.financialProduct === "NAJLEASING"}
                />


                <span ref={buyoutValueRef}></span>
                <div id="formWykup" className={(showBuyoutValue ? "" : "hidden ") + "form-item form-wykup"}>
                    <BuyoutValueSlider
                        infoCode="wykup"
                        onChange={buyoutValueChangeHandler}
                        defaultValue={MathUtils.valueOfPercentage(vehiclePrice, calculationParameters.buyoutValuePercentage)}
                        value={MathUtils.valueOfPercentage(vehiclePrice, defaultBuyoutPercentage)}
                        brutto={!isCustomerCompany(customerContext)}
                        buyoutValues={allowedCalculationParameters.buyoutValues}
                        contractLength={calculationParameters.contractLength}
                    />
                </div>

                <hr/>
                <CalculationSummary
                    isNew={props.offer.isNew}
                    allInsuranceVisible={props.gapInsuranceVisible && props.ocAcInsurance12MonthsVisible && props.ocAcInsuranceAllMonthsVisible}
                    isIndividualInsurance={isIndividualInsurance}
                    isGwarancjaZwrotuActive={isGwarancjaZwrotuActive}
                    calculationParameters={calculationParameters}
                    calculationResult={calculationResponse.calculationResult}
                    offer={props.offer}
                    changeContractLength={goToContractLengthHandler}
                    changeInitialFee={goToInitialFeeHandler}
                    changeBuyoutValue={showBuyoutValueHandler}
                />

                <InstallmentSubmitBox
                    installment={{
                        net: calculationResponse.calculationResult.installmentWithInsuranceNet,
                        gross: calculationResponse.calculationResult.installmentWithInsuranceGross
                    }}
                    onReservationClick={reservationClickHandler}
                    onSendMessageClick={contactClickHandler}
                />
                { isSpinnerEnabled && <Overlay isOpen={isOpenSpinner} usePortal={false}>
                    <Spinner intent={Intent.PRIMARY} className="calculator-spinner"/>
                </Overlay>
                }
            </form>

            <ContactDialog isOpen={isOpenContactDialog}
                           onClose={() => setIsOpenContactDialog(false)}
                           additionalMessage={"Dotyczy oferty: " + window.location.href}
            />
        </Fragment>
    );
}

export default InstallmentCalculator;