import React, {Fragment} from "react";
import LabelWithInfo from "../../../../components/LabelWithInfo/LabelWithInfo";
import MathUtils from "../../../../utils/MathUtils";

function RentalCalculationSummary({calculation, calculationParameters, offerElements}) {

    const elementsInPrice = offerElements.map(element => (
        <div key={element.code} className="line flex">
            <div>
                <LabelWithInfo infoCode={element.code}/>
            </div>

            <div className="line-right">
                w cenie
            </div>

        </div>
    ));

    return (
        <Fragment>
            <h4 id="podsumowanie">Podsumowanie</h4>
            <div className="form-summary">
                <div className="line flex">
                    <div className="line-left">
                        Okres wynajmu:
                    </div>
                    <div className="line-right">
                        {calculationParameters.contractLength} m-cy
                    </div>

                </div>

                <div className="line flex">
                    <div className="line-left">
                        Opłata wstępna:
                    </div>

                    <div className="line-right">
                        {MathUtils.valueOfPercentage(calculation.vehiclePrice, calculationParameters.initialFeePercentage)} zł
                    </div>
                </div>

            </div>

            <h4 id="rata">Miesięczna rata</h4>
            <div className="form-summary">
                <div className="line flex">
                    <div className="line-left">
                        Rata wynajmu:
                    </div>

                    <div className="line-right">
                        {calculation.value} zł
                    </div>

                </div>
                {elementsInPrice}

            </div>
        </Fragment>
    );
}

export default RentalCalculationSummary;