import React, {useState, useEffect} from 'react';
import {Suggest} from "@blueprintjs/select";
import {MenuItem, PopoverInteractionKind} from "@blueprintjs/core";
import arrowDown from "../../assets/images/icons/arrow_circle_bottom.svg";

function SimpleSuggest(props) {

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [suggestEnabled, setSuggestEnabled] = useState(true);

    useEffect(() => {
        setSuggestEnabled(props.rangeList.length > 0)
    }, [props.rangeList])

    const onArrowClickHandler = () => {
        if(suggestEnabled){
            setDropdownOpen(!dropdownOpen)
        }
    }

    const itemRenderer = (val, {handleClick}) => {
        return (
            <MenuItem key={val} onClick={handleClick} text={val + " " + props.unit} />
        );
    };

    const queryValueRenderer = (val) => {
        return val + " " + props.unit;
    }

    const popoverInteractionHandler = (nextOpenState, event) => {
        requestAnimationFrame(() => {
            if (nextOpenState === false) {
                // input is no longer focused, we should close the popover
                setDropdownOpen(false);
            } else if(suggestEnabled){
                setDropdownOpen(true);
            }
        });
    }

    return (
        <Suggest
            className="simpleSuggest"
            selectedItem={props.initValue}
            query={props.rangeQuery.toString()}
            onQueryChange={props.updateRangeFromInput}
            inputValueRenderer={queryValueRenderer}
            items={props.rangeList}
            onItemSelect={props.updateRangeFromInput}
            itemRenderer={itemRenderer}
            disabled={!suggestEnabled}

            inputProps={{
                placeholder: props.placeholder,
                rightElement: (<img className={"multiSelectDownArrow " + (dropdownOpen ? "open" : "")} src={arrowDown}
                                    onClick={onArrowClickHandler} alt="open/close"/>),
            }}

            popoverProps={{
                isOpen: dropdownOpen,
                minimal: true,
                usePortal: false,
                interactionKind: PopoverInteractionKind.CLICK,
                onInteraction: popoverInteractionHandler,
            }}/>
    )
}

export default SimpleSuggest