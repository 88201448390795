import React from "react";

class Dictionary {

    static ACCOUNTING_TYPE = "AccountingType";
    static VOIVODESHIP = "Voivodeship";
    static DECISION_TYPE = "DecisionType";
    static MARITAL_STATUS = "MaritalStatus";
    static LEGAL_FORM = "LegalForm";
    static BIK_KRD_STATUS = "BikKrdStatus";
    static CUSTOMER_TYPE = "CustomerType";
    static SEPARATION_TYPE = "SeparationType";
    static IDENTITY_CARD_TYPE = "IdentityCardType";
    static ALL_COUNTRIES = "AllCountries";
    static EMPLOYMENT_FORM = "EmploymentForm";
    static POLAND = "Polska";
    static IS_DRIVING_LICENCE="IsDrivingLicence";

    static DICTIONARY_LIST = [
        {
            "name": "AccountingType",
            "values": {
                "FULL": "Księgi handlowe",
                "KPIR": "KPIR",
                "LUMP_SUM": "Ryczałt",
                "TAX_CARD": "Karta podatkowa"
            }
        },
        {
            "name": "Voivodeship",
            "values": {
                "DOLNOSLASKIE": "dolnośląskie",
                "KUJAWSKO_POMORSKIE": "kujawsko-pomorskie",
                "LUBELSKIE": "lubelskie",
                "LUBUSKIE": "lubuskie",
                "LODZKIE": "łódzkie",
                "MALOPOLSKIE": "małopolskie",
                "MAZOWIECKIE": "mazowieckie",
                "OPOLSKIE": "opolskie",
                "PODKARPACKIE": "podkarpackie",
                "PODLASKIE": "podlaskie",
                "POMORSKIE": "pomorskie",
                "SLASKIE": "śląskie",
                "SWIETOKRZYSKIE": "świętokrzyskie",
                "WARMINSKO_MAZURSKIE": "warmińsko-mazurskie",
                "WIELKOPOLSKIE": "wielkopolskie",
                "ZACHODNIOPOMORSKIE": "zachodniopomorskie"
            }
        },
        {
            "name": "DecisionType",
            "values": {
                "POSITIVE": "POZYTYWNA",
                "TO_VERIFICATION": "DO WERYFIKACJI"
            }
        },
        {
            "name": "MaritalStatus",
            "values": {
                "SINGLE": "Panna/Kawaler",
                "MARRIAGE": "Małżeństwo",
                "DIVORCED": "Rozwiedziony/a",
                "WIDOW": "Wdowiec/a"
            }
        },
        {
            "name": "LegalForm",
            "values": {
                "DZIALALNOSC_JEDNOOSOBOWA": "działalność jednoosobowa",
                "SPOLKA_CYWILNA": "spółka cywilna",
                "SPOLKA_JAWNA": "spółka jawna",
                "SPOLKA_Z_OGRANICZONA_ODPOWIEDZIALNOSCIA": "spółka z ograniczoną odpowiedzialnością",
                "SPOLKA_AKCYJNA": "spółka akcyjna",
                "SPOLKA_KOMANDYTOWA": "spółka komandytowa",
                "SPOLKA_KOMANDYTOWO_AKCYJNA": "spółka komandytowo-akcyjna",
                "SPOLKA_PARTNERSKA": "spółka partnerska",
                "STOWARZYSZENIE": "stowarzyszenie",
                "FUNDACJA": "fundacja",
                "PRZEDSIEBIORSTWO_PANSTWOWE": "przedsiębiorstwo państwowe"
            }
        },
        {
            "name": "BikKrdStatus",
            "values": {
                "POSITIVE": "POZYTYWNY",
                "NEGATIVE": "NEGATYWNY",
                "UNKNOWN": "NIEZNANY"
            }
        },
        {
            "name": "CustomerType",
            "values": {
                "COMPANY": "Firma",
                "PERSON": "Konsument"
            }
        },
        {
            "name": "SeparationType",
            "values": {
                "YES": "TAK",
                "NO": "NIE",
                "NOT_APPLICABLE": "NIE DOTYCZY"
            }
        },
        {
            "name": "IdentityCardType",
            "values": {
                "ID_CARD": "Dowód osobisty",
                "PASSPORT": "Paszport",
                "DRIVING_LICENSE": "Prawo jazdy",
                "RESIDENCE_CARD": "Karta pobytu"
            }
        },
        {
            "name": "EmploymentForm",
            "values": {
                "EMPLOYMENT_CONTRACT": "umowa o pracę",
                "MANDATORY_CONTRACT": "umowa zlecenie/o dzieło/inne"
            }
        },
        {
            "name": "AllCountries",
            "values": {
                "AF": "Afganistan",
                "AL": "Albania",
                "DZ": "Algieria",
                "AD": "Andora",
                "AO": "Angola",
                "AI": "Anguilla",
                "AQ": "Antarktyda",
                "AG": "Antigua i Barbuda",
                "SA": "Arabia Saudyjska",
                "AR": "Argentyna",
                "AM": "Armenia",
                "AW": "Aruba",
                "AU": "Australia",
                "AT": "Austria",
                "AZ": "Azerbejdżan",
                "BS": "Bahamy",
                "BH": "Bahrajn",
                "BD": "Bangladesz",
                "BB": "Barbados",
                "BE": "Belgia",
                "BZ": "Belize",
                "BJ": "Benin",
                "BM": "Bermudy",
                "BT": "Bhutan",
                "BY": "Białoruś",
                "BO": "Boliwia",
                "BQ": "Bonaire, Sint Eustatius i Saba",
                "BA": "Bośnia i Hercegowina",
                "BW": "Botswana",
                "BR": "Brazylia",
                "BN": "Brunei Darussalam",
                "BG": "Bułgaria",
                "BF": "Burkina Faso",
                "BI": "Burundi",
                "XC": "Ceuta",
                "CL": "Chile",
                "CN": "Chiny",
                "CW": "Curaçao",
                "HR": "Chorwacja",
                "CY": "Cypr",
                "TD": "Czad",
                "ME": "Czarnogóra",
                "CZ": "Czechy",
                "DK": "Dania",
                "DM": "Dominika",
                "DO": "Dominikana",
                "DJ": "Dżibuti",
                "EG": "Egipt",
                "EC": "Ekwador",
                "ER": "Erytrea",
                "EE": "Estonia",
                "ET": "Etiopia",
                "FK": "Falklandy",
                "FJ": "Fidżi Republika",
                "PH": "Filipiny",
                "FI": "Finlandia",
                "TF": "Francuskie Terytorium Południowe",
                "FR": "Francja",
                "GA": "Gabon",
                "GM": "Gambia",
                "GH": "Ghana",
                "GI": "Gibraltar",
                "GR": "Grecja",
                "GD": "Grenada",
                "GL": "Grenlandia",
                "GE": "Gruzja",
                "GU": "Guam",
                "GY": "Gujana",
                "GT": "Gwatemala",
                "GN": "Gwinea",
                "GQ": "Gwinea Równikowa",
                "GW": "Gwinea-Bissau",
                "HT": "Haiti",
                "ES": "Hiszpania",
                "HN": "Honduras",
                "HK": "Hongkong",
                "IN": "Indie",
                "ID": "Indonezja",
                "IQ": "Irak",
                "IR": "Iran",
                "IE": "Irlandia",
                "IS": "Islandia",
                "IL": "Izrael",
                "JM": "Jamajka",
                "JP": "Japonia",
                "YE": "Jemen",
                "JO": "Jordania",
                "KY": "Kajmany",
                "KH": "Kambodża",
                "CM": "Kamerun",
                "CA": "Kanada",
                "QA": "Katar",
                "KZ": "Kazachstan",
                "KE": "Kenia",
                "KG": "Kirgistan",
                "KI": "Kiribati",
                "CO": "Kolumbia",
                "KM": "Komory",
                "CG": "Kongo",
                "CD": "Kongo, Republika Demokratyczna",
                "KP": "Koreańska Republika Ludowo-Demokratyczna",
                "XK": "Kosowo",
                "CR": "Kostaryka",
                "CU": "Kuba",
                "KW": "Kuwejt",
                "LA": "Laos",
                "LS": "Lesotho",
                "LB": "Liban",
                "LR": "Liberia",
                "LY": "Libia",
                "LI": "Liechtenstein",
                "LT": "Litwa",
                "LU": "Luksemburg",
                "LV": "Łotwa",
                "MK": "Macedonia Północna",
                "MG": "Madagaskar",
                "YT": "Majotta",
                "MO": "Makau",
                "MW": "Malawi",
                "MV": "Malediwy",
                "MY": "Malezja",
                "ML": "Mali",
                "MT": "Malta",
                "MP": "Mariany Północne",
                "MA": "Maroko",
                "MR": "Mauretania",
                "MU": "Mauritius",
                "MX": "Meksyk",
                "XL": "Melilla",
                "FM": "Mikronezja",
                "UM": "Minor",
                "MD": "Mołdawia",
                "MN": "Mongolia",
                "MS": "Montserrat",
                "MZ": "Mozambik",
                "MM": "Myanmar (Burma)",
                "NA": "Namibia",
                "NR": "Nauru",
                "NP": "Nepal",
                "NL": "Niderlandy",
                "DE": "Niemcy",
                "NE": "Niger",
                "NG": "Nigeria",
                "NI": "Nikaragua",
                "NU": "Niue",
                "NF": "Norfolk",
                "NO": "Norwegia",
                "NC": "Nowa Kaledonia",
                "NZ": "Nowa Zelandia",
                "PS": "Okupowane Terytorium Palestyny",
                "OM": "Oman",
                "PK": "Pakistan",
                "PW": "Palau",
                "PA": "Panama",
                "PG": "Papua Nowa Gwinea",
                "PY": "Paragwaj",
                "PE": "Peru",
                "PN": "Pitcairn",
                "PF": "Polinezja Francuska",
                "PL": "Polska",
                "ZA": "Południowa Afryka",
                "GS": "Południowa Georgia i Południowe Wyspy Sandwich",
                "PT": "Portugalia",
                "KR": "Republika Korei",
                "CF": "Republika Środkowoafryańska",
                "RU": "Rosja",
                "RW": "Rwanda",
                "EH": "Sahara Zachodnia",
                "BL": "Saint Barthelemy",
                "RO": "Rumunia",
                "SV": "Salwador",
                "WS": "Samoa",
                "AS": "Samoa Amerykańskie",
                "SM": "San Marino",
                "SN": "Senegal",
                "XS": "Serbia",
                "SC": "Seszele",
                "SL": "Sierra Leone",
                "SG": "Singapur",
                "SZ": "Suazi",
                "SK": "Słowacja",
                "SI": "Słowenia",
                "SO": "Somalia",
                "LK": "Sri Lanka",
                "PM": "St. Pierre i Miquelon",
                "KN": "St.Kitts i Nevis",
                "LC": "St.Lucia",
                "VC": "St.Vincent i Grenadyny",
                "US": "Stany Zjednoczone Ameryki",
                "SD": "Sudan",
                "SS": "Sudan Południowy",
                "SR": "Surinam",
                "SY": "Syria",
                "CH": "Szwajcaria",
                "SE": "Szwecja",
                "SH": "Święta Helena",
                "TJ": "Tadżykistan",
                "TH": "Tajlandia",
                "TW": "Tajwan",
                "TZ": "Tanzania",
                "TG": "Togo",
                "TK": "Tokelau",
                "TO": "Tonga",
                "TT": "Trynidad i Tobago",
                "TN": "Tunezja",
                "TR": "Turcja",
                "TM": "Turkmenistan",
                "TC": "Wyspy Turks i Caicos",
                "TV": "Tuvalu",
                "UG": "Uganda",
                "UA": "Ukraina",
                "UY": "Urugwaj",
                "UZ": "Uzbekistan",
                "VU": "Vanuatu",
                "WF": "Wallis i Futuna",
                "VA": "Watykan",
                "VE": "Wenezuela",
                "HU": "Węgry",
                "GB": "Wielka Brytania",
                "VN": "Wietnam",
                "IT": "Włochy",
                "TL": "Wschodni Timor",
                "CI": "Wyb.Kości Słoniowej",
                "BV": "Wyspa Bouveta",
                "CX": "Wyspa Bożego Narodzenia",
                "CK": "Wyspy Cooka",
                "VI": "Wyspy Dziewicze-USA",
                "VG": "Wyspy Dziewicze-W.B",
                "HM": "Wyspy Heard i McDonald",
                "CC": "Wyspy Kokosowe (Keelinga)",
                "FO": "Wyspy Owcze",
                "MH": "Wyspy Marshalla",
                "SB": "Wyspy Salomona",
                "SX": "Wyspa Sint Maarten",
                "ST": "Wyspy Św.Tomasza i Książęca",
                "ZM": "Zambia",
                "CV": "Zielony Przylądek",
                "ZW": "Zimbabwe",
                "AE": "Zjednoczone Emiraty Arabskie"
            }
        },
        {
            "name": "IsDrivingLicence",
            "values": {
                "Tak": "Tak",
                "Nie": "Nie"
            }
        },
    ];

    static createDictionaryOptionsValueAsKey = (dictionaryName) => {
        let dictionaryIndex = Dictionary.DICTIONARY_LIST.findIndex(dictionary => dictionary.name === dictionaryName);
        let dictionaryValues = Dictionary.DICTIONARY_LIST[dictionaryIndex].values;

        return Object.keys(dictionaryValues).map(key => (
            <option key={key} value={key}>{dictionaryValues[key]}</option>));
    }

    static createDictionaryOptions = (dictionaryName) => {
        let dictionaryIndex = Dictionary.DICTIONARY_LIST.findIndex(dictionary => dictionary.name === dictionaryName);
        let dictionaryValues = Dictionary.DICTIONARY_LIST[dictionaryIndex].values;

        return Object.keys(dictionaryValues).map(key => (
            <option key={key} value={dictionaryValues[key]}>{dictionaryValues[key]}</option>));
    }




}

export default Dictionary;