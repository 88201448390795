import React, {useContext} from "react";
import BrandsSubmenu from "./BrandsSubmenu";
import ModelsSubmenu from "./ModelsSubmenu";
import ProductSubmenu from "./ProductSubmenu";
import SegmentsSubmenu from "./SegmentsSubmenu";
import BudgetSubmenu from "./BudgetSubmenu";

import closeIcon from "../../assets/images/icons/close.svg";
import {SearchOptionsContext} from "../../context/search-options-context";
import {Link} from "react-router-dom";
import analyitics from "../../utils/analytics";

function ProductMenu(props) {

    let searchOptions = useContext(SearchOptionsContext);

    let showStyle = props.showInMobile ? {
        display: "block"
    } : null;

    const onConfigureClickHandler = () => {
        analyitics.sendEvent({
            category: 'Konfiguruj',
            action: 'Kliknięcie w link do Konfuguruj'
        });

        props.closeClicked();
    }

    return (
        <div id="product-menu"  style={showStyle}>
            <span className="as-link mobileProductMenuClose" onClick={props.closeClicked}><img src={closeIcon} alt="close"/></span>
            <div id="product-menu-wrapper" className="container flex">
                <ProductSubmenu name={"Szukaj marki"} id="menu-item-marki" onClose={props.closeClicked}>
                    <BrandsSubmenu brands={searchOptions.brandsAndModels}/>
                </ProductSubmenu>
                <ProductSubmenu name={"Szukaj modelu"} id="menu-item-model" onClose={props.closeClicked}>
                    <ModelsSubmenu brandsAndModels={searchOptions.brandsAndModels}/>
                </ProductSubmenu>
                <ProductSubmenu name={"Szukaj segmentu"} id="menu-item-segment" onClose={props.closeClicked}>
                    <SegmentsSubmenu segments={searchOptions.segments}/>
                </ProductSubmenu>
                <ProductSubmenu name={"Szukaj wg raty"} id="menu-item-budzet" onClose={props.closeClicked}>
                    <BudgetSubmenu installments={searchOptions.installments}/>
                </ProductSubmenu>
                <Link to="/konfiguruj" className="product-menu-item" id="menu-item-konfigurator" onClick={onConfigureClickHandler}>
                    <span>Konfiguruj</span>
                </Link>
            </div>
        </div>

    );
}

export default ProductMenu;