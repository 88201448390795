import React, {Fragment, useEffect, useState} from "react";
import {Slider} from "@blueprintjs/core";
import LabelWithInfo from "../../../components/LabelWithInfo/LabelWithInfo";

const { forwardRef, useImperativeHandle } = React;

const FeeSlider = forwardRef((props, ref) => {

    const STEP_SIZE = 500;
    let minValue = Math.ceil(props.min/STEP_SIZE) * STEP_SIZE;
    let maxValue = Math.ceil(props.max/STEP_SIZE) * STEP_SIZE;

    const [sliderValue, setSliderValue] = useState(props.defaultValue);
    const [inputValue, setInputValue] = useState(props.defaultValue);

    useImperativeHandle(ref, () => ({
        getStepSize() {
            const stepSize = STEP_SIZE;
            return stepSize;
        }
    }));

    useEffect(() => {
            setInputValue(sliderValue);
        }, [sliderValue]);

    useEffect(() => setSliderValue(prevValue => validatedValue(prevValue)), [props.min, props.max]);

    useEffect(() => {
        if(props.calculationResult && props.calculationResult.initialFeeValueNet){

            if(props.brutto){
                setInputValue(props.calculationResult.initialFeeValueGross)
            }else{
                setInputValue(props.calculationResult.initialFeeValueNet)
            }

        }
    }, [props.brutto])

    const validatedValue = (value) => {
        if(value < minValue){
            return minValue;
        }

        if(value > maxValue){
            return maxValue;
        }

        return value;
    }

    const sliderValueChangeHandler = (value) => setSliderValue(Math.round(validatedValue(value)/STEP_SIZE) * STEP_SIZE);
    const inputValueChangeHandler = (event) => setInputValue(parseInt(event.target.value));
    const inputBlurHandler = (event) => {
        const validatedInputValue = validatedValue(inputValue);
        setSliderValue(validatedInputValue);
        props.onChange(validatedInputValue);
    }
    const sliderReleaseHandler = (value) => {
        props.onChange(value);
    }

    return (
        <Fragment>
            <div className="flex">
                <p>
                    <LabelWithInfo infoCode={props.infoCode} strongTitle={true}/>
                </p>
                <div className="flex">
                    <input type="number" value={inputValue} min={minValue}
                           max={maxValue} onChange={inputValueChangeHandler} onBlur={inputBlurHandler}></input>
                    {props.isCreditCalculation ?
                        <span>zł brutto</span> :
                        <span>zł {props.brutto ? "brutto" : "netto"}</span>
                    }
                </div>
            </div>

            <Slider
                min={minValue}
                max={maxValue}
                stepSize={STEP_SIZE}
                labelRenderer={false}
                value={validatedValue(sliderValue)}
                className="go-slider"
                onChange={sliderValueChangeHandler}
                onRelease={sliderReleaseHandler}
            />

        </Fragment>
    );
});

export default FeeSlider;