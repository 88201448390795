import React, {useContext, useEffect, useState} from "react";
import {CustomerTypeContext, isCustomerCompany} from "../../context/customer-type-context";
import DiscountPercentageRibbonFactory from "../../utils/DiscountPercentageRibbonFactory";
import Carousel from "react-gallery-carousel";
import 'react-gallery-carousel/dist/index.css';

import gwaranacjaZwrotu from "../../assets/images/icons/gwarancja.svg";
import CalculationTypeLabel from "../../components/VehicleOfferListItem/CalculationTypeLabel";

function VehicleImage({offer, isGwarancjaZwrotuActive}) {
    const customerContext = useContext(CustomerTypeContext);

    const [isFirstRender, setIsFirstRender] = useState(false)
    const discountClass = isFirstRender ? "ribbon-hot-text ribbon-hot-text-active" : "ribbon-hot-text";

    let discountPercentageComponent = null;

    if(offer.discountVisible){
        DiscountPercentageRibbonFactory.setOffer(offer);
        DiscountPercentageRibbonFactory.setIsCompany(isCustomerCompany(customerContext));
        DiscountPercentageRibbonFactory.setStyleClassName(discountClass);

        discountPercentageComponent = DiscountPercentageRibbonFactory.create();
    } else if (offer.specialOffer){
        discountPercentageComponent = ( <div className="ribbon-hot-text">
            <p>OFERTA SPECJALNA</p>
        </div>);
    }

    let inStock = (offer.availability === "IN_STOCK" || offer.availability.includes("IN_STOCK"))
        ? (<span className="ribbon-od-reki"></span>) : null;

    useEffect(() => {
        //Animacja napisu z rabatem na wejsciu na oferte
        setTimeout(() => {setIsFirstRender(true)}, 800)
        setTimeout(() => {setIsFirstRender(false)}, 1100)
        setTimeout(() => {setIsFirstRender(true)}, 1400)
        setTimeout(() => {setIsFirstRender(false)}, 1700)
        setTimeout(() => {setIsFirstRender(true)}, 2000)
        setTimeout(() => {setIsFirstRender(false)}, 2300)
    }, [])

    const images = [
        {
            src: offer.imageUrl,
        }
    ].concat(offer.additionalImageUrls.map(url => ({src: url})));

    let imageComponent = <div className="single-offer-image-container"><img className="cover-img" src={offer.imageUrl} alt=""/></div>;
    if(images.length > 1){
        imageComponent = <Carousel
            style={{height: "95%"}}
            images={images}
            canAutoPlay={false}
            hasIndexBoard={false}
            hasSizeButton={false}
            shouldMaximizeOnClick={true}
            shouldMinimizeOnClick={true}
        />;
    }


    return (
        <div className="single-cover vehicle-gallery">

            <CalculationTypeLabel calculationType={offer.calculationType} viewType="offer-view" />
            {discountPercentageComponent}
            {inStock}
            {isGwarancjaZwrotuActive && (
                <span className="gwarancja-zwrotu flex">
                        <img src={gwaranacjaZwrotu} alt="Gwarancja zwrotu"/>
                        Gwarancja zwrotu
                    </span>
            )}
            {imageComponent}
            {offer.isPreviewPhoto ?
            <div className="preview-foto">Zdjęcie poglądowe</div> : ''}
            <div className="offer-id-foto">ID oferty: {offer.id}</div>
        </div>
    );
}

export default VehicleImage;