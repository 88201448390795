export var initialAllowedCalculationParameters = {
     "defaultContractLengthMonth": 60,
     "contractLengthMonths": [
         24,
         36,
         48,
         60,
         72
     ],
     "buyoutValues": [
         {
             "contractLengthMonths": 24,
             "minimumBuyoutValueNet": 100,
             "maximumBuyoutValueNet": 100000,
             "minimumBuyoutValueGross": 100,
             "maximumBuyoutValueGross": 100000
         },
         {
             "contractLengthMonths": 36,
             "minimumBuyoutValueNet": 100,
             "maximumBuyoutValueNet": 100000,
             "minimumBuyoutValueGross": 100,
             "maximumBuyoutValueGross": 100000
         },
         {
             "contractLengthMonths": 48,
             "minimumBuyoutValueNet": 100,
             "maximumBuyoutValueNet": 100000,
             "minimumBuyoutValueGross": 100,
             "maximumBuyoutValueGross": 100000
         },
         {
             "contractLengthMonths": 60,
             "minimumBuyoutValueNet": 100,
             "maximumBuyoutValueNet": 100000,
             "minimumBuyoutValueGross": 100,
             "maximumBuyoutValueGross": 100000
         },
         {
             "contractLengthMonths": 72,
             "minimumBuyoutValueNet": 100,
             "maximumBuyoutValueNet": 100000,
             "minimumBuyoutValueGross": 100,
             "maximumBuyoutValueGross": 100000
         }
     ],
     "initialFeeValues": {
         "minimumInitialFeeNet": 0,
         "maximumInitialFeeNet": 100000,
         "minimumInitialFeeGross": 0,
         "maximumInitialFeeGross": 100000
     }
 };
