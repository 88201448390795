import React, {useContext, useEffect, useState} from "react";
import MetaTagsManipulator from "../../utils/MetaTagsManipulator";
import backgroundImage from "../../assets/images/content/konfigurator.jpg";
import {CustomerTypeContext, isCustomerCompany} from "../../context/customer-type-context";
import SellerCodeReader from "../../utils/SellerCodeReader";
import StepForBasicData from "../Reservation/BasicData/StepForBasicData";
import ReservationHeader from "../Reservation/ReservationHeader";
import StepForInitialData from "../Reservation/InitialData/StepForInitialData";
import StepForAdditionalData from "../Reservation/AdditionalData/StepForAdditionalData";
import DecisionInfo from "../Reservation/DecisionInfo";
import Session from "../../utils/Session";
import {goKredytService, goVehisService} from "../../utils/axios";
import sleeper from "../../utils/sleeper";
import AppToaster from "../../components/AppToaster";
import SpinnerOverlay from "../../components/SpinnerOverlay/SpinnerOverlay";
import ProposalRepository from "../../utils/ProposalRepository";
import analytics from "../../utils/analytics";
import StepForAdditionalDataForCredit from "./AdditionalData/StepForAdditionalDataForCredit";
import StepForBasicDataForCredit from "./BasicData/StepForBasicDataForCredit";

function CreditReservation({offer, onShowOffer}) {

    MetaTagsManipulator.setTitle("Rezerwacja samochodu");

    const customerContext = useContext(CustomerTypeContext);
    const isCompany = isCustomerCompany(customerContext);

    let proposalRepository = new ProposalRepository();
    let sellerCodeReader = new SellerCodeReader();

    const [proposal, setProposal] = useState({
        "vehicleOffer": {
            "id": offer.vehicle.id,
            "vehicleType": offer.vehicle.vehicleType,
            "brandName": offer.vehicle.brandName,
            "modelName": offer.vehicle.modelName,
            "versionName": offer.vehicle.versionName,
            "condition": offer.vehicle.condition,
            "manufactureYear": offer.vehicle.manufactureYear,
            "segment": offer.vehicle.segment,
            "bodyType": offer.vehicle.bodyType,
            "gearboxType": offer.vehicle.gearboxType,
            "fuelType": offer.vehicle.fuelType,
            "driveType": offer.vehicle.driveType,
            "engineCapacity": offer.vehicle.engineCapacity,
            "enginePowerHP": offer.vehicle.enginePowerHP,
            "installmentNet": offer.vehicle.installmentNet,
            "installmentGross": offer.vehicle.installmentGross,
            "minimumPriceNet": offer.vehicle.minimumPriceNet,
            "minimumPriceGross": offer.vehicle.minimumPriceGross,
            "basicPriceNet": offer.vehicle.basicPriceNet,
            "basicPriceGross": offer.vehicle.basicPriceGross,
            "vehicleMargin": offer.vehicle.vehicleMargin,
            "availability": offer.vehicle.availability,
            "specialOffer": offer.vehicle.specialOffer,
            "color": offer.vehicle.color,
            "city": offer.vehicle.city,
            "numberOfDoors": offer.vehicle.numberOfDoors,
            "numberOfSeats": offer.vehicle.numberOfSeats,
            "standardEquipment": offer.vehicle.standardEquipment,
            "additionalEquipment": offer.vehicle.additionalEquipment,
            "imageUrl": offer.vehicle.imageUrl,
            "additionalImageUrls": offer.vehicle.additionalImageUrls,
            "financialProduct": offer.vehicle.financialProduct,
            "hasReturnGuarantee": offer.vehicle.hasReturnGuarantee,
            "salesDocument": offer.vehicle.salesDocument,
            "mileage": offer.vehicle.mileage,
            "vin": offer.vehicle.vin,
            "attachments": offer.vehicle.attachments,
            "supplier": {
                "email": offer.vehicle.supplier.email,
                "phoneNumber": offer.vehicle.supplier.phoneNumber,
            },
            "description": offer.vehicle.description,
            "discountVisible": offer.vehicle.discountVisible,
            "registeredDate": offer.vehicle.registeredDate
        },
        "vehicleId": offer.vehicle.id,
        "sellerCode": sellerCodeReader.readSellerCode(),
        "offerUrl": window.location.href,
        "customerType": customerContext[0],
        "employmentForm": "EMPLOYMENT_CONTRACT",
        "employer": {
            "nip": "",
            "phoneNumber": ""
        },
        "company": {
            "nip": "",
            "creationDate": null,
            "name": "",
            "accountingForm": "",
            "pkd": "",
            "bikStatus": "",
            "location": "",
            "voivodeship": "",
            "legalForm": "",
            "employeesNumber": "",
            "loadedFromRegon": false
        },
        "calculation": offer.calculation,
        "representatives": [{
            "pesel": "",
            "birthDate": "",
            "email": "",
            "phoneNumber": "",
            "location": "",
            "firstName": "",
            "lastName": "",
            "identityCardType": "ID_CARD",
            "identityCardNumber": "",
            "identityCardExpirationDate": null,
            "identityCardAuthority": "",
            "maritalStatus": null,
            "propertySeparation": null,
            "address": "",
            "postalCode": "",
            "spouse": null,
            "nationality": "Polska",
            "companyNip": ""
        }],
        "agreements": []
    });

    const [calculationOffer, setCalculationOffer] = useState({
        "vehicleId": offer.vehicle.id,
        "sellerCode": sellerCodeReader.readSellerCode(),
        "offerUrl": window.location.href,
        "customerType": customerContext[0],
        "calculation": offer.calculation,
        "vehicleOffer": offer.vehicle,
        "agreements": [],
        "email": "",
        "nip": "",
        "phoneNumber": "",
        "firstName": ""
    });

    useEffect(() => {
        let persistedOffer = proposalRepository.readOffer();
        if(persistedOffer){
            setCalculationOffer(prevState => ({
                ...prevState,
                email: persistedOffer.email,
                nip:  isCompany ? persistedOffer.nip : "",
                phoneNumber: persistedOffer.phoneNumber,
                firstName: isCompany ? "" : persistedOffer.firstName,
                agreements: persistedOffer.agreements
            }));
        }

        let persistedProposal = proposalRepository.readProposal();
        if(persistedProposal){
            setProposal(prevState => ({
                ...prevState,
                company: persistedProposal.company,
                representatives: persistedProposal.representatives,
                agreements: persistedProposal.agreements
            }));
        }

    },[]);

    const [decision, setDecision] = useState(null);
    const [isOpenSpinner, setIsOpenSpinner] = useState(false);

    let backgroundStyle = {
        background: `url(${backgroundImage}) center center no-repeat`,
        backgroundSize: "cover",
        paddingTop: "70px"
    };


    const [currentStepNumber, setCurrentStepNumber] = useState(1);

    function scrollToTop(){
        window.scrollTo({behavior: 'smooth', top: 150});
    }

    const backToOfferHandler = () => {
        proposalRepository.persistOffer(calculationOffer);
        proposalRepository.persistProposal(proposal);
        onShowOffer();
    }

    const goToNextStep = () => {
        scrollToTop();
        analytics.sendEvent({
            category: 'Rezerwacja  - Krok ' + currentStepNumber,
            action: 'Klikniecie',
            label: 'Kliknieto w dalej',
            value: 1
        });

        setCurrentStepNumber(prevState => prevState + 1);
    }
    const goToPreviousStep = () => {
        scrollToTop();
        setCurrentStepNumber(prevState => prevState > 0 ? prevState - 1 : 0);
    }

    function getProposalUrl() {
        let sessionId = Session.readSessionId();

        let endpoint = "/proposals";

        if(!isCompany){
            endpoint = "/personproposals"
        }

        return endpoint + "?sessionId=" + sessionId;
    }

    function getProposal() {
        let proposalToSend = {...proposal};

        if(!isCompany){
            proposalToSend = {...proposal, representative: proposal.representatives[0], representatives: null};
        }

        return proposalToSend;
    }

    const sendProposalButtonClickHandler = () => {
        setIsOpenSpinner(true);

        goKredytService.post(getProposalUrl(), getProposal()).then(sleeper(5000)).then(response => {
            setDecision(response.data);
            Session.resetSession();
            proposalRepository.removeAll();
            goToNextStep();
        }).catch(error => {
            AppToaster.show({message: "Ups! Wystąpił jakiś błąd. Spróbuj ponownie.", intent: "danger"})
        }).finally(() => {
            setIsOpenSpinner(false);
        });
    };

    const sendCalculationOffer = () => {

        let sessionId = Session.readSessionId();

        goKredytService.post("/calculationOffer?sessionId=" + sessionId, calculationOffer);
    };

    const goToBasicDataStep = () => {
        if (calculationOffer.nip) {
            setProposal(prevState => ({
                ...prevState,
                company: {
                    ...prevState.company,
                    nip: calculationOffer.nip,
                    loadedFromRegon: false
                },
                representatives: [{
                    ...prevState.representatives[0],
                    email: calculationOffer.email,
                    phoneNumber: calculationOffer.phoneNumber,
                }],
                agreements: [...calculationOffer.agreements]
            }))
        }

        if (calculationOffer.firstName) {
            setProposal(prevState => ({
                ...prevState,
                company: null,
                representatives: [{
                    ...prevState.representatives[0],
                    firstName: calculationOffer.firstName,
                    email: calculationOffer.email,
                    phoneNumber: calculationOffer.phoneNumber,
                }],
                agreements: [...calculationOffer.agreements]
            }))
        }

        sendCalculationOffer();

        goToNextStep();
    }

    const initialDataChangeHandler = initialData => setCalculationOffer(prevState => ({...prevState, ...initialData}));
    const proposalChangeHandler = proposal => setProposal(prevState => ({...prevState, ...proposal}));

    let currentStep = null;

    switch (currentStepNumber) {
        case 1:
            currentStep = (<StepForInitialData
                isCompany={isCompany}
                offerWithCalculation={calculationOffer}
                checkedAgreements={proposal.agreements}
                onChange={initialDataChangeHandler}
                onNextClick={goToBasicDataStep}
            />);
            break;
        case 2:
            currentStep = (<StepForBasicDataForCredit
                    isCompany={isCompany}
                    proposal={proposal}
                    onChange={proposalChangeHandler}
                    onNextClick={goToNextStep}
                    onBackClick={goToPreviousStep}
                />
            );
            break;
        case 3:
            currentStep = (<StepForAdditionalDataForCredit
                    isCompany={isCompany}
                    proposal={proposal}
                    onChange={proposalChangeHandler}
                    onNextClick={sendProposalButtonClickHandler}
                    onBackClick={goToPreviousStep}
                />
            );
            break;
        case 4:
            currentStep = (
                <div className="config-modal-body">
                    <DecisionInfo
                        decision={decision}
                    />
                </div>
            );
            break;
    }

    return (
        <div id="config" style={backgroundStyle}>
            <h2>Rezerwacja samochodu</h2>
            <h3>{offer.vehicle.brandName + " " + offer.vehicle.versionName}</h3>
            <div id="config-modal">

                <ReservationHeader
                    currentNumber={currentStepNumber}
                    onStepChange={number => setCurrentStepNumber(number)}
                    onBackToOffer={backToOfferHandler}
                />

                {currentStep}

            </div>
            <SpinnerOverlay isOpen={isOpenSpinner} usePortal={true}/>

        </div>
    );
}

export default CreditReservation;