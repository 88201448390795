import React from "react";
import {Icon} from "@blueprintjs/core";

function Attachments({attachments}) {

    let attachmentList = null;

    if(attachments) {
        attachmentList = attachments.map(attachment => (
            <div key={attachment.url} className="line"><strong><a href={attachment.url} target="_blank">{attachment.name} <Icon
                icon="share" iconSize={12}/></a></strong></div>
        ));
    }

    return attachments.length > 0 &&
            <div className="single-info single-box">
                <h3>Dokumenty do pobrania</h3>

                {attachmentList}
            </div>
        ;
}

export default Attachments;