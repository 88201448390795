import React, {useContext, useState} from "react";
import RangeSelect from "../ProductSearchField/RangeSelect";
import URLSearchParamsConverter from "../../utils/URLSearchParamsConverter";
import {useHistory} from "react-router";
import {CustomerTypeContext, isCustomerCompany} from "../../context/customer-type-context";

function BudgetSubmenu(props) {

    const [budget, setBudget] = useState({from: 0, to: 0});

    const customerContext = useContext(CustomerTypeContext);
    const history = useHistory();

    const budgetChangeHandler = (budget) => {
        setBudget(budget);
    };

    const searchClickHandler = () => {
        let searchParameters = null;
        if (isCustomerCompany(customerContext)) {
            searchParameters = {
                installmentNetFrom: budget.from,
                installmentNetTo: budget.to,
                installmentGrossFrom: null,
                installmentGrossTo: null
            };
        } else {
            searchParameters = {
                installmentGrossFrom: budget.from,
                installmentGrossTo: budget.to,
                installmentNetFrom: null,
                installmentNetTo: null,
            };
        }

        history.push({
            pathname: "/lista-ofert",
            search: URLSearchParamsConverter.createURLSearchString(searchParameters)
        });

        props.onClose();
    };


    return (
        <form action="">
            <RangeSelect
                onChange={budgetChangeHandler}
                items={props.installments}
                range={budget}
                unit="zł"
                placeholder={["Rata od", "Rata do"]}
            />
            <button type="button" onClick={searchClickHandler}>Szukaj</button>
        </form>
    );
}

export default BudgetSubmenu;