import React, {Fragment, useContext} from "react";
import {CustomerTypeContext, isCustomerCompany} from "../../../context/customer-type-context";
import LabelWithInfo from "../../../components/LabelWithInfo/LabelWithInfo";
import FormatUtils from "../../../utils/FormatUtils";

function CalculationSummary(props) {

    const customerContext = useContext(CustomerTypeContext);

    const isCompany = isCustomerCompany(customerContext);

    let brakLabel = (<span style={{color: "red"}}>brak</span>);

    let acOcValue = props.isIndividualInsurance ? "indywidualnie" : (props.calculationResult.ocAcInsuranceNet === 0 ?
        (brakLabel) : ((isCompany ?
            props.calculationResult.ocAcInsuranceNet : props.calculationResult.ocAcInsuranceGross) + " zł"));

    let gapValue = props.calculationResult.gapInsuranceNet === 0 ?
        (brakLabel) : ((isCompany ?
            props.calculationResult.gapInsuranceNet : props.calculationResult.gapInsuranceGross) + " zł");

    let inPrice = props.isGwarancjaZwrotuActive ? "w cenie" : brakLabel;

    const brakLabelFormatter = (condition, value, isLabel) => {
        if(condition === brakLabel) {
            if(props.offer.hasReturnGuarantee) {
                if(isLabel) return value; else return brakLabel;
            } else return '';
        } else return value;
    }

    return (
        <Fragment>
            <h4 id="podsumowanie">Podsumowanie</h4>
            <div className="form-summary">
                <div className="line flex">
                    <div className="line-left">
                        Okres leasingu:
                    </div>
                    <div className="line-middle">
                                        <span id="zmienOkres" className="changeForm"
                                              onClick={props.changeContractLength}>
                                            Zmień <i className="fa fa-angle-right"></i>
                                        </span>
                    </div>
                    <div className="line-right">
                        {props.calculationResult.numberOfInstallments} m-cy
                    </div>

                </div>

                <div className="line flex">
                    <div className="line-left">
                        Opłata wstępna:
                    </div>
                    <div className="line-middle">
                        <span id="zmienOplate" className="changeForm" onClick={props.changeInitialFee}>
                            Zmień <i className="fa fa-angle-right"></i>
                        </span>
                    </div>
                    <div className="line-right">
                        {isCompany ?
                            FormatUtils.formatMoney(props.calculationResult.initialFeeValueNet,0) : FormatUtils.formatMoney(props.calculationResult.initialFeeValueGross,0)} zł
                    </div>
                </div>

                <div className="line flex">
                    <div className="line-left">
                        Wykup:
                    </div>
                    <div className="line-middle">
                                        <span id="zmienWykup" className="changeForm" onClick={props.changeBuyoutValue}>
                                            Zmień <i className="fa fa-angle-right"></i>
                                        </span>
                    </div>
                    <div className="line-right">
                        {isCompany ?
                            FormatUtils.formatMoney(props.calculationResult.buyoutValueNet,0) : FormatUtils.formatMoney(props.calculationResult.buyoutValueGross,0)} zł
                    </div>
                </div>
            </div>

            <h4 id="rata">Miesięczna rata</h4>
            <div className="form-summary">
                <div className="line flex">
                    <div className="line-left">
                        Rata leasingu:
                    </div>

                    <div className="line-right">
                        {isCompany ?
                            FormatUtils.formatMoney(props.calculationResult.installmentNet,0) : FormatUtils.formatMoney(props.calculationResult.installmentGross,0)} zł
                    </div>

                </div>
                {props.isNew && props.allInsuranceVisible && <>
                    <div className="line flex">
                        <div className="line-left">
                            Ubezpieczenie:
                        </div>

                        <div className="line-middle" style={{
                            whiteSpace: "nowrap",
                            overflow: "visible",
                            display: "flex",
                            justifyContent: "space-around"
                        }}>
                            {brakLabelFormatter(acOcValue, props.isIndividualInsurance ? "OC/AC" : "OC/AC na " + props.calculationParameters.insuranceLength + " miesięcy", true)}<br/>
                            GAP na cały okres umowy<br/>
                            {brakLabelFormatter(inPrice, "Assistance", true)}
                        </div>
                        <div className="line-right">
                            {brakLabelFormatter(acOcValue,acOcValue)}<br/>
                            {gapValue}<br/>
                            {brakLabelFormatter(inPrice,inPrice)}
                        </div>
                    </div>

                    {props.offer.hasReturnGuarantee && <>
                        <div className="line flex">
                            <div className="line-left">
                                <LabelWithInfo infoCode="gwarancja-zwrotu"/>
                            </div>

                            <div className="line-right">
                                {inPrice}
                            </div>

                        </div>
                    </> }

                    {props.offer.hasReturnGuarantee && <>
                        <div className="line flex">
                            <div className="line-left line-service-card">
                                <LabelWithInfo infoCode="karta-serwisowa"/>
                            </div>

                            <div className="line-right">
                                {inPrice}
                            </div>

                        </div>
                    </> }

                    {props.offer.hasReturnGuarantee && <>
                        <div className="line flex">
                            <div className="line-left">
                                <LabelWithInfo infoCode="gps"/>
                            </div>

                            <div className="line-right">
                                {inPrice}
                            </div>

                        </div>
                    </> }

                    <div className="line flex">
                        <div className="line-left">
                            <LabelWithInfo infoCode="rejestracja-auta"/>
                        </div>
                        <div className="line-right">
                            w cenie
                        </div>
                    </div>
                </>
                }

                {!props.isNew &&  props.allInsuranceVisible &&
                <div className="line flex">
                    <div className="line-left">
                        Ubezpieczenie:
                    </div>

                    <div className="line-middle" style={{
                        whiteSpace: "nowrap",
                        overflow: "visible",
                        display: "flex",
                        justifyContent: "space-around"
                    }}>
                        GAP na cały okres umowy
                    </div>
                    <div className="line-right">

                        {gapValue}
                    </div>
                </div>
                }

            </div>
        </Fragment>
    );
}

export default CalculationSummary;