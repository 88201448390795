import cityIcon from '../assets/images/content/nadwozia/city.png';
import hatchbackIcon from '../assets/images/content/nadwozia/hatchback.png';
import suvIcon from '../assets/images/content/nadwozia/suv.png';
import dostawczyIcon from '../assets/images/content/nadwozia/dostawczy.png';
import pickupIcon from '../assets/images/content/nadwozia/pickup.png';
import sredniVanIcon from '../assets/images/content/nadwozia/sredni-van.png';
import malyVanIcon from '../assets/images/content/nadwozia/maly-van.png';
import sportIcon from '../assets/images/content/nadwozia/sport.png';
import premiumIcon from '../assets/images/content/nadwozia/premium.png';
import kombiVanIcon from '../assets/images/content/nadwozia/kombi-van.png';
import sedanIcon from '../assets/images/content/nadwozia/sedan.png';
import minivanIcon from '../assets/images/content/nadwozia/maly-van.png';
import middlevanIcon from '../assets/images/content/nadwozia/sredni-van.png';
import bigvanIcon from '../assets/images/content/nadwozia/dostawczy.png';


const SegmentIcons = {
    "Miejskie / małe": {icon: cityIcon},
    "Kompaktowe / średnie": {icon: hatchbackIcon},
    "SUVy / terenowe duże": {icon: suvIcon},
    "SUVy / terenowe średnie": {icon: suvIcon},
    "SUVy / terenowe małe": {icon: suvIcon},
    "Vany duże / minibusy": {icon: dostawczyIcon},
    "Pick-up": {icon: pickupIcon},
    "Luksusowe": {icon: premiumIcon},
    "Limuzyny / duże": {icon: sedanIcon},
    "Sportowe": {icon: sportIcon},
    "Kombivany": {icon: kombiVanIcon},
    "Kombivany / minibusy": {icon: kombiVanIcon},
    "Vany średnie": {icon: sredniVanIcon},
    "Vany małe": {icon: malyVanIcon},
    "Inne": {icon: hatchbackIcon},
    "Małe dostawcze": {icon: minivanIcon},
    "Średnie dostawcze": {icon: middlevanIcon},
    "Duże dostawcze": {icon: bigvanIcon}
}

export default SegmentIcons;