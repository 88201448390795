import React, {Suspense, useContext, useEffect, useState} from 'react';
import {Redirect, Route, Switch} from "react-router";

import useGoogleAnalytics from "../../hooks/useGoogleAnalytics/useGoogleAnalytics";

import {Spinner} from "@blueprintjs/core";

import Header from '../Header/Header';
import Footer from "../Footer/Footer";
import HomePage from "../HomePage/HomePage";
import CookiesInfo from "../CookiesInfo/CookiesInfo";
import ContactPage from "../ContactPage/ContactPage";
import FaqPage from "../FaqPage/FaqPage";
import OfferListPage from "../OfferListPage/OfferListPage";
import OfferPage from "../OfferPage/OfferPage";
import ScrollToTop from "../../components/ScrollToTop/ScrollToTop";
import SearchConfigurator from "../SearchConfigurator/SearchConfigurator";
import FavouritesVehiclePage from "../OfferListPage/FavouritesVehiclePage";
import ChoosenVehiclePage from "../OfferListPage/ChoosenVehiclePage";
import BlogPage from "../BlogPage/BlogPage";
import BlogPost from "../BlogPage/BlogPost";
import BlogCategoryPage from "../BlogPage/BlogCategoryPage";
import BlogTagPage from "../BlogPage/BlogTagPage";

import {CustomerTypeContext} from "../../context/customer-type-context";
import {SearchOptionsContext} from "../../context/search-options-context";
import {FavouriteVehiclesContext} from "../../context/favourite-vehicles-context";
import {SellerContext} from "../../context/seller-context";

import {goVehisService} from '../../utils/axios';
import SellerCodeReader from '../../utils/SellerCodeReader';
import brandImages from "../../utils/BrandImages";
import FormatUtils from "../../utils/FormatUtils";
import UtmReader from "../../utils/UtmReader";
import BlogDataAccess from "../../utils/BlogDataAccess";
import backgroundImage from "../../assets/images/content/audi-intro.jpg";
import Routes from "./Routes"

const SimplePage = React.lazy(() => import("../SimplePage/SimplePage"))

function Layout() {

    useGoogleAnalytics();

    const [amountFavouriteVehicles, setAmountFavouriteVehicles] = useState();

    const [blogDataAccess] = useState(new BlogDataAccess())
    const [blogBackgroundImage, setBlogBackgroundImage] = useState(backgroundImage)

    const [seller, setSeller] = useState({
        code: SellerCodeReader.DEFAULT_SELLER_CODE,
        name: "GO-leasing Sp. z o.o. - Centrala Olsztyn",
        city: "Olsztyn",
        postalCode: "10-416",
        street: "Towarowa  9f/49",
        phone: "799 350 636",
        email: "goauto@go-leasing.pl",
        webpageUrl: "https://go-leasing.pl",
    });

    let sellerCodeReader = new SellerCodeReader();
    let code = sellerCodeReader.readSellerCode();
    const [sellerCode] = useState(code);

    useEffect(() => {
        if(localStorage.getItem("favouriteVehicles") === null) {
            localStorage.setItem("favouriteVehicles", JSON.stringify([]));
        }
        let retrievedVehicles = localStorage.getItem('favouriteVehicles');
        let retrievedVehiclesToArray = JSON.parse(retrievedVehicles);
        let amountOfVehicles;
        if(retrievedVehiclesToArray !== null && retrievedVehiclesToArray !== undefined) {
            amountOfVehicles = retrievedVehiclesToArray.length;
            setAmountFavouriteVehicles(amountOfVehicles);
        }
    }, []);

    useEffect(() => {
        blogDataAccess.getBlogMainImage().then(setBlogBackgroundImage);
    }, [])

    useEffect(() => {
        // omit fetching seller for default code
        if (code !== SellerCodeReader.DEFAULT_SELLER_CODE) {
            goVehisService.get('/sellers/' + code)
                .then(response => {
                    let sellerData = {...response.data};

                    if(sellerData) {
                        sellerData.phone = FormatUtils.formatPhone(sellerData.phone);
                        setSeller(sellerData);
                    }
                })
                .catch(error => console.log("Cannot get seller"));
        }
    }, [sellerCode]);

    const [customerType, setCustomerType] = useState("COMPANY");
    const [companyOnly, setCompanyOnly] = useState(false);
    const [searchOptions, setSearchOptions] = useState({
        "customerTypes": {
            company: "COMPANY",
            person: "PERSON"
        },
        "availabilities": {
            inStock: "IN_STOCK",
            toOrder: "TO_ORDER"
        },
        "brandsAndModels": [],
        "bodyTypes": [
            "Coupe / Roadster",
            "Furgon",
            "Hatchback",
            "Kabriolet",
            "Kombi",
            "Pickup",
            "Sedan",
            "SUV / Crossover",
            "VAN / Bus"
        ],
        "segments": [
            "Kompaktowe / średnie",
            "Limuzyny / duże",
            "Luksusowe",
            "Miejskie / małe",
            "SUVy / terenowe duże",
            "SUVy / terenowe małe",
            "SUVy / terenowe średnie"
        ],
        "fuelTypes": [
            "Benzyna",
            "Diesel",
            "Elektryczny",
            "Hybryda"
        ],
        "gearboxTypes": [
            "Automatyczna",
            "Manualna"
        ],
        "driveTypes": [
            "Na wszystkie koła",
            "Napęd przedni",
            "Napęd tylny"
        ],
        "installments": [
            500,
            1000,
            1500,
            2000,
            2500,
            3000,
            3500,
            4000,
            4500,
            5000,
            5500,
            6000,
            6500,
            7000,
            7500,
            8000,
            8500,
            9000,
            9500
        ],
        "enginePowerHp": [
            50,
            100,
            150,
            200,
            250,
            300
        ],
        "numbersOfDoors": [
            3,
            4,
            5
        ],
        "numbersOfSeats": [
          3,
          4,
          5,
          7
        ],
        "prices": [
            30000,
            35000,
            40000,
            50000,
            65000,
            80000,
            100000,
            200000,
            300000,
            500000,
            1000000
        ],
        "mileages": [
            0,
            15000,
            30000,
            45000,
            60000,
            75000,
            90000,
            105000
        ],
        "manufactureYears": [
            2015,
            2016,
            2017,
            2018,
            2019,
            2020,
            2021
        ],
        "equipment": [
            "Kamera cofania",
            "Czujniki parkowania",
            "Asystent parkowania",
            "Aktywny tempomat",
            "Dach panoramiczny",
            "Podgrzewane fotele"
        ],
        "colors": [],
        "calculationTypes" : [
            "Leasing",
            "Wynajem",
            "Kredyt"
        ],
        "deliveryCars" : {
            "customerTypes": {
                company: "COMPANY",
                person: "PERSON"
            },
            "availabilities": {
                inStock: "IN_STOCK",
                toOrder: "TO_ORDER"
            },
            "brandsAndModels": [],
            "bodyTypes": [
                "Coupe / Roadster",
                "Furgon",
                "Hatchback",
                "Kabriolet",
                "Kombi",
                "Pickup",
                "Sedan",
                "SUV / Crossover",
                "VAN / Bus"
            ],
            "segments": [
                "Kompaktowe / średnie",
                "Limuzyny / duże",
                "Luksusowe",
                "Miejskie / małe",
                "SUVy / terenowe duże",
                "SUVy / terenowe małe",
                "SUVy / terenowe średnie"
            ],
            "fuelTypes": [
                "Benzyna",
                "Diesel",
                "Elektryczny",
                "Hybryda"
            ],
            "gearboxTypes": [
                "Automatyczna",
                "Manualna"
            ],
            "driveTypes": [
                "Na wszystkie koła",
                "Napęd przedni",
                "Napęd tylny"
            ],
            "installments": [
                500,
                1000,
                1500,
                2000,
                2500,
                3000,
                3500,
                4000,
                4500,
                5000,
                5500,
                6000,
                6500,
                7000,
                7500,
                8000,
                8500,
                9000,
                9500
            ],
            "enginePowerHp": [
                50,
                100,
                150,
                200,
                250,
                300
            ],
            "numbersOfDoors": [
                3,
                4,
                5
            ],
            "numbersOfSeats": [
                2,
                3,
                5
            ],
            "prices": [
                30000,
                35000,
                40000,
                50000,
                65000,
                80000,
                100000,
                200000,
                300000,
                500000,
                1000000
            ],
            "mileages": [
                0,
                15000,
                30000,
                45000,
                60000,
                75000,
                90000,
                105000
            ],
            "manufactureYears": [
                2015,
                2016,
                2017,
                2018,
                2019,
                2020,
                2021
            ],
            "equipment": [
                "Kamera cofania",
                "Czujniki parkowania",
                "Asystent parkowania",
                "Aktywny tempomat",
                "Dach panoramiczny",
                "Podgrzewane fotele"
            ],
            "colors": [],
            "calculationTypes" : [
                "Leasing",
                "Wynajem",
                "Kredyt"
            ]
        }
    });

    useEffect(() => {
        UtmReader.readUtm("SOURCE");
        UtmReader.readUtm("MEDIUM");
        UtmReader.readUtm("CAMPAIGN");
    }, [])

    useEffect(() => {
        goVehisService.get('/searchoptions')
            .then(response => {
                let newSearchOptions = response.data;
                let brandsAndModels = [...newSearchOptions.brandsAndModels];

                brandsAndModels = brandsAndModels.map((brand) => {
                    let brandNameUnified = brand.name.toLowerCase().replace(/ /g, "-");
                    let brandImage = null;

                    if (brandImages[brandNameUnified] !== undefined) {

                        brandImage = brandImages[brandNameUnified].smallIcon;
                    }

                    return {
                        ...brand,
                        identifier: brandNameUnified,
                        image: brandImage
                    }
                });

                if(newSearchOptions.deliveryCars) {
                    let brandAndModelsDeliveryCars = [...newSearchOptions.deliveryCars.brandsAndModels];
                    brandAndModelsDeliveryCars = brandAndModelsDeliveryCars.map((brand) => {
                        let brandNameUnified = brand.name.toLowerCase().replace(/ /g, "-");
                        let brandImage = null;

                        if (brandImages[brandNameUnified] !== undefined) {
                            brandImage = brandImages[brandNameUnified].smallIcon;
                        }

                        return {
                            ...brand,
                            identifier: brandNameUnified,
                            image: brandImage
                        }
                    })

                    newSearchOptions.deliveryCars.brandsAndModels = brandAndModelsDeliveryCars;
                }
                newSearchOptions.brandsAndModels = brandsAndModels;
                newSearchOptions.customerTypes = {
                    company: newSearchOptions.customerTypes[0],
                    person: newSearchOptions.customerTypes[1]
                };
                newSearchOptions.availabilities = {
                    inStock: newSearchOptions.availabilities[0],
                    toOrder: newSearchOptions.availabilities[1]
                };
                newSearchOptions.mileages = createMileagesList(newSearchOptions.mileages);
                newSearchOptions.years = createManufactureYearsList(newSearchOptions.manufactureYears);
                setSearchOptions(newSearchOptions);
            })
            .catch(error => console.log("Cannot get search options:" + error));

    }, []);

    const createMileagesList = (mileages) => {
        const mileageStep = 15000;
        const maxMileage = Math.max(...mileages)

        const mileagesList = new Array([0]);

        for(let i = 0; i <= maxMileage; i = i + mileageStep){
            mileagesList.push(i + mileageStep);
        }

        return mileagesList;
    }

    const createManufactureYearsList = (years) => {
        const minYear = Math.min(...years)
        const maxYear = Math.max(...years)

        const yearsList = new Array([minYear]);

        for(let i = minYear; i < maxYear; i++){
            yearsList.push(i);
        }

        return yearsList;
    }

    return (
        <CustomerTypeContext.Provider value={[customerType, setCustomerType, searchOptions.customerTypes, companyOnly, setCompanyOnly]}>
            <SearchOptionsContext.Provider value={searchOptions}>
                <SellerContext.Provider value={seller}>
                    <FavouriteVehiclesContext.Provider value={{value: amountFavouriteVehicles, setAmount: setAmountFavouriteVehicles}}>
                            <ScrollToTop />
                            <Header seller={seller}/>
                            <Routes seller={seller} backgroundImage={blogBackgroundImage}/>
                            <Footer seller={seller}/>
                            <CookiesInfo/>
                            <a id="gotop" href="#"></a>
                    </FavouriteVehiclesContext.Provider>
                </SellerContext.Provider>
            </SearchOptionsContext.Provider>
        </CustomerTypeContext.Provider>
    );
}

export default Layout;