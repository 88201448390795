import React, { useContext } from "react";

import logo_01 from "../../assets/images/content/logos/01.svg";
import logo_02 from "../../assets/images/content/logos/02.svg";
import logo_03 from "../../assets/images/content/logos/03.svg";
import logo_04 from "../../assets/images/content/logos/04.svg";
import SellerCodeReader from "../../utils/SellerCodeReader";
import {SellerContext} from "../../context/seller-context";

function SeeAlso(){
    const sellerCodeReader = new SellerCodeReader();
    const seller = useContext(SellerContext);
    return (
        <div id="others">
            <h3 className="center">Zobacz także</h3>
            <div className="container flex">
                <div className="other">
                    <a href={"https://go-leasing.pl/leasing-online/" + sellerCodeReader.readSellerCodeForLeasingOnline()} target="_blank" rel="noopener noreferrer"><img src={logo_01} alt="" /></a>
                    <span>Chcę sfinansować inne auto<br/> nowe lub używane</span>
                    <a href={"https://go-leasing.pl/leasing-online/" + sellerCodeReader.readSellerCodeForLeasingOnline()} target="_blank" rel="noopener noreferrer" className="readmore">Dowiedz się więcej</a>
                </div>
                
                <div className="other">
                    <a href="https://go-auto.pl/ubezpieczenie-gap" target="_blank" rel="noopener noreferrer"><img src={logo_02} alt="" /></a>
                    <span>Chcę kupić ubezpieczenie GAP<br/> do swojego auta</span>
                    <a href="https://go-auto.pl/ubezpieczenie-gap" target="_blank" rel="noopener noreferrer" className="readmore">Dowiedz się więcej</a>
                </div>
                
                <div className="other">
                    <a href={seller.webpageUrl + "/oferta/leasing-all-inclusive/"} target="_blank" rel="noopener noreferrer"><img src={logo_03} alt="" /></a>
                    <span>Chcę kupić auto<br/> w najmie długoterminowym</span>
                    <a href={seller.webpageUrl + "/oferta/leasing-all-inclusive/"} target="_blank" rel="noopener noreferrer" className="readmore">Dowiedz się więcej</a>
                </div>
                
                <div className="other">
                    <a href="https://go-leasing.pl/siec-partnerska-go-leasing/" target="_blank" rel="noopener noreferrer"><img src={logo_04} alt="" /></a>
                    <span>Chcę zostać<br/> waszym Partnerem</span>
                    <a href="https://go-leasing.pl/siec-partnerska-go-leasing/" target="_blank" rel="noopener noreferrer" className="readmore">Dowiedz się więcej</a>
                </div>
                
            </div>
        </div>
    );
}

export default SeeAlso;