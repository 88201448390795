import React, {useContext, useEffect, useRef, useState} from "react";
import {Suggest} from "@blueprintjs/select";
import './SearchField.css';
import {carSearchProps, searchInputProps} from './CarSearchProps';
import {MenuItem} from "@blueprintjs/core";
import {SearchOptionsContext} from "../../context/search-options-context";
import {goVehisService} from "../../utils/axios";
import {useHistory} from "react-router";
import {CustomerTypeContext} from "../../context/customer-type-context";
import ConvertUtils from "../../utils/ConvertUtils";

function SearchField({onItemSelect}) {
    const setCompanyOnly = useContext(CustomerTypeContext)[4];
    const searchOptions = useContext(SearchOptionsContext);
    const history = useHistory();
    const [query, _setQuery] = useState('');
    const queryRef = useRef(query)
    const setQuery = data => {
        queryRef.current = data;
        _setQuery(data)
    }

    const reg = new RegExp('^\\d+$');

    const brandsAndModels = () => {
        let brandsAndModels = [];

        for(let brand of searchOptions.brandsAndModels){
            brandsAndModels.push({
                brand: brand.name,
                model: null,
                fullName: brand.name
            });

            for(let model of brand.models){
                brandsAndModels.push({
                    brand: brand.name,
                    model: model,
                    fullName: brand.name + " " + model
                });
            }
        }
        return brandsAndModels;
    }

    useEffect(() => {
        document.addEventListener('keydown', enterPressedListener);

        return () => {
            document.removeEventListener('keydown', enterPressedListener);
        }
    }, [])

    const enterPressedListener = (e) => {
        if (queryRef.current !== '' && e.code === "Enter") {
            enterClickHandler();
        }
    }

    const queryChangeHandler = (query) => {
        setQuery(query);
    }

    const enterClickHandler = () => {
        processQuery(queryRef.current);
    }

    const inputClickHandler = () => {
        processQuery(query);

        if(query === ''){
            history.replace("/lista-ofert");
        }
    }

    const processQuery = (query) => {
        if(query) {
            if(reg.test(query) === true) {
                offerIdToUrlConverter(query)
            }else{
                let result = brandsAndModels().filter(
                    car => car.fullName.toLowerCase().includes(queryRef.current.toLocaleLowerCase()))
                if(result[0]){
                    onItemSelect(result[0]);
                }
            }
        }
    }

    const offerIdToUrlConverter = (query) => {
        let id = query;
        goVehisService("/vehicles/offers/" + id).then(response => {
            let vehicleOffer = response.data;
            if (vehicleOffer !== undefined) {

                let urlToOffer = ConvertUtils.stringToUrl(vehicleOffer.brandName + "/" +vehicleOffer.versionName+ "/" + id);


                window.location.pathname=urlToOffer;
            }
        }).catch(error => {
            history.replace("/lista-ofert");
        }).finally(() => {
            setQuery('');
        });
    }

    const itemSelectHandler = (car) => {
        onItemSelect(car);
    }

        return (
            <div className="topFormBody flex">
                <Suggest
                    className="topSearchInput"
                    {...carSearchProps}
                    selectedItem={queryRef.current}
                    items={brandsAndModels()}
                    noResults={<MenuItem disabled={true} text={carSearchProps.noResultsText} />}
                    onItemSelect={itemSelectHandler}
                    onQueryChange={queryChangeHandler}
                    resetOnClose={true}
                    openOnKeyDown={false}
                    inputProps={{...searchInputProps, ...{rightElement: (<button type="button" className="flex" onClick={inputClickHandler}><i className="fa fa-search"></i></button>)}}}
                />
            </div>
        );
}

export default SearchField;