import React, {Fragment, useContext} from "react";
import {CustomerTypeContext, isCustomerCompany} from "../../context/customer-type-context";

function StepForCustomerType({onNextClick}) {

    const customerContext = useContext(CustomerTypeContext);
    const [currentCustomerType, setCustomerType, availableCustomerTypes] = customerContext;

    const companyClickHandler = () => setCustomerType(availableCustomerTypes.company);
    const personClickHandler = () => setCustomerType(availableCustomerTypes.person);

    return (
        <Fragment>

            <div className="config-modal-body">

                <h5 className="center">Prowadzisz firmę czy jesteś konsumentem (osoba fizyczna)?</h5>
                <form>
                    <button type="button" onClick={companyClickHandler}
                            className={"fullwidth" + (isCustomerCompany(customerContext) ? " active" : "")}>Firma
                    </button>
                    <button type="button" onClick={personClickHandler}
                            className={"fullwidth" + (isCustomerCompany(customerContext) ? "" : " active")}>Konsument
                    </button>
                </form>

            </div>

            <div className="config-modal-footer">

                <div className="flex">
                    <button type="button" className="lastButton" onClick={onNextClick}>
                        Dalej
                    </button>
                </div>

            </div>
        </Fragment>
    );
}

export default StepForCustomerType;