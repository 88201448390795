import React, {useState} from "react";
import {cmsService} from "../../utils/axios";
import AppToaster from "../../components/AppToaster";
import analyitics from "../../utils/analytics";

function Newsletter(){

    const [subscriberEmail, setSubscriberEmail] = useState("");

    const changeSubscriberEmail = (event) => {
        setSubscriberEmail(event.target.value);
    }

    const submitNewsletterHandler = (event) => {
        event.preventDefault();

        cmsService.post("/NewsletterSubscriber","Email=" + subscriberEmail)
            .then(response => {
                AppToaster.show({ message: "Dziękujemy za dodanie adresu " + response.data.Email + " do naszego newsletter.", intent: "primary"  })

                analyitics.sendEvent({
                    category: 'Newsletter',
                    action: 'Kliknięcie w Zapisz mnie'
                });
            })
            .catch(error => {
                AppToaster.show({ message: "Ups! Wystąpił jakiś błąd. Spróbuj ponownie.", intent: "danger"  })
            })
    }

    return (
        <div id="newsletter">
            <div className="container flex">
                <h4>Wyślijcie mi koniecznie informacje o nowościach</h4>
                <form onSubmit={submitNewsletterHandler} className="flex">
                    <input id="newsletter-email" type="email" value={subscriberEmail} onChange={changeSubscriberEmail} placeholder="Wpisz swój adres e-mail..."/>
                        <button type="submit">Zapisz mnie</button>
                </form>
            </div>
        </div>
    );
}

export default Newsletter;