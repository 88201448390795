import React from "react";
import {Position, Tooltip} from "@blueprintjs/core";

function BikKrdTooltip({children}) {
    return (
        <Tooltip content={(
            <p>Status <strong>POZYTYWNY</strong> oznacza, że nie posiadasz żadnych zaległości w spłacie kredytów lub
                innych zobowiązań gospodarczych;<br/> <strong>NEGATYWNY</strong> - występują zaległości w
                spłatach; <br/><strong>NIEZNANY</strong> - nie jestem pewny/a czy występują zaległości w spłatach</p>)}
                 position={Position.TOP}>
            {children}
        </Tooltip>
    );
}

export default BikKrdTooltip;