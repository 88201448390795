import React, {Fragment, useEffect, useState} from "react";

import PageTitle from "../../components/PageTitle/PageTitle";
import {useHistory} from "react-router";
import {cmsService} from "../../utils/axios";

import logo from "../../assets/images/icons/logo.svg";
import envelope from "../../assets/images/icons/envelope.svg";
import phone from "../../assets/images/icons/phone.svg";
import {Intent, Spinner} from "@blueprintjs/core";
import MetaTagsManipulator from "../../utils/MetaTagsManipulator";
import backgroundImage from "../../assets/images/content/page-intro.png";
import ContactForm from "../../components/ContactForm/ContactForm";
import analyitics from "../../utils/analytics";

function ContactPage(props) {

    const [page, setPage] = useState({
        Title: null,
        Content: null,
        BackgroundURL: null
    });

    const history = useHistory();


    useEffect(() => {
        cmsService.get("/ContactPage?URLSegment=kontakt&limit=1&add_fields=BackgroundURL").then(response => {
            let pageData = response.data.items[0];

            if (pageData !== undefined) {
                if(pageData.BackgroundURL == null){
                    pageData.BackgroundURL = backgroundImage;
                }

                setPage(pageData);

                MetaTagsManipulator.setTitle(pageData.Title);
                MetaTagsManipulator.setDescription(pageData.MetaDescription);
            }

        }).catch(error => {
            history.push("/");
        })
    }, []);

    const submitContactFormHandler = () => {
        analyitics.sendEvent({
            category: 'Podstrona Kontakt',
            action: 'Kliknięcie',
            label: 'Wyślij wiadomość',
            value: 1
        });
    }




    return (
        <Fragment>
            <PageTitle title={page.Title} backgroundImage={page.BackgroundURL}/>

            <div id="content" className="kontakt">
                <div className="container flex">

                    <div id="contact-content">

                        <h2>Skontaktuj się z nami</h2>

                        {page.Content == null ? <Spinner intent={Intent.PRIMARY} size={Spinner.SIZE_STANDARD}/> :
                            <div dangerouslySetInnerHTML={{__html: page.Content}}/>}

                        <hr/>

                        <div className="contact-data flex">
                            <img className="contact-logo" src={logo} alt=""/>
                            <div className="contact-data-text">
                                <h5>Napisz lub zadzwoń do nas</h5>
                                <p>
                            <span className="flex"><img className="contact-mail" src={envelope} alt=""/>
                            <strong><a href={"mailto:" + props.seller.email}>{props.seller.email}</a></strong></span>
                                    <br/>
                                    <span className="flex"><img className="contact-phone" src={phone} alt=""/>
                                        <strong><a href={"tel:" + props.seller.phone}>{props.seller.phone}</a></strong></span>
                                </p>
                            </div>
                        </div>

                    </div>
                    <ContactForm onSuccess={submitContactFormHandler}/>
                </div>
            </div>
        </Fragment>);
}


export default ContactPage;