import React, {Fragment} from "react";
import {FormGroup} from "@blueprintjs/core";
import Validator from "../../../utils/Validator";
import InputErrorMessage from "../InputErrorMessage";

function CompanyInitialForm({offerWithCalculation, formValidator, onChange}) {

    const companyValueChangeHandler = (event) => {
        const {name, value} = event.target;

        onChange({...offerWithCalculation, [name]: value});
    }

    return (
        <Fragment>
            <FormGroup
                className="form-group"
                label="NIP"
                labelFor="nip"
            >
                <input type="text"
                       ref={formValidator.register({
                           required: true,
                           pattern: /[0-9]{10}/,
                           maxLength: 10,
                           validate: value => Validator.validateNip(value)
                       })}
                       name="nip"
                       value={offerWithCalculation.nip}
                       onChange={companyValueChangeHandler}
                       placeholder="Np. 1234567890 (10 cyfr)"/>
                <InputErrorMessage error={formValidator.errors.nip} message="Podaj poprawny numer NIP"/>

            </FormGroup>
            <FormGroup
                className="form-group"
                label="E-mail"
                labelFor="email"
            >
                <input type="email"
                       ref={formValidator.register({required: true})}
                       name={"email"}
                       value={offerWithCalculation.email}
                       onChange={companyValueChangeHandler}/>
                <InputErrorMessage error={formValidator.errors["email"]}
                                   message="Podaj poprawny adres e-mail"/>
            </FormGroup>
            <FormGroup
                className="form-group"
                label="Telefon"
                labelFor="phoneNumber"
            >
                <input type="text"
                       ref={formValidator.register({
                           required: true,
                           pattern: /[0-9]{9}/,
                           maxLength: 9
                       })}
                       name={"phoneNumber"}
                       value={offerWithCalculation.phoneNumber}
                       onChange={companyValueChangeHandler}
                       placeholder="Np. 753421869 (9 cyfr)"/>
                <InputErrorMessage error={formValidator.errors["phoneNumber"]}
                                   message="Podaj poprawny numer telefonu"/>
            </FormGroup>
        </Fragment>
    );

}

export default CompanyInitialForm;