class SellerCodeReader{
    static DEFAULT_SELLER_CODE = "C1";
    static SELLER_CODE_KEY = "SELLER_CODE";

    readSellerCode() {
        let code = this.readCodeFromURLParams();

        if(code && 0 !== code.length){
            localStorage.setItem(SellerCodeReader.SELLER_CODE_KEY, code);
        } else {
            code = localStorage.getItem(SellerCodeReader.SELLER_CODE_KEY);

            if(code === null){
                code = SellerCodeReader.DEFAULT_SELLER_CODE;
            }
        }

        return code;
    };

    readSellerCodeForLeasingOnline() {
        let code = this.readCodeFromURLParams();

        if(code && 0 !== code.length){
            localStorage.setItem(SellerCodeReader.SELLER_CODE_KEY, code);
        } else {
            code = localStorage.getItem(SellerCodeReader.SELLER_CODE_KEY);

            if(code === null){
                code = SellerCodeReader.DEFAULT_SELLER_CODE;
            }
        }

        if(code === "C1") {
            code = '';
        }

        return code;
    }

    readCodeFromURLParams(){
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        let code = urlParams.get('sel_code');
        let codeShort = urlParams.get('s');

        if(code) {
            code = code.replace('/', '');

            if(code === "O1"){
                code = SellerCodeReader.DEFAULT_SELLER_CODE;
            }
        }

        if(codeShort) {
            codeShort = codeShort.replace('/', '');

            if(codeShort === "O1"){
                codeShort = SellerCodeReader.DEFAULT_SELLER_CODE;
            }
        }

        if(code){
            return code;
        }else{
            return codeShort;
        }
    }
}

export default SellerCodeReader;