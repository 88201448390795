import {useEffect} from "react";
import {useLocation} from "react-router-dom";
import URLSearchParamsConverter from "../../utils/URLSearchParamsConverter";

function ScrollToTop({excludedPathNames}) {
    const { pathname } = useLocation();
    const searchParams = window.location.search;
    const shouldScroll = URLSearchParamsConverter.shouldScroll(searchParams);
    useEffect(() => {
        if((excludedPathNames == null || !excludedPathNames.includes(pathname)) && shouldScroll) {
            window.scrollTo(0, 0);
        }
    }, [pathname, excludedPathNames]);

    return null;
}

export default ScrollToTop;