import React, {Fragment} from "react";
import {FormGroup, NumericInput, Position, Tag, Tooltip} from "@blueprintjs/core";
import Validator from "../../../utils/Validator";
import InputErrorMessage from "../InputErrorMessage";

function PersonInitialForm({offerWithCalculation, formValidator, onChange}) {

    const personValueChangeHandler = (event) => {
        const {name, value} = event.target;

        onChange({...offerWithCalculation, [name]: value});
    }

    return (
        <Fragment>

            <FormGroup
                className="form-group"
                label="Imię"
                labelFor="firstName"
            >
                <input type="text"
                       ref={formValidator.register({required: true})}
                       name="firstName"
                       value={offerWithCalculation.firstName}
                       onChange={personValueChangeHandler}/>
                <InputErrorMessage error={formValidator.errors.firstName} message="Podaj swoje imię"/>

            </FormGroup>
            <FormGroup
                className="form-group"
                label="E-mail"
                labelFor="email"
            >
                <input type="email"
                       ref={formValidator.register({required: true})}
                       name={"email"}
                       value={offerWithCalculation.email}
                       onChange={personValueChangeHandler}/>
                <InputErrorMessage error={formValidator.errors["email"]}
                                   message="Podaj poprawny adres e-mail"/>
            </FormGroup>
            <FormGroup
                className="form-group"
                label="Telefon"
                labelFor="phoneNumber"
            >
                <input type="text"
                       ref={formValidator.register({
                           required: true,
                           pattern: /[0-9]{9}/,
                           maxLength: 9
                       })}
                       name={"phoneNumber"}
                       value={offerWithCalculation.phoneNumber}
                       onChange={personValueChangeHandler}
                       placeholder="Np. 753421869 (9 cyfr)"/>
                <InputErrorMessage error={formValidator.errors["phoneNumber"]}
                                   message="Podaj poprawny numer telefonu"/>
            </FormGroup>
        </Fragment>
    );

}

export default PersonInitialForm;