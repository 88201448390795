
class ConvertUtils {

    static stringToUrl(rawString){
        let url = rawString.toLowerCase();
        url = url
                .trim()
                .replace(/\s/g,"-");

        return encodeURI(url);
    }

    static capacityFromCmToDm(capacity) {
        capacity = Number(capacity);

        return (capacity / 1000).toPrecision(2);
    }

    static databaseStringToUrlParam(string) {
        const param = string.replace('-', ' ')

        return encodeURIComponent(param)
    }
}

export default ConvertUtils;