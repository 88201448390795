import React from 'react';
import {Dialog, Icon} from "@blueprintjs/core";
import NotFoundContactForm from "../ContactForm/NotFoundContactForm";
import { useMediaQuery } from 'react-responsive'

function NotFoundContactDialog(props){
    const isMobile = useMediaQuery({ query: '(max-width: 479px)' }) ? true : false;
    const closeButtonSize = isMobile ? 16 : 20;

    return (
        <Dialog
            className="dialog"
            id="sell-call-dialog"
            isOpen={props.isOpen}
            onClose={props.onClose}
            title={""}>
            <Icon
                icon="cross" iconSize={closeButtonSize}
                className="contact-dialog-close-button"
                onClick={props.onClose}/>
            <NotFoundContactForm onSuccess={props.onClose}/>
        </Dialog>
    )
}

export default NotFoundContactDialog