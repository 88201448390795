import React, {Fragment, useContext, useState} from "react";
import {Link, useHistory} from "react-router-dom";
import brandImages from "../../utils/BrandImages";
import FormatUtils from "../../utils/FormatUtils";
import {CustomerTypeContext, isCustomerCompany} from "../../context/customer-type-context";
import FavouriteVehicle from "../../components/FavouriteVehicles/FavouriteVehicle";
import {Popover, Tooltip} from "@blueprintjs/core";
import {Icon} from "@blueprintjs/core";
import SendEmailForm from "../../components/SendEmailForm/SendEmailForm";
import shareIcon from "../../assets/images/icons/share-icon.svg";
import linkIcon from "../../assets/images/icons/link-icon.svg";

import {
    FacebookIcon,
    FacebookMessengerIcon, FacebookMessengerShareButton,
    FacebookShareButton,
    LinkedinIcon, LinkedinShareButton,
    TwitterIcon, TwitterShareButton,
    WhatsappIcon, WhatsappShareButton
} from "react-share";
import AppToaster from "../../components/AppToaster";
import {useMediaQuery} from "react-responsive/src";
import ContactDialog from "../../components/ContactDialog/ContactDialog";

function HeadSection(props) {

    const customerContext = useContext(CustomerTypeContext)
    const history = useHistory();

    const [openSendSpecificationDialog, setOpenSendSpecificationDialog] = useState(false);
    const [isOpenContactDialog, setIsOpenContactDialog] = useState(false);

    let vehicleName = props.offer.brandName + " " + props.offer.versionName;
    let brandNameUnified = props.offer.brandName.toLowerCase().replace(/ /g, "-");

    const goBackClickHandler = () => history.goBack();

    let basicPrice = FormatUtils.formatMoney(isCustomerCompany(customerContext) ? props.offer.basicPriceNet : props.offer.basicPriceGross);
    let priceTypeLabel = isCustomerCompany(customerContext) ? "netto" : "brutto";


    const condition = props.offer.condition;
    const salesDocument = props.offer.salesDocument;

    const minimumPriceFormatter = () => {
        if( condition === "USED" && salesDocument !== "FAKTURA_VAT" ||
            condition === "USED" && salesDocument === "FAKTURA_VAT" && !isCustomerCompany(customerContext) ||
            condition === "DEMO" && salesDocument !== "FAKTURA_VAT" ||
            condition === "DEMO" && salesDocument === "FAKTURA_VAT" && !isCustomerCompany(customerContext)) {
                return <><span> {FormatUtils.formatMoney(props.offer.minimumPriceGross)}</span> {"brutto"}</>;
        } else return <><span>{FormatUtils.formatMoney(isCustomerCompany(customerContext) ? props.offer.minimumPriceNet : props.offer.minimumPriceGross)} </span>
                            {isCustomerCompany(customerContext) ? "netto" : "brutto"}</>;
    }

    const iconSize = useMediaQuery({query: '(max-width: 767px)'}) ? 18 : 12;

    const brandIsVisibleForBasicPrice = () => {
        const brandsToHide = ['seat'];

        return !brandsToHide.includes(props.offer.brandName.toLowerCase())
    }

    const showBasicPrice = props.offer.isNew && brandIsVisibleForBasicPrice();


    const socialButtonsPopover = <div className="social-button-popover">
        <FacebookShareButton class="social-share-button" url={window.location.href}>
            <FacebookIcon size={40} round={true} class="social-share-button"/>
        </FacebookShareButton>
        <FacebookMessengerShareButton class="social-share-button" url={window.location.href}>
            <FacebookMessengerIcon size={40} round={true} class="social-share-button"/>
        </FacebookMessengerShareButton>
        <TwitterShareButton class="social-share-button" url={window.location.href}>
            <TwitterIcon size={40} round={true} class="social-share-button"/>
        </TwitterShareButton>
        <LinkedinShareButton class="social-share-button" url={window.location.href}>
            <LinkedinIcon size={40} round={true} class="social-share-button"/>
        </LinkedinShareButton>
        <WhatsappShareButton class="social-share-button" url={window.location.href}>
            <WhatsappIcon size={40} round={true} class="social-share-button"/>
        </WhatsappShareButton>
    </div>

    const copyOfferLinkHandler = () => {
        navigator.clipboard.writeText(window.location.href);
        AppToaster.show({message: "Skopiowano link.", intent: "success", icon: "info-sign", timeout: 1500});
    }

    const askClickHandler = () => {
        setIsOpenContactDialog(true);
    }

    return (
        <div id="single-intro">
            <div className="container side-padding">
                <div className="breadcrumb">
                    <Link to="/"><i className="fa fa-home"></i></Link>
                    <span>/</span>
                    <span className="as-link" onClick={goBackClickHandler}>Powrót</span>
                    <span>/</span>
                    <span>{vehicleName}</span>
                </div>
                <div className="single-heading-1 flex">
                    <img src={brandImages[brandNameUnified].smallIcon} width="60" alt=""/>
                    <h1>
                        {vehicleName}
                    </h1>
                    <div className="single-heading-info">
                        {showBasicPrice &&
                        <span className="wartosc-katalogowa">Cena katalogowa:
                                <span> {basicPrice}</span> {priceTypeLabel}
                            </span>
                        }
                            <br/>
                        <span className="wartosc-katalogowa">
                            Nasza cena:

                            {props.offer.isNew ?
                                <small><span className="ask-for-price" onClick={askClickHandler}> Zapytaj doradcę</span>
                                    <Icon icon="share" iconSize={12}/></small> :
                                minimumPriceFormatter()
                            }
                        </span>
                        <br/>
                        <div id="favourite-button-head-section">
                            <FavouriteVehicle
                                offer={props.offer}
                            />
                        </div>
                        <Tooltip content="Kopiuj link oferty" placement="left" popoverClassName="display-none">
                            <div className="social-button-svg-wrapper" onClick={() => {
                                copyOfferLinkHandler()
                            }}>
                                <img src={linkIcon} alt="copy" className="social-button-svg"/>
                            </div>
                        </Tooltip>
                        <Popover content={socialButtonsPopover} minimal={true} popoverClassName="social-popover">
                            <Tooltip content="Udostępnij ofertę" placement="left" popoverClassName="display-none">
                                <div className="social-button-svg-wrapper">
                                    <img src={shareIcon} alt="share" className="social-button-svg"/>
                                </div>
                            </Tooltip>
                        </Popover>
                        <Tooltip content="Drukuj" placement="left" popoverClassName="display-none">
                            <div className="social-button-wrapper">
                                <Icon icon="print" iconSize={iconSize} className="social-button" value="print"
                                      onClick={() => window.print()}></Icon>
                            </div>
                        </Tooltip>
                        <Tooltip content="Wyślij specyfikację na e-mail">
                            <div className={"social-button-wrapper"}>
                                <Icon
                                    icon="envelope"
                                    iconSize={iconSize}
                                    className="social-button"
                                    onClick={() => setOpenSendSpecificationDialog(true)}/>
                            </div>
                        </Tooltip>

                        <script async src="https://static.addtoany.com/menu/page.js"></script>
                        <SendEmailForm
                            offer={props.offer}
                            isOpen={openSendSpecificationDialog}
                            close={() => setOpenSendSpecificationDialog(false)}/>
                    </div>
                </div>
            </div>

            <ContactDialog isOpen={isOpenContactDialog}
                           onClose={() => setIsOpenContactDialog(false)} />
        </div>
    );
}

export default HeadSection;