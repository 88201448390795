import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom'

import {Container} from "@material-ui/core";

import BlogDataAccess from "../../utils/BlogDataAccess";

import PageTitle from "../../components/PageTitle/PageTitle";
import FullBlogPost from "../../components/BlogPost/FullBlogPost";
import BlogPostsGrid from "../../components/BlogPost/BlogPostsGrid";
import MetaTagsManipulator from "../../utils/MetaTagsManipulator";


function BlogPost({backgroundImage}) {
    const {postLink} = useParams();

    const [blogDataAccess] = useState(new BlogDataAccess());
    const [morePosts, setMorePosts] = useState(null);
    const [post, setPost] = useState(null);

    const [previousMetaDescription] = useState(MetaTagsManipulator.getDescription())

    useEffect(() => {
        blogDataAccess.getPostByLink(postLink).then(setPost)

        return () => {
            MetaTagsManipulator.setDefaultTitle()
            MetaTagsManipulator.setDescription(previousMetaDescription)
        }
    }, [postLink])

    useEffect(() => {
        if(post){
            getMorePosts(post.tagNames)
            setPostMetaData(post)
        }
    }, [post])

    const setPostMetaData = (post) => {
        if(post.metaTitle) {
            MetaTagsManipulator.setTitle(post.metaTitle)
        }

        if(post.metaDescription) {
            MetaTagsManipulator.setDescription(post.metaDescription)
        }
    }

    const getMorePosts = (tagArray) => {
        let posts = new Array();

        tagArray.forEach(tag => {

            if(!morePosts || morePosts.length < 8) {

                blogDataAccess.getPostsByTagLimited(tag).then(postsByTag => {
                    posts = posts.concat(postsByTag.filter(iteratedPost => iteratedPost.id !== parseInt(post.id)));
                    setMorePosts(posts.slice(0, 8))
                })

            }

        })

        if(!morePosts || morePosts.length < 8) {

            blogDataAccess.getPostsByCategoryLimited(post.categoryName).then(postsByCategory => {
                if(!morePosts || morePosts.length < 8) {
                    posts = posts.concat(postsByCategory.filter(iteratedPost => iteratedPost.id !== parseInt(post.id)));
                    setMorePosts(posts.slice(0, 8))
                }
            })
            
        }

    }


    return (
        <div id="content">
            <PageTitle title={post ? post.title : 'Tytuł'} content={null} backgroundImage={backgroundImage}/>

            <Container fixed className="container" style={{padding:"25px", width: "90%"}}>

                <FullBlogPost blogPost={post} />
                <BlogPostsGrid categoryName="Czytaj więcej" categoryLink={"czytaj-wiecej"} blogPosts={morePosts} />

            </Container>

        </div>
    )
}

export default BlogPost;