import React, {useContext, useEffect, useState} from "react";
import {SellerContext} from "../../context/seller-context";
import SellerCodeReader from "../../utils/SellerCodeReader";
import {Icon} from "@blueprintjs/core";
import analyitics from "../../utils/analytics";
import { useMediaQuery } from 'react-responsive';
import CallpageManager from "../../utils/CallpageManager";
import Validator from "../../utils/Validator";
import ScrollUtils from "../../utils/ScrollUtils";

function CallForm(props) {

    const isDeviceSmallMobile = useMediaQuery({ query: '(max-width: 479px)' })
    const seller = useContext(SellerContext);
    const [phone, setPhone] = useState(seller.phone)
    let sellerCodeReader = new SellerCodeReader();

    const [contactForm, setContactForm] = useState({
        phone: "",
        agreement: false,
        sourceUrl: window.location.href,
        sellerEmail: seller.email,
        sellerPhone: seller.phone,
        sellerCode: sellerCodeReader.readSellerCode(),
    });

    useEffect(() => {
        setContactForm(prevState => (
            {
                ...prevState,
                sellerEmail: seller.email,
                sellerPhone: seller.phone,
                sellerCode: sellerCodeReader.readSellerCode(),
            }
        ));
    }, [seller])

    useEffect(() => {
        if(Validator.nonNumbersExistsInString(contactForm.phone)){
            setContactForm(prevState => ({
                    ...prevState,
                    phone: contactForm.phone.slice(0, -1)
                }
            ));
        }
    }, [contactForm.phone])

    useEffect(() => {
        const footerPhoneNumber = document.getElementById("footer-phone").innerText;
        setPhone(footerPhoneNumber);
    }, [])

    const valueChangeHandler = (event) => {
        const {name, value} = event.target;

        setContactForm(prevState => ({
                ...prevState,
                [name]: value
            })
        );
    }

    const agreementChangeHandler = (event) => {
        const checked = event.target.checked;
        setContactForm(prevState => ({
                ...prevState,
                agreement: checked
            }
        ));
    }

    const phoneClickHandler = () => {
        analyitics.sendEvent({
            category: 'Telefon',
            action: 'Klikniecie',
            label: 'Kliknieto w nr telefonu'
        });
    }

    const submitFormHandler = (event) => {
        event.preventDefault();

        analyitics.sendEvent({
            category: 'Kontakt',
            action: 'Telefon',
            label: 'Proszę o kontakt'
        });

        const contactFormData = {...contactForm};

        CallpageManager.performCallOrSchedule(contactFormData.phone);
        props.onSuccess()
    }

    const rodoForm =
        <label htmlFor="agreement" className="rodo-checkbox-wrapper">
            <input required={true} type="checkbox" name="agreement" id="rodo-checkbox"
                   value={contactForm.agreement}
                   onChange={agreementChangeHandler}/>
            <span style={{textAlign:"left"}}>Wyrażam zgodę na przetwarzanie przez GO LEASING Sp. z o.o., ul. Towarowa 9F lok.49, 10-416 Olsztyn,
                            powyższych danych osobowych w celu umożliwienia kontaktu drogą telefoniczną lub elektroniczną.
                            Jestem świadoma/y, że zgoda może być odwołana w każdym czasie poprzez poinformowanie
                            GO LEASING Sp. z o.o. mailowo na adres biuro@go-leasing.pl lub pisemnie na adres podany w zakładce kontakt.
                            <a href="/rodo" target="_blank">Więcej informacji – sprawdź</a>.</span>
        </label>

    const callMeForm =
        <div className="phone-input">
            <label htmlFor="phone">
                <span>Numer telefonu</span>
                <input required={true} type="text"
                       name="phone"
                       value={contactForm.phone}
                       minLength="9"
                       maxLength="9"
                       pattern="[0-9]{9}"
                       onChange={valueChangeHandler}
                       onClick={() => ScrollUtils.scrollToViewPosition("phone-input")}
                       placeholder="Np. 123456789 (9 cyfr)"/>
            </label>
        </div>

    return (
        <div id="send-phone-form" style={{marginTop: "-20px", marginBottom:"-30px"}}>
            <div className="phone-form">
                <p><strong>Zadzwoń teraz:</strong></p>
                <div>
                    <a href={"tel:" + seller.phone} onClick={phoneClickHandler} className="phone-call">
                        {isDeviceSmallMobile ?
                            <Icon icon="phone" iconSize={26} style={{marginTop:"0.32rem", marginRight:"5px"}}/> :
                            <Icon icon="phone" iconSize={35} style={{marginTop:"0.4rem", marginRight:"7px"}}/>}
                        <span>{phone}</span>
                    </a>
                </div>

                {sellerCodeReader.readSellerCode() === "C1" ?
                    <div>
                        <p><strong>lub zostaw swój numer, oddzwonimy!</strong></p>
                        <p>Pracujemy w dni robocze od 8:00 do 20:00</p>
                    </div>
                    : null}
            </div>

            <form onSubmit={submitFormHandler}>
                {sellerCodeReader.readSellerCode() === "C1" ? callMeForm : null}

                {sellerCodeReader.readSellerCode() === "C1" ? rodoForm : null}

                {sellerCodeReader.readSellerCode() === "C1" ? <button type="submit" style={{marginBottom:"25px"}}>Proszę o kontakt</button> : null}
            </form>
        </div>
    );
}

export default CallForm;