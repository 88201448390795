import React, {useState, useEffect} from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import PromiseUtils from "../../utils/PromiseUtils";
import {Link} from "react-router-dom";
import ReactHtmlParser from "react-html-parser";

function BlogPostTeaser({blogPost}) {
    const [post, setPost] = useState(null)

    useEffect(() => {
        if(PromiseUtils.isPromise(blogPost)) {
            blogPost.then(setPost)
        }else{
            setPost(blogPost)
        }
    }, [blogPost])

    return (
        <div className="blogFeatured blogItem flex flex-wrap">
            <div className="blogCover">
                {post ? <Link to={`../../blog/post/${post.link}`}><img src={post.backgroundUrl} alt=""/></Link> : <Skeleton variant="rect" width={350} height={263} />}
            </div>
            <div className="blogShort">

                <h4>{post ? <Link to={`../../blog/post/${post.link}`} className="blogTitle">{ReactHtmlParser(post.title)}</Link> : <Skeleton variant="text" width={350} />}</h4>

                <div className="blogShort">
                    <p>{post ? <Link to={`../../blog/post/${post.link}`}>{<p style={{textAlign: 'justify'}}>{ReactHtmlParser(post.lead)}</p>}</Link> : <Skeleton variant="text" width={350}/>}</p>
                </div>

                <div className="blogTags">
                    {post ? post.tagLinks.map(tagLink => {
                        return <Link to={`../../blog/tag/${encodeURI(tagLink)}`} className="blogTag">{tagLink.replace(/-/g, ' ')}</Link>
                    }) : <Skeleton variant="text" width={200}/>}
                </div>

            </div>
        </div>
    );
}

export default BlogPostTeaser;