import React from "react";
import OwlCarousel from "react-owl-carousel";

function ExternalPortalLogos(props) {

    const pageLogos = props.logos.map((logo,index) => (
        <div className="logo-slide" key={index}>
                <img src={logo} alt=""/>
        </div>
    ));

    if(props.logos.length > 0) {
        return (
            <div id="logos">
                <div className="container">
                    <OwlCarousel
                        id="logos-slider"
                        loop={true}
                        margin={40}
                        dots={true}
                        nav={false}
                        mouseDrag={false}
                        autoplay={true}
                        responsive={{
                            0: {items: 3, margin: 0},
                            480: {items: 3},
                            768: {items: 4},
                            1024: {items: 6},
                            1199: {items: 8}
                        }}>
                        {pageLogos}

                    </OwlCarousel>

                </div>
            </div>
        );
    }

    return null;

}

export default ExternalPortalLogos;