import React, {Fragment, useContext, useEffect, useState} from "react";
import MetaTagsManipulator from "../../utils/MetaTagsManipulator";
import {useHistory, useParams} from "react-router";
import {goVehisService} from "../../utils/axios";
import HeadSection from "./HeadSection";
import {Spinner} from "@blueprintjs/core";
import VehicleImage from "./VehicleImage";
import OfferVariants from "./OfferVariants";
import VehicleInfo from "./VehicleInfo";
import VehicleEquipment from "./VehicleEquipment";
import UrlDecorator from "../../utils/UrlDecorator";
import Reservation from "../Reservation/Reservation";
import {CustomerTypeContext} from "../../context/customer-type-context";
import ConvertUtils from "../../utils/ConvertUtils";
import PrintSellerContact from "../../components/PrintSellerContact/PrintSellerContact";
import PrintLogo from "../../components/PrintLogo/PrintLogo";
import SimilarOffers from "../HomePage/SimilarOffers";
import ProcessSteps from "../../components/ProcessSteps/ProcessSteps";
import InstallmentCalculatorStrategy from "./InstallmentCalculator/InstallmentCalculatorStrategy";
import Attachments from "./Attachments";
import OfferDescription from "./OfferDescription";
import CreditReservation from "../CreditReservation/CreditReservation";
import CustomerTypeSwitch from "../../components/CustomerTypeSwitch/CustomerTypeSwitch";

function OfferPage() {

    UrlDecorator.addSellerCode();

    const [offer, setOffer] = useState(null);
    const [showOrder, setShowOrder] = useState(false);
    const [showOderForCredit, setShowOrderForCredit] = useState(false);
    const [calculationOffer, setCalculationOffer] = useState(null);
    const [isGwarancjaZwrotuActive, setIsGwarancjaZwrotuActive] = useState(true);
    const setCompanyOnly = useContext(CustomerTypeContext)[4];
    const searchParams = useParams();

    const history = useHistory();

    useEffect(() => {
        goVehisService(`/vehicles/offers/${searchParams.id}`).then(response => {
            let vehicleOffer = response.data;
            if (vehicleOffer !== undefined) {
                setOffer({...vehicleOffer, isNew: vehicleOffer.condition === 'NEW'});

                let vehicleName = vehicleOffer.brandName + " " + vehicleOffer.versionName;

                setCompanyOnly(vehicleOffer.customerType.length < 2);

                MetaTagsManipulator.setTitle(vehicleName);
                MetaTagsManipulator.setDescription(vehicleName);
            }
        }).catch(error => {
            goVehisService(`/vehicles/variants?brand=${searchParams.brand}&version=${encodeURIComponent(searchParams.version)}`).then(response => {
                let vehicleOffer = response.data;
                if (vehicleOffer) {
                    history.replace(
                        ConvertUtils.stringToUrl("/" + vehicleOffer.brandName + "/" + vehicleOffer.versionName.trim() + "/" + vehicleOffer.id));
                }else{
                    goVehisService(`/vehicles/model?version=${searchParams.version}&sortingType=INSTALLMENT_ASC&start=0&limit=10`).then(response => {
                        let modelVersions = response.data;
                        if(modelVersions.length > 0){
                            const representativeModel = modelVersions[0];
                            history.replace(`/lista-ofert?brands=${representativeModel.brandName}&models=${representativeModel.modelName}`);
                        }else{
                            goVehisService(`/vehicles/segment?version=${searchParams.version}&sortingType=INSTALLMENT_ASC&start=0&limit=10`).then(response => {
                                let segmentName = response.data;

                                if(segmentName !== "undefined_segment"){
                                    history.replace(`/lista-ofert?segments=${segmentName}`);
                                }else{
                                    history.replace(
                                        ConvertUtils.stringToUrl(`/lista-ofert`));
                                }
                            })
                        }
                    })
                }
            })
        });

    }, [searchParams]);

    useEffect(() => {
        UrlDecorator.addUtmParameters(sessionStorage.getItem("UTM_SOURCE"),
                                      sessionStorage.getItem("UTM_MEDIUM"),
                                      sessionStorage.getItem("UTM_CAMPAIGN"))
    }, [window.location.href])

    const showOrderClick = (showOrder, calculationOffer) => {
        setShowOrder(showOrder);
        setCalculationOffer(calculationOffer);
        window.scrollTo({behavior: 'smooth', top: 150});
    }

    const showOrderClickForCredit = (showOrder, calculationOffer) => {
        setShowOrderForCredit(showOrder);
        setCalculationOffer(calculationOffer);
        window.scrollTo({behavior: 'smooth', top: 150});
    }

    if(showOrder){
        return (<Reservation offer={calculationOffer} onShowOffer={() => {
            setShowOrder(false)
        }} />);
    }

    if(showOderForCredit) {
        return (<CreditReservation offer={calculationOffer} onShowOffer={() => {
            setShowOrderForCredit(false);
            window.location.reload(true);
        }} />);
    }

    if (offer) {
        return (
                <Fragment>
                    <HeadSection offer={offer}/>
                    <PrintLogo />
                    <div id="content">
                        <div className="container side-padding flex">

                            <div id="single-left">
                                <VehicleImage offer={offer} isGwarancjaZwrotuActive={isGwarancjaZwrotuActive}/>
                                <OfferVariants offerId={offer.id}/>
                                <VehicleInfo offer={offer} />
                                <OfferDescription description={offer.description} />
                                <VehicleEquipment offer={offer} />
                                <Attachments attachments={offer.attachments} />
                            </div>
                            <div id="single-right">
                                <div className="single-toggle">
                                    <CustomerTypeSwitch/>
                                </div>
                                <InstallmentCalculatorStrategy
                                    offer={offer}
                                    initialCalculation={ calculationOffer ? calculationOffer.calculation : null}
                                    onShowOrderClick={showOrderClick}
                                    onShowOrderClickForCredit={showOrderClickForCredit}
                                    onGwarancjaZwrotuChange={active => setIsGwarancjaZwrotuActive(active)}/>
                            </div>

                        </div>
                        <ProcessSteps/>
                    </div>


                    <PrintSellerContact/>
                    <SimilarOffers offer={offer} />
                </Fragment>
        );
    }

    return <Spinner/>;
}

export default OfferPage;