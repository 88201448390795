import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";

const withFormErrorHandler = WrappedComponent => props => {

    const [hasErrorsBySend, setHasErrorsBySend] = useState(false);
    const {register, errors, trigger} = useForm();

    useEffect(() => {
        if (Object.keys(errors).length > 0 && hasErrorsBySend) {
            let firstError = Object.keys(errors)[0];
            let errorPosition = document.getElementsByName(firstError)[0].getBoundingClientRect().top + window.scrollY;
            window.scrollTo({behavior: 'smooth', top: errorPosition - 50});
            setHasErrorsBySend(false);
        }
    }, [hasErrorsBySend]);

    const onCheckErrorHandler = (successMethod) => {
        trigger().then(result => result ? successMethod() : setHasErrorsBySend(true));
    }

    return (
        <WrappedComponent {...props} formValidator={{register, errors}} onCheckErrorHandler={onCheckErrorHandler}/>
    );
}

export default withFormErrorHandler;