import {Position, Tooltip} from "@blueprintjs/core";
import React, {useContext, useEffect, useState} from "react";
import star from "../../assets/images/icons/star.svg";
import starEmpty from "../../assets/images/icons/star-empty.svg";
import AppToaster from "../AppToaster";
import {FavouriteVehiclesContext} from "../../context/favourite-vehicles-context";
import analyitics from "../../utils/analytics";

function FavouriteVehicles(props) {

    const [starIcon, setStarIcon] = useState('');
    const [isFavourite, setIsFavourite] = useState();
    const [tooltipContent, setTooltipContent] = useState('');

    const amountOfVehicles = useContext(FavouriteVehiclesContext);

    useEffect(() => {
        if(localStorage.getItem("favouriteVehicles") === null) {
            localStorage.setItem("favouriteVehicles", JSON.stringify([]));
        }
        let retrievedVehicles = localStorage.getItem('favouriteVehicles');
        let retrievedVehiclesToArray = JSON.parse(retrievedVehicles);
        if(retrievedVehiclesToArray !== null ){
            if(checkIsArrayContainsCurrentVehicleId(retrievedVehiclesToArray) === true) {
                setStarIcon(star);
                setIsFavourite(true);
                setTooltipContent('Usuń z ulubionych');
            } else {
                setStarIcon(starEmpty);
                setIsFavourite(false);
                setTooltipContent('Dodaj do ulubionych');
            }
        }
    }, [])


    const checkInputValue = () => {
        if(Array.isArray(props.offer.vehicleIds)) {
            return props.offer.vehicleIds[0].toString();
        } else if(props.offer.id !== undefined) {
            return props.offer.id.toString();
        }
    }

    const checkIsArrayContainsCurrentVehicleId = (array) => {
        if(props.offer.vehicleIds !== undefined) {
                let isArrayContainsCurrentVehicleId = (array.indexOf(checkInputValue()) > -1);
                return isArrayContainsCurrentVehicleId;
        } else if (props.offer.id !== undefined) {
            let isArrayContainsCurrentVehicleId = (array.indexOf(checkInputValue()) > -1);
            return isArrayContainsCurrentVehicleId;
        }
    }

    const deleteVehicleIdFromLocalStorage = () => {
        let retrievedVehicles = localStorage.getItem('favouriteVehicles');
        let retrievedVehiclesToArray = JSON.parse(retrievedVehicles);
        retrievedVehiclesToArray = props.deleteAllVersionIds(retrievedVehiclesToArray, props.offer.vehicleIds)
        let afterEliminatedDuplicates=[...new Set(retrievedVehiclesToArray)];
        localStorage.setItem('favouriteVehicles', JSON.stringify(afterEliminatedDuplicates));
    }

    const addVehicleIdToLocalStorage = () => {
        let retrievedVehicles = localStorage.getItem('favouriteVehicles');
        let retrievedVehiclesToArray = JSON.parse(retrievedVehicles);
        let offerId = checkInputValue();
        retrievedVehiclesToArray.push(offerId);
        let afterEliminationDuplicates =[...new Set(retrievedVehiclesToArray)];
        localStorage.setItem('favouriteVehicles', JSON.stringify(afterEliminationDuplicates));
        return retrievedVehiclesToArray;
    }

    const showMessageToaster = (status) => {
        if(status === true) {
            AppToaster.show({message: 'Dodano do ulubionych', intent: "success", icon: "info-sign", timeout: 1500});
        } else {
            AppToaster.show({message: 'Usunięto z ulubionych', intent: "warning", icon: "info-sign", timeout: 1500});
        }
    }

    const onClickFavouriteButtonHandler = (event) => {
        event.preventDefault();

        if(isFavourite === true) {
            deleteVehicleIdFromLocalStorage();
            amountOfVehicles.setAmount(prevState => prevState-1);
            setStarIcon(starEmpty);
            setIsFavourite(!isFavourite);
            setTooltipContent('Dodaj do ulubionych');
            showMessageToaster(false);
        } else if(isFavourite === false) {
            addVehicleIdToLocalStorage();
            amountOfVehicles.setAmount(prevState => prevState+1);
            setStarIcon(star);
            setIsFavourite(!isFavourite);
            setTooltipContent('Usuń z ulubionych');
            showMessageToaster(true);

            analyitics.sendEvent({
                category: 'Ulubione',
                action: 'Dodano do ulubionych'
            });
        }

        try{
            props.refreshVehicles()
        }catch(err){

        }
    }

    return (
        <div id="favourite-button-offer-page">
            <Tooltip content={tooltipContent} position={Position.RIGHT}>
                <img src={starIcon} onClick={onClickFavouriteButtonHandler} />
            </Tooltip>
        </div>
    );
}

export default FavouriteVehicles;

