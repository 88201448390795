import {cmsService} from "./axios";
import PostDTO from "../components/BlogPost/PostDTO";
import BannerDTO from "../components/BlogPost/BannerDTO";

class BlogDataAccess {

    getBlogMainImage() {

        return new Promise((resolve) => {
            cmsService.get("BlogPage?limit=1&fields=BackgroundURL").then(response => {
                resolve(response.data.items[0].BackgroundURL);
            })
        }).catch(error => {
            console.log(error)
        })

    }

    getAvailableCategories() {

        return new Promise((resolve) => {
            cmsService.get("/Category?fields=CategoryLink,CategoryName").then(response => {
                const availableCategories = new Map();

                response.data.items.forEach(category => {
                    availableCategories.set(category.CategoryLink, category.CategoryName)
                })

                resolve(availableCategories);
            })
        }).catch(error => {
            console.log(error)
        })

    }

    getAvailableTags() {

        return new Promise((resolve) => {
            cmsService.get("/Tag?fields=TagLink,TagName").then(response => {
                const availableTags = new Map();

                response.data.items.forEach(tag => {
                    availableTags.set(tag.TagLink, tag.TagName)
                })

                console.log(availableTags)
                resolve(availableTags);
            })
        }).catch(error => {
            console.log(error)
        })

    }

    getNewPostsLimited() {

        return new Promise((resolve) => {
            cmsService.get("/BlogPage?limit=1&fields=PostsLimited").then(response => {
                const newPosts = new Array();

                response.data.items[0].PostsLimited.forEach(jsonPost => {
                    newPosts.push(new PostDTO(jsonPost))
                })

                resolve(newPosts)
            })
        }).catch(error => {
            console.log(error)
        })

    }

    getNewPosts() {

        return new Promise((resolve) => {
            cmsService.get("/BlogPage?limit=1&fields=Posts").then(response => {
                const newPosts = new Array();

                response.data.items[0].Posts.forEach(jsonPost => {
                    newPosts.push(new PostDTO(jsonPost))
                })

                resolve(newPosts)
            })
        }).catch(error => {
            console.log(error)
        })

    }

    getNewestPost() {

        return new Promise((resolve) => {
            cmsService.get("/BlogPage?limit=1&fields=NewestPost").then(response => {
                const newPost = new PostDTO(response.data.items[0].NewestPost[0]);

                resolve(newPost)
            })
        }).catch(error => {
            console.log(error)
        })

    }

    getNewestPostsPaginated(page, postsAmount) {

        return new Promise(resolve => {
            cmsService.get(`/BlogPage?limit=1&fields=NewestPostsPaginated&page=${page}&postsAmount=${postsAmount}`).then(response => {
                const posts = new Array();

                response.data.items[0].NewestPostsPaginated.forEach(jsonPost => {
                    posts.push(new PostDTO(jsonPost))
                })

                resolve(posts)
            })
        }).catch(error => {
            console.log(error)
        })

    }

    getPostById(id) {

        return new Promise((resolve) => {
            cmsService.get(`/BlogPage?limit=1&fields=PostById&postId=${id}`).then(response => {
                const newPost = new PostDTO(response.data.items[0].PostById[0]);

                resolve(newPost)
            })
        }).catch(error => {
            console.log(error)
        })

    }

    getPostByLink(link) {

        return new Promise((resolve) => {
            cmsService.get(`BlogPost?PostLink=/blog/${link}/`).then(response => {
                const newPost = new PostDTO(response.data.items[0]);

                resolve(newPost)
            })
        }).catch(error => {
            console.log(error)
        })

    }

    getPostsByTagLimited(tag) {

        return new Promise(resolve => {
            cmsService.get(`/Tag?TagName=${tag}&fields=PostsLimited`).then(response => {
                const posts = new Array();

                response.data.items[0].PostsLimited.forEach(jsonPost => {
                    posts.push(new PostDTO(jsonPost))
                })

                resolve(posts)
            })
        }).catch(error => {
            console.log(error)
        })

    }

    getPostsByTag(tag) {

        return new Promise(resolve => {
            cmsService.get(`/Tag?TagName=${tag}&fields=Posts`).then(response => {
                const posts = new Array();
                
                response.data.items[0].Posts.forEach(jsonPost => {
                    posts.push(new PostDTO(jsonPost))
                })

                resolve(posts)
            })
        }).catch(error => {
            console.log(error)
        })

    }

    getPostsByTagLink(tagLink) {

        return new Promise(resolve => {
            cmsService.get(`/Tag?TagLink=${tagLink}&fields=Posts`).then(response => {
                const posts = new Array();

                response.data.items[0].Posts.forEach(jsonPost => {
                    posts.push(new PostDTO(jsonPost))
                })

                resolve(posts)
            })
        }).catch(error => {
            console.log(error)
        })

    }

    getPostsByTagPaginated(tag, page, postsAmount) {

        return new Promise(resolve => {
            cmsService.get(`/Tag?TagName=${tag}&fields=PostsPaginated&page=${page}&postsAmount=${postsAmount}`).then(response => {
                const posts = new Array();

                response.data.items[0].PostsPaginated.forEach(jsonPost => {
                    posts.push(new PostDTO(jsonPost))
                })

                resolve(posts)
            })
        }).catch(error => {
            console.log(error)
        })

    }

    getPostsByCategoryLimited(category) {
        return new Promise(resolve => {
            cmsService.get(`/Category?CategoryName=${category}&fields=PostsLimited`).then(response => {
                const posts = new Array();

                if(response.data.items[0]){
                    response.data.items[0].PostsLimited.forEach(jsonPost => {
                        posts.push(new PostDTO(jsonPost))
                    })
                }

                resolve(posts)
            })
        }).catch(error => {
            console.log(error)
        })

    }

    getPostsByCategory(category) {
        return new Promise(resolve => {
            cmsService.get(`/Category?CategoryName=${category}&fields=Posts`).then(response => {
                const posts = new Array();

                if(response.data.items[0]){
                    response.data.items[0].Posts.forEach(jsonPost => {
                        posts.push(new PostDTO(jsonPost))
                    })
                }

                resolve(posts)
            })
        }).catch(error => {
            console.log(error)
        })

    }

    getPostsByCategoryLink(categoryLink) {
        return new Promise(resolve => {
            cmsService.get(`/Category?CategoryLink=${categoryLink}&fields=Posts`).then(response => {
                const posts = new Array();

                if(response.data.items[0]){
                    response.data.items[0].Posts.forEach(jsonPost => {
                        posts.push(new PostDTO(jsonPost))
                    })
                }

                resolve(posts)
            })
        }).catch(error => {
            console.log(error)
        })

    }

    getPostsByCategoryPaginated(category, page, postsAmount) {
        return new Promise(resolve => {
            cmsService.get(`/Category?CategoryName=${decodeURI(category)}&fields=PostsPaginated&page=${page}&postsAmount=${postsAmount}`).then(response => {
                const posts = new Array();

                if(response.data.items[0]){
                    response.data.items[0].PostsPaginated.forEach(jsonPost => {
                        posts.push(new PostDTO(jsonPost))
                    })
                }

                resolve(posts)
            })
        }).catch(error => {
            console.log(error)
        })

    }

    getBanners() {

        return new Promise(resolve => {
            cmsService.get(`BlogPage?limit=1&fields=BannersLimited`).then(response => {
                const banners = new Array();

                response.data.items[0].BannersLimited.forEach(jsonBanner => {
                    banners.push(new BannerDTO(jsonBanner))
                })

                resolve(banners)
            })
        }).catch(error => {
            console.log(error)
        })

    }

}

export default BlogDataAccess