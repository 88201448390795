import React from "react";

function MainTextContent(props){
    return (
        <div id="home-text">
            <div className="container" dangerouslySetInnerHTML={{ __html: props.content}}/>
        </div>
    );
}

export default MainTextContent;