import React, {Fragment} from "react";


import MenuCheckbox from "../ProductMenu/MenuCheckbox";
import SimpleMultiSelect from "./SimpleMultiSelect";
import {MenuItem} from "@blueprintjs/core";

function GroupedMultiSelect(props) {

    let currentGroup = null;

    const itemRenderer = (item, {modifiers, handleClick, index}) => {
        if (!modifiers.matchesPredicate) {
            return null;
        }
        let isSelected = props.selectedItems.findIndex(selectedItem =>
            selectedItem.name === item.name && selectedItem.groupName === item.groupName) > -1;

        let existsGroup = currentGroup === item.groupName;

        let selectAllCheckbox = null;
        if (index === 0 ) {
            let isAllSelected = props.selectedItems.length === props.items.length;

            selectAllCheckbox = (
                <MenuCheckbox
                    key="all"
                    name="all"
                    checked={isAllSelected}
                    selected={props.allItemsSelected}>
                    Zaznacz wszystkie
                </MenuCheckbox>
            );
        }

        let groupLabel = null;
        if(!existsGroup){
            groupLabel = (<MenuItem disabled={true} text={item.groupName}/>);
            currentGroup = item.groupName;
        }

        return (
            <Fragment key={item.name}>
                {selectAllCheckbox}
                {groupLabel}

                <MenuCheckbox
                    name={item.name}
                    checked={isSelected}
                    selected={event => props.itemChanged(item, event)}>
                    {item.name}
                </MenuCheckbox>
            </Fragment>
        );
    };


    return (
        <SimpleMultiSelect
            itemRenderer={itemRenderer}
            itemSelected={props.itemSelected}
            itemDeselected={props.itemDeselected}
            allItemsSelected={props.allItemsSelected}
            items={props.items}
            selectedItems={props.selectedItems}
            placeholder={props.placeholder}
        />
    );
}

export default GroupedMultiSelect;