import React from "react";

function ConfiguratorHeader({currentNumber, onStepChange, stepTitles, allowClickOnStep = true, children}){

    const onStepClickHandler = stepNumber => {
        if(allowClickOnStep){
            onStepChange(stepNumber);
        }
    }

    let steps = stepTitles.map((title, index) => {
        let stepNumber = index + 1;
        let notActive = "";
        if(currentNumber !== stepNumber){
            notActive = "not-active";
        }

        if(allowClickOnStep === false){
            notActive += " no-click";
        }

        return (<span key={index} className={notActive} onClick={() => onStepClickHandler(stepNumber)}>{stepNumber}. {title}</span>);
    });

    const style = {
        textAlign:'center',
    }

    return (
        <div className="config-modal-header center">
            {children}
            <h4 style={style}>Krok <span>{currentNumber}</span> z 4</h4>
            <div className="kroki flex">
                {steps}
            </div>
        </div>
    );
}

export default ConfiguratorHeader;