import React from "react";

const CalculationTypeLabel = ({calculationType, viewType}) => {

    const calculationTypeNames = {
        "LEASING" : "",
        "CREDIT": "KREDYT",
        "RENTAL" : "WYNAJEM"
    }

    const calculationTypeClasses = {
        "LEASING" : "",
        "CREDIT": "ribbon-credit-",
        "RENTAL" : "ribbon-rental-"
    }

    let label = {
        name: calculationTypeNames[calculationType],
        className: calculationTypeClasses[calculationType]
    }


    if(calculationType === "LEASING"){
        return null;
    }

    return (
        <div className={label.className + viewType}>
            <p>{label.name}</p>
        </div>
    );
}

export default CalculationTypeLabel;