import React, {useEffect, useState, useRef} from "react";
import URLSearchParamsConverter from "../../utils/URLSearchParamsConverter";
import {useHistory, withRouter} from "react-router";

function NewUsedButtons(props) {
    const nonSelectedClassName = "new-used-button";
    const selectedClassName = "new-used-button selected";

    const [allButtonClassName, setAllButtonClassName] = useState(selectedClassName)
    const [newButtonClassName, setNewButtonClassName] = useState(nonSelectedClassName)
    const [usedButtonClassName, setUsedButtonClassName] = useState(nonSelectedClassName)

    const [newUsed, setNewUsed] = useState(props.searchParameters.newUsed)

    const history = useHistory();

    const didMount = useRef(false);

    useEffect(() => {
        setNewUsed(props.searchParameters.newUsed)
    }, [props.searchParameters.newUsed])

    useEffect(() => {
       searchVehicles()
    }, [newUsed])

    const onAllClick = () => {
        setAllButtonClassName(selectedClassName)
        setNewButtonClassName(nonSelectedClassName)
        setUsedButtonClassName(nonSelectedClassName)

        props.setSearchParameters({...props.searchParameters, newUsed: "all"});
    }

    const onNewClick = () => {
        setAllButtonClassName(nonSelectedClassName)
        setNewButtonClassName(selectedClassName)
        setUsedButtonClassName(nonSelectedClassName)

        props.setSearchParameters({...props.searchParameters, newUsed: "new"});
    }

    const onUsedClick = () => {
        setAllButtonClassName(nonSelectedClassName)
        setNewButtonClassName(nonSelectedClassName)
        setUsedButtonClassName(selectedClassName)

        props.setSearchParameters({...props.searchParameters, newUsed: "used"});
    }

    const searchVehicles = () => {
        if(didMount.current){
            history.push({
                pathname: "/lista-ofert",
                search: URLSearchParamsConverter.createURLSearchString(props.searchParameters, props.location.search)
            });
        }else{
            didMount.current = true
        }
    }

    return (
        <div className="new-used-buttons">
            <div className={allButtonClassName} onClick={onAllClick}>Wszystkie</div>
            <div className={newButtonClassName} onClick={onNewClick}>Nowe</div>
            <div className={usedButtonClassName} onClick={onUsedClick}>Używane</div>
        </div>
    )
}

export default withRouter(NewUsedButtons);