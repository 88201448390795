import React from 'react';

import proces from "../../assets/images/content/proces.webp";
import ProcessSteps from "../../components/ProcessSteps/ProcessSteps";

function Process(){
    
    return(
        <div id="proces">
            <ProcessSteps/>
        </div>
    );
}

export default Process;