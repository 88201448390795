
class Company{

    #DZIALALNOSC_JEDNOOSOBOWA = "DZIALALNOSC_JEDNOOSOBOWA";
    #SPOLKA_CYWILNA = "SPOLKA_CYWILNA";

    #LEGAL_FORM_CODES = {
        "DZIALALNOSC_JEDNOOSOBOWA": "099",
        "SPOLKA_CYWILNA": "019",
        "SPOLKA_JAWNA": "118",
        "SPOLKA_Z_OGRANICZONA_ODPOWIEDZIALNOSCIA": "117",
        "SPOLKA_AKCYJNA": "116",
        "SPOLKA_KOMANDYTOWA": "120",
        "SPOLKA_KOMANDYTOWO_AKCYJNA": "121",
        "SPOLKA_PARTNERSKA": "115",
        "STOWARZYSZENIE": "155",
        "FUNDACJA": "049",
        "PRZEDSIEBIORSTWO_PANSTWOWE": "124",

    };

    constructor(company) {
        this.legalForm = company.legalForm;
    }

    isCompanyIndividual(){
        return [this.#DZIALALNOSC_JEDNOOSOBOWA, this.#SPOLKA_CYWILNA].includes(this.legalForm);
    }

    isSpolkaCywilna(){
        return this.#SPOLKA_CYWILNA === this.legalForm;
    }

    isDzialalnoscJednoosobowa(){
        return this.#DZIALALNOSC_JEDNOOSOBOWA === this.legalForm;
    }

    getLegalFormCode(){
        return this.#LEGAL_FORM_CODES[this.legalForm];
    }
}

export default Company;