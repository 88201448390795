import React, {Fragment, useEffect, useState} from "react";
import {Drawer, Position} from "@blueprintjs/core";
import {cmsService} from "../../utils/axios";

function LabelWithInfo(props) {

    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [info, setInfo] = useState({
        Title: props.title,
        Content: props.content
    });

    useEffect(() => {

        cmsService("/InformationBox?UnifiedName=" + props.infoCode + "&fields=Title,Content").then(response => {
            let information = response.data.items[0];

            if (information) {
                setInfo(information);
            }
        });

    }, [props.infoCode]);

    let style = null;
    if (props.strongTitle) {
        style = {
            fontWeight: "bold"
        };
    }

    return (
        <Fragment>
            <span className="openPrompt" onClick={() => setIsDrawerOpen(true)}>
                <i className="fa fa-question-circle"></i>
            </span>
            <span style={style}>
                {info.Title}
            </span>

            <Drawer
                isOpen={isDrawerOpen}
                title={info.Title}
                onClose={() => setIsDrawerOpen(false)}
                size={Drawer.SIZE_SMALL}
                position={Position.RIGHT}
                canOutsideClickClose={true}
            >
                <div className="info-box-content" dangerouslySetInnerHTML={{__html: info.Content}}>

                </div>


            </Drawer>

        </Fragment>


    );
}

export default LabelWithInfo;