import React, {Fragment, useContext} from "react";
import {Card, Elevation} from "@blueprintjs/core";
import {SellerContext} from "../../context/seller-context";

function DecisionInfo({decision}) {

    const seller = useContext(SellerContext);

    let content = null;

    switch (decision.type) {
        case "POSITIVE":
            content = (
                <Fragment>
                    <h3 className="center">Gratulacje!</h3>

                    <Card elevation={Elevation.ONE} className="decision-section positive-decision">
                        <p className="decision-info">Twój wniosek nr #{decision.proposalId} został
                            wstępnie zaakceptowany! </p>
                        <p>W ciągu 8 godz. roboczych potwierdzimy decyzję, prześlemy i omówimy projekt umowy oraz
                            ustalimy
                            wygodne dla Ciebie miejsce i termin jej podpisania.</p>
                        <p>W każdej chwili możesz do nas zadzwonić lub napisać: {seller.phone} / <a
                            href={"mailto:" + seller.email}>{seller.email}</a> <br/>
                            Jesteśmy do Twojej dyspozycji w dni robocze w godz. 8 – 17.</p>
                    </Card>
                </Fragment>
            );
            break;
        case "TO_VERIFICATION":
            content = (<Card elevation={Elevation.ONE} className="decision-section neutral-decision">
                <p className="decision-info">Twój wniosek nr #{decision.proposalId} wymaga
                    dodatkowych konsultacji z naszym ekspertem.</p>
                <p>Skontaktujemy się z Tobą niezwłocznie, nie później niż w ciągu 2 godz. w dni robocze.</p>
                <p>W każdej chwili możesz do nas zadzwonić lub napisać: {seller.phone} / <a
                    href={"mailto:" + seller.email}>{seller.email}</a> <br/>
                    Jesteśmy do Twojej dyspozycji w dni robocze w godz. 8 – 17.</p>

            </Card>);
            break;
        default:
            content = null;
    }

    return content;
}

export default DecisionInfo;