
class URLSearchParamsConverter{

    static createURLSearchString(searchParameters, existingQueryString = "") {
        let urlSearchParams = new URLSearchParams(existingQueryString);

        this.addCustomerTypeToURL(urlSearchParams);

        for (const searchParamKey in searchParameters) {
            let searchParameter = searchParameters[searchParamKey];

            if(Array.isArray(searchParameter) && searchParameter.length > 0) {
                if(searchParameter[0].name){
                    searchParameter = searchParameter.map(item => item.name);
                }

                urlSearchParams.set(searchParamKey, searchParameter.join(","));
            } else if (!Array.isArray(searchParameter) && searchParameter){
                if(searchParameter.name){
                    searchParameter = searchParameter.name;
                }

                urlSearchParams.set(searchParamKey, searchParameter);
            }
        }
        
        return urlSearchParams.toString()
    }

    static addCustomerTypeToURL(urlSearchParams) {
        let customerType = this.getCustomerTypeFromURL();

        if(customerType){
            urlSearchParams.set("customerType", customerType);
        }
    }

    static getCustomerTypeFromURL() {
        let urlSearchParams = new URLSearchParams(window.location.search);

        return urlSearchParams.get("customerType");
    }

    static getSearchParamsFromURLSearchString(queryString, searchOptions){
        let urlSearchParams = new URLSearchParams(queryString);

        return {
            brands: this.searchBrands(urlSearchParams.get("brands"), searchOptions.brandsAndModels),
            models: this.searchModels(urlSearchParams.get("models"), searchOptions.brandsAndModels),
            bodyTypes: this.searchValues(urlSearchParams.get("bodyTypes"), searchOptions.bodyTypes),
            fuelTypes: this.searchValues(urlSearchParams.get("fuelTypes"), searchOptions.fuelTypes),
            segments: this.searchValues(urlSearchParams.get("segments"), searchOptions.segments),
            installmentNetFrom: urlSearchParams.get("installmentNetFrom") ? urlSearchParams.get("installmentNetFrom") : "",
            installmentNetTo: urlSearchParams.get("installmentNetTo") ? urlSearchParams.get("installmentNetTo") : "",
            installmentGrossFrom: urlSearchParams.get("installmentGrossFrom") ? urlSearchParams.get("installmentGrossFrom") : "",
            installmentGrossTo: urlSearchParams.get("installmentGrossTo") ? urlSearchParams.get("installmentGrossTo") : "",
            minimumSellPriceNetFrom: urlSearchParams.get("minimumSellPriceNetFrom") ? urlSearchParams.get("minimumSellPriceNetFrom") : "",
            minimumSellPriceNetTo: urlSearchParams.get("minimumSellPriceNetTo") ? urlSearchParams.get("minimumSellPriceNetTo") : "",
            minimumSellPriceGrossFrom: urlSearchParams.get("minimumSellPriceGrossFrom") ? urlSearchParams.get("minimumSellPriceGrossFrom") : "",
            minimumSellPriceGrossTo: urlSearchParams.get("minimumSellPriceGrossTo") ? urlSearchParams.get("minimumSellPriceGrossTo") : "",
            enginePowerHpFrom: urlSearchParams.get("enginePowerHpFrom") ? urlSearchParams.get("enginePowerHpFrom") : "",
            enginePowerHpTo: urlSearchParams.get("enginePowerHpTo") ? urlSearchParams.get("enginePowerHpTo") : "",
            mileageFrom: urlSearchParams.get("mileageFrom") ? urlSearchParams.get("mileageFrom") : "",
            mileageTo: urlSearchParams.get("mileageTo") ? urlSearchParams.get("mileageTo") : "",
            manufactureYearFrom: urlSearchParams.get("manufactureYearFrom") ? urlSearchParams.get("manufactureYearFrom") : "",
            manufactureYearTo: urlSearchParams.get("manufactureYearTo") ? urlSearchParams.get("manufactureYearTo") : "",
            gearboxTypes: this.searchValues(urlSearchParams.get("gearboxTypes"), searchOptions.gearboxTypes),
            driveTypes: this.searchValues(urlSearchParams.get("driveTypes"), searchOptions.driveTypes),
            colors: this.searchValues(urlSearchParams.get("colors"), searchOptions.colors),
            equipment: this.searchValues(urlSearchParams.get("equipment"), searchOptions.equipment),
            numberOfDoors: this.searchValues(urlSearchParams.get("numberOfDoors"), searchOptions.numbersOfDoors),
            numberOfSeats: this.searchValues(urlSearchParams.get("numberOfSeats"), searchOptions.numbersOfSeats),
            prices: this.searchValues(urlSearchParams.get("prices"), searchOptions.prices),
            availability: urlSearchParams.get("availability"),
            specialOffer: (urlSearchParams.get("specialOffer") === 'true'),
            isPremium: (urlSearchParams.get("isPremium") === 'true'),
            filterClicked: (urlSearchParams.get("filterClicked") === 'true'),
            vehicleTypes: urlSearchParams.get("vehicleTypes"),
            newUsed: urlSearchParams.get("newUsed"),
            calculationTypes: this.searchValues(urlSearchParams.get("calculationTypes"), searchOptions.calculationTypes)
        };
    }

    static getSearchParamsFromURLSearchStringForAdvancedSearch(queryString){
        let urlSearchParams = new URLSearchParams(queryString);

        return urlSearchParams.get("filterClicked");
    }

    static getDontScrollParamFromURLSearchString(queryString){
        let urlSearchParams = new URLSearchParams(queryString);

        return urlSearchParams.get("ds");
    }

    static searchBrands(brandsFromURL, brandsAndModels) {
        if(brandsFromURL === null){
            return [];
        }

        brandsFromURL = brandsFromURL.split(",");

        return brandsAndModels.filter(brand => brandsFromURL.includes(brand.name));
    }

    static searchModels(modelsFromURL, brandsAndModels) {
        if(modelsFromURL === null){
            return [];
        }

        modelsFromURL = modelsFromURL.split(",");
        let models = [];

        for(let brand of brandsAndModels){
            let newModels = brand.models.filter(model => modelsFromURL.includes(model)).map(model => ({name: model, groupName: brand.name}));
            models = [...models,...newModels];
        }

        return models;
    }

    static searchValues(valuesFromURL, availableOptions) {
        if(valuesFromURL === null){
            return [];
        }

        valuesFromURL = valuesFromURL.split(",");

        return availableOptions.filter(item => valuesFromURL.includes(item)).map(item => ({name : item}));
    }

    static shouldScroll(searchParamsQuery){
        return this.getDontScrollParamFromURLSearchString(searchParamsQuery) !== '1';
    }
}

export default URLSearchParamsConverter;