import React, {Fragment, useContext} from "react";
import FormatUtils from "../../../utils/FormatUtils";
import {CustomerTypeContext, isCustomerCompany} from "../../../context/customer-type-context";

function InstallmentSubmitBox(props) {

    const customerContext = useContext(CustomerTypeContext);

    let totalInstallment = FormatUtils.formatMoney(isCustomerCompany(customerContext) ?
        props.installment.net : props.installment.gross);

    return (
        <Fragment>
            <div id="fixSubmit"></div>

            <div id="form-submit">
                <div className="form-fixed-box">
                    <div className="flex">
                        <div className="fixed-box-left flex">
                                    <span>RATA RAZEM<br/>
                                        {props.isCreditCalculation ? "brutto" : isCustomerCompany(customerContext) ? "netto" : "brutto" } / mc </span>
                            <button type="button"
                                onClick={props.onSendMessageClick}
                                className="btn offer-btn flex"
                            >
                                <i className="fa fa-comments"></i> Zapytaj
                            </button>
                        </div>
                        <div className="fixed-box-right flex flex-wrap">
                                    <span className="full-price">
                                        <span>{totalInstallment}</span>
                                    </span>
                            <button type="button" id="send-form" className="offer-btn" onClick={props.onReservationClick}>
                                Dalej
                            </button>
                        </div>
                    </div>
                </div>
            </div>


        </Fragment>
    );
}

export default InstallmentSubmitBox;