import React from "react";

function OfferDescription({description}) {

    return description &&
            <div className="single-info single-box">
                <h3>Opis</h3>
                <div className="description" dangerouslySetInnerHTML={{__html: description}} />
            </div>
        ;
}

export default OfferDescription;