import React, {useContext} from "react";
import {CustomerTypeContext, isCustomerCompany} from "../../../context/customer-type-context";
import FeeSlider from "./FeeSlider";

function BuyoutValueSlider(props) {

    const customerContext = useContext(CustomerTypeContext);

    let index = props.buyoutValues.findIndex(value => value.contractLengthMonths === props.contractLength);

    return (
        <FeeSlider
            {...props}
            min={isCustomerCompany(customerContext) ?
                props.buyoutValues[index].minimumBuyoutValueNet : props.buyoutValues[index].minimumBuyoutValueGross}
            max={isCustomerCompany(customerContext) ?
                props.buyoutValues[index].maximumBuyoutValueNet : props.buyoutValues[index].maximumBuyoutValueGross}
        />
    );
}

export default BuyoutValueSlider;