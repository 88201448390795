import React from "react";

import step1Icon from '../../assets/images/icons/process_steps/icon1.svg';
import step2Icon from '../../assets/images/icons/process_steps/icon2.svg';
import step3Icon from '../../assets/images/icons/process_steps/icon3.svg';
import step4Icon from '../../assets/images/icons/process_steps/icon4.svg';
import step5Icon from '../../assets/images/icons/process_steps/icon5.svg';

function ProcessSteps() {

    const steps = [
        {
            content: (<span><strong>Wybierz</strong><br/>samochód</span>),
            icon: step1Icon
        },
        {
            content: (<span><strong>Zarezerwuj</strong><br/>i wypełnij wniosek</span>),
            icon: step2Icon
        },
        {
            content: (<span><strong>Poznaj decyzję</strong><br/>leasingową</span>),
            icon: step3Icon
        },
        {
            content: (<span><strong>Podpisz<br/>umowę</strong></span>),
            icon: step4Icon
        },
        {
            content: (<span><strong>Odbierz</strong><br/>samochód</span>),
            icon: step5Icon
        }
    ];

    const stepsElements = steps.map((step, index) => (
        <div className="process-step" key={index}>
            <div className="step-element">
                <img src={step.icon}/>
                <div className="process-name">
                    <span className="gold"><strong>{index + 1}. </strong></span>
                    {step.content}
                </div>
            </div>
        </div>
    ));

    return (
        <div className="container flex flex-wrap">
            {stepsElements}
        </div>
    );
}


export default ProcessSteps;