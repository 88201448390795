import React, {useEffect, useState} from "react";

function ContractLengthChooser(props){

    const transformContractLengths = () => {
        return props.items.map(length => ({
                value: length,
                active: props.defaultLength === length
            })
        );
    }

    const [contractLengths, setContractLengths] = useState(transformContractLengths());


    const updateContractLengths = (newLength) => {
        let updatedContractLengths = [...contractLengths].map(length => ({
            value: length.value,
            active: newLength === length.value
        }));


        setContractLengths(updatedContractLengths);
    }

    useEffect(() => {
        setContractLengths(transformContractLengths());

    }, [props.items]);


    const lengthButtonClickHandler = (chosenLength) => {
        updateContractLengths(chosenLength.value);
        props.onChange(chosenLength.value);
    };

    let lengthButtons = contractLengths.map(contractLength => (
        <button key={contractLength.value}
                type="button"
                onClick={() => lengthButtonClickHandler(contractLength)}
                className={(contractLength.active ? "active" : "") + " form-button"}>
            {contractLength.value}
        </button>
    ));

    return (
        <div id="formOkres" className="form-item form-okres">
            <p><strong>{props.label}</strong></p>
            <div className="form-options flex flex-wrap">
                {lengthButtons}
            </div>

        </div>
    );
}

export default ContractLengthChooser;