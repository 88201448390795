import React, {Fragment, useContext} from "react";
import {CustomerTypeContext, isCustomerCompany} from "../../../context/customer-type-context";
import FormatUtils from "../../../utils/FormatUtils";
import infoSign from "../../../assets/images/icons/info-sign.svg";
import LabelWithInfo from "../../../components/LabelWithInfo/LabelWithInfo";

function CreditCalculationSummary(props) {

    const customerContext = useContext(CustomerTypeContext);

    const isCompany = isCustomerCompany(customerContext);

    return (
        <Fragment>
            <h4 id="podsumowanie">Podsumowanie</h4>
            <div className="form-summary">
                <div className="line flex">
                    <div className="line-left">
                        Okres kredytu:
                    </div>
                    <div className="line-middle">
                                        <span id="zmienOkres" className="changeForm"
                                              onClick={props.changeContractLength}>
                                            Zmień <i className="fa fa-angle-right"></i>
                                        </span>
                    </div>
                    <div className="line-right">
                        {props.calculationParameters.contractLength} m-cy
                    </div>

                </div>

                <div className="line flex">
                    <div className="line-left">
                        Opłata wstępna:
                    </div>
                    <div className="line-middle">
                        <span id="zmienOplate" className="changeForm" onClick={props.changeInitialFee}>
                            Zmień <i className="fa fa-angle-right"></i>
                        </span>
                    </div>
                    <div className="line-right">
                        {isCompany ?
                            props.initialFeeValue : props.initialFeeValue} zł
                    </div>
                </div>

                {isCompany ? '' :
                    <div className="line flex">
                        <div className="line-left">
                            <LabelWithInfo infoCode="rrso"/>
                        </div>
                        <div className="line-right">
                            {isCompany ?
                                7.53 : 7.53} %
                        </div>
                    </div>
                }

            </div>

            <h4 id="rata">Miesięczna rata</h4>
            <div className="form-summary">
                <div className="line flex">
                    <div className="line-left">
                        Rata kredytu:
                    </div>

                    <div className="line-right">
                        {isCompany ?
                            FormatUtils.formatMoney(props.calculationResult.installment) : FormatUtils.formatMoney(props.calculationResult.installment)} zł
                    </div>

                </div>

            </div>
        </Fragment>
    );
}

export default CreditCalculationSummary;