import React, {useState} from 'react';
import {Dialog, Icon, Tab, Tabs} from "@blueprintjs/core";
import CallForm from "../CallForm/CallForm";
import ContactForm from "../ContactForm/ContactForm";
import { useMediaQuery } from 'react-responsive'

function ContactDialog(props){
    const [tabSelected, setTabSelected] = useState("send");
    const isMobile = useMediaQuery({ query: '(max-width: 479px)' }) ? true : false;
    const tableTitleMargin = isMobile ? "0px" : "10px";
    const closeButtonSize = isMobile ? 16 : 20;
    return (
            <Dialog
                className="dialog"
                id="sell-call-dialog"
                isOpen={props.isOpen}
                onClose={props.onClose}
                title={""}>
                <Icon
                    icon="cross" iconSize={closeButtonSize}
                    className="contact-dialog-close-button"
                    onClick={props.onClose}/>
                <Tabs
                    id="contact-tabs"
                    large={true}
                    selectedTabId={tabSelected}
                    className="contact-tabs">

                    <Tab
                        id="call"
                        title={
                            <div style={{display:"inline-flex"}}>
                                <Icon
                                    icon="phone" iconSize={20}
                                    style={{margin: "0 10px",marginTop: tableTitleMargin}}/>
                                <a>Zadzwoń</a>
                            </div>
                        }

                        onClickCapture={() => setTabSelected("send")}
                        panel={<ContactForm
                            onSuccess={props.onClose}
                            additionalMessage={props.additionalMessage}
                        />}>

                    </Tab>
                    <Tab
                        id="send"
                        title={<div style={{display:"inline-flex"}}>
                                <Icon
                                    icon="envelope" iconSize={20}
                                    style={{margin: "0 10px",marginTop: tableTitleMargin}}/>
                                <a>Wyślij wiadomość</a>
                            </div>
                        }

                        onClickCapture={() => setTabSelected("call")}
                        panel={<CallForm
                            onSuccess={props.onClose} />}>
                    </Tab>

                    <Tabs.Expander />
                </Tabs>
            </Dialog>
    )
}

export default ContactDialog