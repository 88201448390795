import {MenuItem} from "@blueprintjs/core";
import React from "react";


const highlightText = (text, query) => {
    let lastIndex = 0;

    const words = query
        .split(/\s+/)
        .filter(word => word.length > 0)
        .map(escapeRegExpChars);

    if (words.length === 0) {
        return [text];
    }

    const regexp = new RegExp(words.join("|"), "gi");
    const tokens = [];
    while (true) {
        const match = regexp.exec(text);
        if (!match) {
            break;
        }
        const length = match[0].length;
        const before = text.slice(lastIndex, regexp.lastIndex - length);
        if (before.length > 0) {
            tokens.push(before);
        }
        lastIndex = regexp.lastIndex;
        tokens.push(<strong key={lastIndex}>{match[0]}</strong>);
    }
    const rest = text.slice(lastIndex);
    if (rest.length > 0) {
        tokens.push(rest);
    }
    return tokens;
}

const escapeRegExpChars = (text) => {
    return text.replace(/([.*+?^=!:${}()|\\[\]\\/\\])/g, "\\$1");
}

const renderCarHandler = (car, {handleClick, modifiers, query}) => {
    if (!modifiers.matchesPredicate) {
        return null;
    }

    return (
        <MenuItem
            key={car.fullName}
            active={modifiers.active}
            disabled={modifiers.disabled}
            onClick={handleClick}
            text={highlightText(car.fullName, query)}
        />
    );
}

const filterCarHandler = (query, car, _index, exactMatch) => {
    const normalizedTitle = car.fullName.toLowerCase();
    const normalizedQuery = query.toLowerCase();

    if (exactMatch) {
        return normalizedTitle === normalizedQuery;
    } else {
        return `${normalizedTitle}`.indexOf(normalizedQuery) >= 0;
    }
};

const renderInputValueHandler = (car) => {
    return car.fullName;
}

export const carSearchProps = {
    inputValueRenderer: renderInputValueHandler,
    itemPredicate: filterCarHandler,
    itemRenderer: renderCarHandler,
    fill: true,
    noResultsText: "Nie znaleziono samochodu.",
    popoverProps: {minimal: true, fill: true},
    openOnKeyDown: true,
};

export const searchInputProps = {
    placeholder: "Szukaj marki lub modelu",
    leftIcon: null
}