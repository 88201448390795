import React from 'react';
import {Icon} from "@blueprintjs/core";

function MobileCallButton(props) {

    const favouriteVehicleCounterHandler = () => {
        if(props.favouritesAmount < 10) {
           return (
               <div className="mobile-favourite-vehicle-counter">
                   <div className="mobile-favourite-vehicle-counter-amount-less-than-10">
                       {props.favouritesAmount}
                   </div>
               </div>
           );
        } else if(props.favouritesAmount >= 10) {
            return (
            <div className="mobile-favourite-vehicle-counter">
                <div className="mobile-favourite-vehicle-counter-amount-more-than-10">
                    {props.favouritesAmount}
                </div>
            </div>);
        }
    }

    const favouriteVehicleButtonHandler = () => {
        if(props.amount === 0) {
        } else {
            return (
                    <button
                        className="mobile-favourite-button"
                        onClick={props.clicked}
                        style={{
                            borderRadius:"45px",
                            height:"50px",
                            width:"50px",
                            boxShadow: "0px 8px 15px rgba(0, 0, 0, 0.4)"
                        }}>
                        <Icon icon="star" iconSize={28} style={{marginTop:"0.55rem", marginLeft:"-12px"}}/>
                        {favouriteVehicleCounterHandler()}
                    </button>
            );
        }
    }

    return(
        <div className="mobile-favourite-call-wrapper" id="mobile-favourite-button">
            {favouriteVehicleButtonHandler()}
        </div>
    )
}

export default MobileCallButton;