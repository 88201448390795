import React from 'react'
import logo from "../../assets/images/icons/logo.png";

const PrintLogo = () => {
    return (
        <div id="print-logo">
            <img src={logo} alt="GO-Leasing logo" width="214" height="75"/>
        </div>
    )
}

export default PrintLogo;