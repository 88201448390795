export var initialAllowedCalculationParametersForCredit = {
    "defaultContractLengthMonth": 60,
    "contractLengthMonths": [
        12,
        24,
        36,
        48,
        60,
        72,
        84,
        96,
        108,
        120
    ],
    "initialFeeValues": {
        "minimumInitialFeeNet": 0,
        "maximumInitialFeeNet": 100000,
        "minimumInitialFeeGross": 0,
        "maximumInitialFeeGross": 100000
    }
};
