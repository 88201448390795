import React, {Fragment, useEffect, useState} from "react";
import Company from "../../../Reservation/AdditionalData/Company/Company";
import {leasingOnlineService} from "../../../../utils/axios";
import RepresentativesToChooseDialog from "../../../Reservation/AdditionalData/Company/RepresentativesToChooseDialog";
import {FormGroup, Position, Tooltip} from "@blueprintjs/core";
import CurrencyInput from "../../../../components/CurrencyInput/CurrencyInput";
import InputErrorMessage from "../../../Reservation/InputErrorMessage";
import PersonFormFieldsForCredit from "../../PersonFormFieldsForCredit";

function RepresentativeListForCredit(props) {

    let emptyRepresentative = {
        "pesel": "",
        "email": "",
        "phoneNumber": "",
        "location": "",
        "firstName": "",
        "lastName": "",
        "identityCardType": "ID_CARD",
        "identityCardNumber": "",
        "identityCardExpirationDate": null,
        "identityCardAuthority": "",
        "maritalStatus": null,
        "propertySeparation": null,
        "address": "",
        "postalCode": "",
        "spouse": null,
        "nationality": "Polska",
        "birthDate": "",
    };

    let {formValidator} = props;

    const company = new Company(props.company);

    const minRepresentativeCount = company.isSpolkaCywilna() ? 2 : 1;

    const [representatives, setRepresentatives] = useState(props.representatives);
    const [representativesToChoose, setRepresentativesToChoose] = useState([]);
    const [isOpenRepresentativesDialog, setIsOpenRepresentativesDialog] = useState();


    const {onChange} = props;

    useEffect(() => {
        let representativesToChange = representatives.map(representative => ({...representative, companyNip: props.company.nip}))

        onChange(representativesToChange);
    }, [representatives]);

    function isRepresentativesNotSet(){
        return props.company.nip !== props.representatives[0].companyNip;
    }

    const chooseRepresentativesHandler = (selectedRepresenatives) => {
        let newRepresentatives = selectedRepresenatives.map(representative => ({
            ...emptyRepresentative,
            firstName: representative.firstName,
            lastName: representative.lastName
        }));

        if(newRepresentatives.length > 0){
            newRepresentatives[0].phoneNumber = props.representatives[0].phoneNumber;
            newRepresentatives[0].email = props.representatives[0].email;
        }

        setRepresentatives(newRepresentatives);
    };

    const currencyChangeHandler = (name, value) =>
        props.onAverageSalaryChange({
            ...props.proposal,
            [name]: value
        });

    useEffect(() => {
        if(isRepresentativesNotSet()) {
            let newRepresentatives = [];

            for (let i = 0; i < minRepresentativeCount; i++) {

                if(i === 0){
                    newRepresentatives.push(props.representatives[0]);
                } else {
                    newRepresentatives.push(emptyRepresentative);
                }
            }
            setRepresentatives(newRepresentatives);
        }
    }, [minRepresentativeCount]);



    useEffect(() => {
        if (props.company.nip && props.company.legalForm && isRepresentativesNotSet()) {
            leasingOnlineService("getRepresentatives?nip=" + props.company.nip + "&legalForm=" + company.getLegalFormCode())
                .then(response => {
                    let representativeData = response.data;

                    if (representativeData.length === 1) {
                        setRepresentatives([{
                            ...props.representatives[0],
                            firstName: representativeData[0].firstName,
                            lastName: representativeData[0].lastName
                        }]);
                    } else if (representativeData.length > 1) {
                        setRepresentativesToChoose(representativeData);
                        setIsOpenRepresentativesDialog(true);
                    }
                });
        }

        setRepresentativesToChoose([]);
    }, []);

    const personAddHandler = () => setRepresentatives(prevState => [...prevState, emptyRepresentative]);

    const personChangeHandler = (person, index) => {
        let updatedRepresentatives = [...representatives];
        updatedRepresentatives[index] = person;

        setRepresentatives(updatedRepresentatives);
    };

    const personRemoveHandler = (index) => {
        let updatedRepresentatives = [...representatives];
        updatedRepresentatives.splice(index, 1);

        setRepresentatives(updatedRepresentatives);
        if(representativesToChoose.length > 0) {
            setRepresentativesToChoose(updatedRepresentatives);
        }
    };

    let representativeItems = representatives.map((representative, index) => {
        return (
            <PersonFormFieldsForCredit
                key={index}
                title={index + 1 + ". reprezentant"}
                person={representative}
                showMaritalStatus={company.isCompanyIndividual()}
                canRemove={index + 1 > minRepresentativeCount}
                index={index}
                onChange={personChangeHandler}
                onRemove={personRemoveHandler}
                formValidator={props.formValidator}
            />
        );
    });

    let averageSalary = () => {
        return (
            <Fragment>

                <h6 className="header-with-line">Dane finansowe</h6>
                <FormGroup
                    className="form-group"
                    label="Dochód miesięczny netto"
                    labelFor="averageSalary"
                >
                    <Tooltip
                        content="Wpisz przeciętny dochód miesięczny netto. Podaj kwotę z zaokrągleniem do pełnych złotych."
                        position={Position.TOP}>

                        <CurrencyInput
                            value={props.proposal.averageSalary}
                            onChange={currencyChangeHandler}
                            inputRef={formValidator.register({required: true})}
                            name="averageSalary"/>
                    </Tooltip>

                    <InputErrorMessage error={formValidator.errors["averageSalary"]}/>
                </FormGroup>

                <FormGroup
                    className="form-group"
                    label="Miesięczne zobowiązania kredytowe"
                    labelFor="creditObligationSum"
                >
                    <Tooltip
                        content="Wpisz łączną kwotę wszystkich miesięcznych  zobowiązań kredytowych (kredyty, pożyczki). Podaj kwotę z zaokrągleniem do pełnych złotych.
                            Jeśli nie posiadasz zobowiązań wpisz 0."
                        position={Position.TOP}>
                        <CurrencyInput
                            value={props.proposal.creditObligationSum}
                            onChange={currencyChangeHandler}
                            inputRef={formValidator.register({required: true})}
                            name="creditObligationSum"/>
                    </Tooltip>
                    <InputErrorMessage error={formValidator.errors["creditObligationSum"]}/>
                </FormGroup>
            </Fragment>
        );
    }

    let addNewButton = null;
    if (representativesToChoose.length > 1) {
        addNewButton = (<button type="button"
                                className="add-representative-button gold"
                                onClick={() => setIsOpenRepresentativesDialog(true)}>
            Wybierz reprezentantów</button>);
    } else if (!company.isCompanyIndividual()) {
        addNewButton = (<button type="button" className="add-representative-button gold" onClick={personAddHandler}>
            Dodaj kolejnego reprezentanta</button>);
    }


    return (
        <Fragment>
            {averageSalary()}
            {representativeItems}
            {addNewButton}
            <RepresentativesToChooseDialog
                isOpen={isOpenRepresentativesDialog}
                items={representativesToChoose}
                onClose={() => setIsOpenRepresentativesDialog(false)}
                onChoose={chooseRepresentativesHandler}
            />
        </Fragment>
    );
}

export default RepresentativeListForCredit;