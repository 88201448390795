import React, {Fragment, useContext} from "react";
import RangeSelect from "../../components/ProductSearchField/RangeSelect";
import {SearchOptionsContext} from "../../context/search-options-context";

function StepForBudget(props) {

    let searchOptions = useContext(SearchOptionsContext);

    return (
        <Fragment>

            <div className="config-modal-body">

                <h5 className="center">Ile chcesz płacić miesięcznie?</h5>
                <RangeSelect
                    onChange={budget => props.onChange(budget)}
                    items={searchOptions.installments}
                    range={props.budget}
                    unit="zł"
                    placeholder={["Rata od", "Rata do"]}
                />
                <div className="clearfix"></div>

            </div>

            <div className="config-modal-footer">

                <div className="flex">
                    <button type="button" className="back" onClick={props.onBackClick}><i className="fa fa-angle-left"></i>Wstecz</button>
                    <button type="button" onClick={props.onNextClick}>Dalej</button>
                </div>

            </div>
        </Fragment>
    );
}

export default StepForBudget;