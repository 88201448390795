class UtmReader {
    static DEFAULT_UTM_SOURCE = "none";
    static DEFAULT_UTM_MEDIUM = "none";
    static DEFAULT_UTM_CAMPAIGN = "none";
    static UTM_SOURCE_KEY = "UTM_SOURCE";
    static UTM_MEDIUM_KEY = "UTM_MEDIUM";
    static UTM_CAMPAIGN_KEY = "UTM_CAMPAIGN";

    static readUtm(type) {
        let utm, key, defaultValue;

        switch (type) {
            case "SOURCE":
                    utm = this.readFromURLParams("SOURCE");
                    key = UtmReader.UTM_SOURCE_KEY;
                    defaultValue = UtmReader.DEFAULT_UTM_SOURCE
                break;
            case "MEDIUM":
                    utm = this.readFromURLParams("MEDIUM");
                    key = UtmReader.UTM_MEDIUM_KEY;
                    defaultValue = UtmReader.DEFAULT_UTM_MEDIUM
                break;
            case "CAMPAIGN":
                    utm = this.readFromURLParams("CAMPAIGN");
                    key = UtmReader.UTM_CAMPAIGN_KEY;
                    defaultValue = UtmReader.DEFAULT_UTM_CAMPAIGN
                break;
            default:
        }

        if (utm && 0 !== utm.length) {
            sessionStorage.setItem(key, utm);
        } else {
            utm = sessionStorage.getItem(key);

            if (utm === null) {
                utm = defaultValue;
            }
        }

        return utm;
    };

    static readFromURLParams(type) {
        let utmParam;
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);

        switch (type) {
            case "SOURCE":
                    utmParam = urlParams.get('utm_source')
                break;
            case "MEDIUM":
                    utmParam = urlParams.get('utm_medium')
                break;
            case "CAMPAIGN":
                    utmParam = urlParams.get('utm_campaign')
                break;
            default:
        }

        if (utmParam) {
            utmParam = utmParam.replace('/', '');
        }

        if (utmParam) {
            return utmParam;
        }
    }
}

export default UtmReader;