import React, {useState} from "react";
import carImage from "../../assets/images/content/glowna_foto.png";
import NotFoundContactDialog from "../ContactDialog/NotFoundContactDialog";

function NotFoundOfferListItem(props){

    let title = "Nie znalazłeś samochodu dla siebie?";
    let buttonTitle = "Zapytaj o samochód";
    let content = (<b>Sprawdź samochody na zamówienie!</b>)

    const [isOpenContactDialog, setIsOpenContactDialog] = useState(false);

    const contactDialogOpenHandler = () => {
        setIsOpenContactDialog(true);
    }

    const contactDialogCloseHandler = () => {
        setIsOpenContactDialog(false);
    }

    return (
            <div className={"undefined offer"}>
                <div className="offer-top">
                    <span className="offer-title-contact" >{title}</span>
                    <span className="offer-points">{content}</span>
                    <br />
                </div>
                <div className="offer-img">
                    <div >
                        <img src={carImage} alt={title}/>
                    </div>
                </div>
                <div className="offer-list-view flex">
                </div>

                <div className="offer-bottom flex">
                    <button onClick={contactDialogOpenHandler} className="btn offer-contact-btn">{buttonTitle}</button>
                </div>
                <NotFoundContactDialog isOpen={isOpenContactDialog}
                                       onClose={contactDialogCloseHandler} />
            </div>
    );
}

export default NotFoundOfferListItem;