class BannerDTO {
    id;
    bannerName;
    bannerUrl;
    link;

    constructor(jsonBanner) {
        this.id = jsonBanner.ID;
        this.bannerName = jsonBanner.BannerName;
        this.bannerUrl = jsonBanner.BannerURL;
        this.link = jsonBanner.Link;
    }

}

export default BannerDTO;