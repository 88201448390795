class PostDTO {
    id;
    author;
    label;
    title;
    lead;
    content;
    imageSource;
    creationDate;
    lastEditedDate;
    backgroundUrl;
    categoryName;
    categoryLink;
    tagNames;
    tagLinks;
    link;
    metaTitle;
    metaDescription;

    constructor(jsonPost) {
        this.id = jsonPost.ID;
        this.author = jsonPost.Author;
        this.label = jsonPost.Label;
        this.title = jsonPost.Title;
        this.lead = jsonPost.Lead ? jsonPost.Lead : jsonPost.Introduction;
        this.content = jsonPost.Content;
        this.imageSource = jsonPost.ImageSource;
        this.creationDate = Date.parse(jsonPost.Created);
        this.lastEditedDate = Date.parse(jsonPost.LastEdited);
        this.backgroundUrl = jsonPost.BackgroundURL;
        this.categoryName = jsonPost.CategoryName;
        this.categoryLink = jsonPost.CategoryLink;
        this.tagNames = jsonPost.TagNames.split(',');
        this.tagLinks = jsonPost.TagLinks.split(',');
        this.link = jsonPost.PostLink.substring(6).slice(0, -1);
        this.metaTitle = jsonPost.MetaTitle;
        this.metaDescription = jsonPost.MetaDescription;
    }

}

export default PostDTO;