import React from "react";

function FilterSortButtonsBar(props){
    let viewToggleWrapperClass = props.showFilterButton ? null : "view-toggle-wrapper-class";

    const displayFilterButton = () => {
        if(props.showFilterButton === true) {
            return (
                <span className="btn" id="fiter-toggle" onClick={props.filterClicked}><i className="fa fa-filter"></i> Filtruj</span>
            );
        }
    }

    return (
        <div id="sorting-bar" className="fixedSortingBar">
            <div className="container flex side-padding">
                {displayFilterButton()}
                <div id="view-toggle-wrapper" className={viewToggleWrapperClass}>
                    <span id="set-view-grid"
                          className={props.viewType === "grid-view" ? "active" : ""}
                          onClick={() => props.onViewTypeChange("grid-view")}>
                        <i className="fa fa-th"></i> Widok siatki
                    </span>
                    <span id="set-view-list"
                          className={props.viewType === "list-view" ? "active" : ""}
                          onClick={() => props.onViewTypeChange("list-view")}>
                        <i className="fa fa-list-ul"></i> Widok listy
                    </span>
                </div>

                <span className="btn" id="sorting-toggle" onClick={props.sortClicked}><i className="fa fa-sort"></i> Sortuj</span>
            </div>
        </div>
    );
}

export default FilterSortButtonsBar;