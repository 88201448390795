import React, {useEffect, useState} from "react";
import PageTitle from "../../components/PageTitle/PageTitle";
import backgroundImage from "../../assets/images/content/offer_page_bg.png";
import MetaTagsManipulator from "../../utils/MetaTagsManipulator";
import UrlDecorator from "../../utils/UrlDecorator";
import FavouriteList from "./FavouriteList";
import SortDrawer from "./SortDrawer";
import FilterSortButtonsBar from "./FilterSortButtonsBar";

function ChoosenVehiclePage() {

    MetaTagsManipulator.setTitle("Oferta");
    MetaTagsManipulator.setDescription("Oferta");

    UrlDecorator.addSellerCode();

    const [isSortDrawerOpen, setIsSortDrawerOpen] = useState(false);
    const [offerViewType, setOfferViewType] = useState("grid-view");

    const sortButtonClickHandler = () => setIsSortDrawerOpen(true);
    const sortDrawerCloseHandler = () => setIsSortDrawerOpen(false);
    const viewTypeChangeHandler = viewType => setOfferViewType(viewType);

    return (
        <div className="lista-ofert">

            <SortDrawer isOpen={isSortDrawerOpen} title="Wybierz sortowanie" onClose={sortDrawerCloseHandler}/>

            <PageTitle favouriteList={true} title={"Oferta"} backgroundImage={backgroundImage} />

            <FilterSortButtonsBar
                viewType={offerViewType}
                onViewTypeChange={viewTypeChangeHandler}
                sortClicked={sortButtonClickHandler}
                showFilterButton={false}
            />

            <FavouriteList viewType={offerViewType} />
        </div>
    );
}

export default ChoosenVehiclePage;