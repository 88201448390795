import React, {Fragment, useEffect, useState} from "react";
import PageTitle from "../../components/PageTitle/PageTitle";
import {cmsService} from "../../utils/axios";
import MetaTagsManipulator from "../../utils/MetaTagsManipulator";
import FaqElementsCollapseList from "./FaqElementsCollapseList";
import backgroundImage from "../../assets/images/content/page-intro.png";

function FaqPage() {

    const [mainPage, setMainPage] = useState({
        Title: null,
        Content: null,
        BackgroundURL: null
    });

    const [faqSections, setFaqSections] = useState([]);


    function buildFaqSections (faqPages, parentId, level) {
        let pagesOnLevel = faqPages.filter(page => page.ParentID === parentId).sort((page1, page2) => page1.Sort - page2.Sort);
        let pages = [];
        level = level + 1;

        if (pagesOnLevel.length > 0) {

            let children = [];
            for (let page of pagesOnLevel) {
                children = buildFaqSections(faqPages, page.ID, level);

                pages.push({
                    id: page.ID,
                    level: level,
                    title: page.Title,
                    children: children,
                    faqElements: page.Questions
                });
            }
        }
        return pages;
    }

    useEffect(() => {
        cmsService("/FAQPage?add_fields=Questions,BackgroundURL&relationdepth=0").then(response => {
            let allFaqPages = response.data.items;
            let mainPageData = allFaqPages.filter(page => page.ParentID === 0)[0];

            if (mainPageData !== undefined) {
                if(mainPageData.BackgroundURL == null){
                    mainPageData.BackgroundURL = backgroundImage;
                }

                setMainPage(mainPageData);

                MetaTagsManipulator.setTitle(mainPageData.Title);
                MetaTagsManipulator.setDescription(mainPageData.MetaDescription);
            }

            let faqSections = buildFaqSections(allFaqPages, mainPageData.ID, 0);
            setFaqSections(faqSections);

        });
    }, []);

    const listElement = (id, title, children = null) => (<li key={id}>
        <a href={'#' + id}>{title}</a>
        {children}
    </li>);

    let sectionsList = faqSections.map(section => {
            let sectionChildren = null;

            if (section.children.length > 0) {
                sectionChildren = (
                    <ul>
                        {section.children.map(child => listElement(child.id, child.title))}
                    </ul>);
            }

            return listElement(section.id, section.title, sectionChildren);
        }
    );


    return (
        <Fragment>
            <PageTitle title={mainPage.Title} content={mainPage.Content} backgroundImage={mainPage.BackgroundURL}/>
            <div id="content">
                <div className="container page-faq">

                    <h2>Pytania i odpowiedzi</h2>

                    <ol>
                        {sectionsList}
                    </ol>
                    <hr/>
                    {faqSections.map(section => (<FaqElementsCollapseList key={section.id} section={section} />))}

                </div>
            </div>
        </Fragment>
    );

}

export default FaqPage