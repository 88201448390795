import React, {useContext, useEffect, useState} from "react";
import CustomerTypeSwitch from "../../components/CustomerTypeSwitch/CustomerTypeSwitch";
import {goVehisService} from "../../utils/axios";
import {CustomerTypeContext, isCustomerCompany} from "../../context/customer-type-context";
import {Link} from "react-router-dom";

import logoButton from "../../assets/images/icons/logo_icon_gold.svg";
import {useParams} from "react-router";
import FormatUtils from "../../utils/FormatUtils";
import UtmReader from "../../utils/UtmReader";

function OfferVariants(props) {

    const [offerVariants, setOfferVariants] = useState([]);
    const customerContext = useContext(CustomerTypeContext);
    let priceTypeLabel = isCustomerCompany(customerContext) ? "netto" : "brutto";

    const urlParams = useParams();

    useEffect(() => {
        goVehisService("/vehicles/versions/" + props.offerId + "/offers?customerType=" + customerContext[0]).then(response => {
            let offerVariants = response.data;

            if (offerVariants !== undefined) {
                let offerVariantsWithoutDuplicates = [];
                for(let variant of offerVariants){
                    let notAlreadyExists = offerVariantsWithoutDuplicates.findIndex(item => item.versionName === variant.versionName
                        && item.color === variant.color
                        && item.basicPriceNet === variant.basicPriceNet ) < 0;

                    if (notAlreadyExists) {
                        offerVariantsWithoutDuplicates.push(variant);
                    }
                }

                setOfferVariants(offerVariantsWithoutDuplicates);
            }
        });
    }, [customerContext[0], props.offerId]);

    let variantItems = offerVariants.map(variant => {

        let price = isCustomerCompany(customerContext) ? variant.basicPriceNet : variant.basicPriceGross;
        let installment = isCustomerCompany(customerContext) ? variant.installmentNet : variant.installmentGross;
        let selectedClassName = props.offerId === variant.id ? "selected" : "";
        let baseLink = "/" + urlParams.brand + "/" + urlParams.version + "/" + variant.id + "/" + "?ds=1";
        let linkToUrl = (UtmReader.readUtm("SOURCE") === "none" || UtmReader.readUtm("SOURCE") === "null") ? baseLink :
            baseLink + "&utm_source=" + UtmReader.readUtm("SOURCE") + "&utm_medium=" + UtmReader.readUtm("MEDIUM") +
            "&utm_campaign=" + UtmReader.readUtm("CAMPAIGN");



        return (
            <tr key={variant.id} className={selectedClassName}>
                <td className="td-wersja">{variant.versionName}</td>
                <td className="td-kolor">{variant.color}</td>
                <td className="td-cena">{FormatUtils.formatMoney(price)}</td>
                <td className="td-rata">od {FormatUtils.formatMoney(installment, 0)}/mc</td>
                <td className="td-zobacz">
                    <Link to={linkToUrl}>
                        <img src={logoButton} width="24" alt=""/>
                    </Link>
                </td>
            </tr>
        );

    });

    if (offerVariants.length > 1) {
        return (
                <div className="single-models single-box">
                    <h3>Wybierz wariant</h3>
                    <div className="table-toggle">
                        <CustomerTypeSwitch/>
                    </div>
                    <table>
                        <thead>
                        <tr>
                            <th className="th-wersja">Wersja</th>
                            <th className="th-kolorń">Kolor</th>
                            <th className="th-cena">Cena {priceTypeLabel}</th>
                            <th className="th-rata">Rata</th>
                            <th className="th-zobacz">Zobacz</th>
                        </tr>
                        </thead>
                        <tbody>
                        {variantItems}
                        </tbody>
                    </table>
                </div>
        );
    }

    return null;
}

export default OfferVariants;