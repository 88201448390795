import React, {useContext, useEffect, useState} from "react";
import CountUp from "react-countup";
import {useMediaQuery} from 'react-responsive'

import {goVehisService} from "../../utils/axios";
import {SearchOptionsContext} from "../../context/search-options-context";

function OurStrengths({vehicleType}){



    const isMobile = useMediaQuery({maxWidth: 767});

    const divClassName = vehicleType === "DOSTAWCZE" ? 'our-strengths-vans' : 'our-strengths';
    const vehiclesAndBrandsNumberClassName = isMobile && vehicleType === "DOSTAWCZE" ? "white bold" : "gold bold";

    let searchOptions = useContext(SearchOptionsContext);

    const [offersCount, setOffersCount] = useState(0);
    const [brandsCount, setBrandsCount] = useState(0);

    useEffect(() => {
        if(searchOptions.brandsAndModels.length > 0){

            goVehisService.get("/vehicles/count").then(response => {
                setOffersCount(response.data);
                setBrandsCount(searchOptions.brandsAndModels.length);
            });

        }
    }, [searchOptions]);


    return (
        <div className={divClassName}>
            <div className="choose-counter">
                Wybierz z <span className={vehiclesAndBrandsNumberClassName}>
                <CountUp end={offersCount} delay="1000" duration="7" />
            </span> aut<br/>
                <span className={vehiclesAndBrandsNumberClassName}>
                    <CountUp end={brandsCount} delay="1000" duration="5" />
                </span> marek
            </div>
            <ul className="strengths-list">
                <li>Leasing z <strong>gwarancją zwrotu</strong></li>
                <li><strong>Brak limitu</strong> kilometrów</li>
                <li><strong>Decyzja w 5 minut</strong></li>
            </ul>
        </div>
    );
}

export default OurStrengths;