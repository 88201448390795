import React, {useContext, useEffect} from "react";
import LeasingInstallmentCalculator from "./LeasingInstallmentCalculator";
import FinancialProductType from "../FinancialProductType";
import NajLeasingInstallmentCalculator from "./NajLeasingInstallmentCalculator";
import CreditInstallmentCalculator from "./CreditInstallmentCalculator";
import {CustomerTypeContext, isCustomerCompany} from "../../../context/customer-type-context";
import RentalInstallmentCalculator from "./RentalInstallmentCalculator/RentalInstallmentCalculator";
import LeasingOnlineInstallmentCalculator from "./LeasingOnlineInstallmentCalculator";

function InstallmentCalculatorStrategy(props) {

    const customerContext = useContext(CustomerTypeContext);

    let componentMap = {};
    componentMap[FinancialProductType.CREDIT] =
        <CreditInstallmentCalculator {...props} reservationStrategy={props.onShowOrderClickForCredit}/>;

    componentMap[FinancialProductType.LEASING] =
        <LeasingInstallmentCalculator {...props} reservationStrategy={props.onShowOrderClick}/>;

    componentMap[FinancialProductType.NAJLEASING] =
        <NajLeasingInstallmentCalculator {...props} reservationStrategy={props.onShowOrderClick}/>;

    componentMap[FinancialProductType.LEASING_ONLINE] = <LeasingOnlineInstallmentCalculator
        {...props}
        spinnerEnabled
        financingCompany="ALIOR"
        reservationStrategy={props.onShowOrderClick}
    />;

    componentMap[FinancialProductType.RENTAL] = <RentalInstallmentCalculator
        {...props}
        onGwarancjaZwrotuChange={props.onGwarancjaZwrotuChange(false)}
        reservationStrategy={props.onShowOrderClick}/>;

    const condition = props.offer.condition;
    const salesDocument = props.offer.salesDocument;

    if (condition === "USED" && salesDocument !== "FAKTURA_VAT" ||
        condition === "USED" && salesDocument === "FAKTURA_VAT" && !isCustomerCompany(customerContext) ||
        condition === "DEMO" && salesDocument !== "FAKTURA_VAT" ||
        condition === "DEMO" && salesDocument === "FAKTURA_VAT" && !isCustomerCompany(customerContext)) {
        return componentMap[FinancialProductType.CREDIT];
    } else {
        if (props.offer.financialProduct in componentMap) {
            return componentMap[props.offer.financialProduct];
        }
        return componentMap[FinancialProductType.LEASING]
    }
}

export default InstallmentCalculatorStrategy;