import hatchbackIcon from '../assets/images/content/nadwozia/hatchback.png';
import suvIcon from '../assets/images/content/nadwozia/suv.png';
import dostawczyIcon from '../assets/images/content/nadwozia/dostawczy.png';
import pickupIcon from '../assets/images/content/nadwozia/pickup.png';
import kabrioletIcon from '../assets/images/content/nadwozia/kabriolet.png';
import sportIcon from '../assets/images/content/nadwozia/sport.png';
import kombiIcon from '../assets/images/content/nadwozia/kombi.png';
import kombiVanIcon from '../assets/images/content/nadwozia/kombi-van.png';
import sedanIcon from '../assets/images/content/nadwozia/sedan.png';


const BodyTypeIcons = {
    "Hatchback": {icon: hatchbackIcon},
    "SUV / Crossover": {icon: suvIcon},
    "VAN / Bus": {icon: dostawczyIcon},
    "Pickup": {icon: pickupIcon},
    "Kabriolet": {icon: kabrioletIcon},
    "Coupe / Roadster": {icon: sportIcon},
    "Kombi Van": {icon: kombiVanIcon},
    "Sedan": {icon: sedanIcon},
    "Furgon": {icon: dostawczyIcon},
    "Kombi": {icon: kombiIcon}
}

export default BodyTypeIcons;
