import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom'

import {Container} from "@material-ui/core";
import InfiniteScroll from 'react-infinite-scroller';
import Skeleton from "@material-ui/lab/Skeleton";
import {Spinner} from "@blueprintjs/core";

import BlogDataAccess from "../../utils/BlogDataAccess";

import PageTitle from "../../components/PageTitle/PageTitle";

import BlogPostTeaser from "../../components/BlogPost/BlogPostTeaser";


function BlogCategoryPage({backgroundImage}) {
    const {categoryLink} = useParams();
    const postsPerLoad = 5;

    const [blogDataAccess, setBlogDataAccess] = useState(new BlogDataAccess());
    const [allPosts, setAllPosts] = useState(new Array());
    const [loadedPosts, setLoadedPosts] = useState(new Array())
    const [hasMore, setHasMore] = useState(true);

    useEffect(() => {
        setAllPosts(new Array())
        if(categoryLink === "najnowsze") {
            blogDataAccess.getNewPosts().then(setAllPosts);
        }else {
            blogDataAccess.getPostsByCategoryLink(categoryLink).then(setAllPosts);
        }
    }, [categoryLink])

    const loadPosts = (page) => {
        const loadStart = (page-1) * postsPerLoad;
        const loadLimit = (page-1) * postsPerLoad + postsPerLoad;
        //mozna na tej podstawie sprobowac pobierac z blogDataAccess

        const newlyLoadedPosts = allPosts.slice(loadStart, loadLimit)

        setLoadedPosts(loadedPosts.concat(newlyLoadedPosts));

        if(newlyLoadedPosts.length < postsPerLoad){
            setHasMore(false);
        }
    }


    return (
        <div id="content">
            <PageTitle title={categoryLink ? categoryLink.replace(/-/g, ' ') : 'Kategoria'} content={null} backgroundImage={backgroundImage}/>

            <Container fixed className="container" style={{padding:"25px", width: "90%"}}>

                {allPosts.length > 0 ?
                    <InfiniteScroll
                        threshold={0}
                        pageStart={0}
                        loadMore={loadPosts}
                        hasMore={hasMore}
                        loader={<Spinner />}>

                        {loadedPosts.map(post => <BlogPostTeaser blogPost={post} />)}
                    </InfiniteScroll>
                    :
                    <div>
                        <Skeleton variant="rect" width={"95%"} height={300} style={{marginTop:'30px'}} />
                        <Skeleton variant="rect" width={"95%"} height={300} style={{marginTop:'30px'}} />
                        <Skeleton variant="rect" width={"95%"} height={300} style={{marginTop:'30px'}} />
                        <Skeleton variant="rect" width={"95%"} height={300} style={{marginTop:'30px'}} />
                    </div>
                }

            </Container>

        </div>
    )
}

export default BlogCategoryPage;