class Session{
    static KEY = "GO_AUTO_SESSION_ID";

    static readSessionId() {
        let sessionId = sessionStorage.getItem(Session.KEY);

        if(sessionId){
            return sessionId;
        }

        sessionId = Date.now();
        sessionStorage.setItem(Session.KEY, sessionId);

        return sessionId;
    };

    static resetSession(){
        sessionStorage.removeItem(Session.KEY);
    }
}

export default Session;