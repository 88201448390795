import React, {Fragment, useState} from "react";
import {Collapse} from "@blueprintjs/core";
import {Link} from "react-router-dom";

function FaqElementsCollapseList(props) {

    let section = props.section;

    // initially hide all FAQ elements
    const [faqElements,setFaqElements] = useState(props.section.faqElements.map(element => ({
        ...element,
        showAnswer: false
    })))

    const clickFaqQuestionHandler = (event, id) => {

        let clickedFaqQuestion = faqElements.filter(element => (element.ID === id))[0];
        let indexOfClickedFaqElement = faqElements.indexOf(clickedFaqQuestion);

        let updatedFaqElements = [...faqElements];
        updatedFaqElements[indexOfClickedFaqElement] = {...clickedFaqQuestion, showAnswer: !clickedFaqQuestion.showAnswer};

        setFaqElements(updatedFaqElements);
    }

    let faqItems = null;
    if (props.section.faqElements.length > 0) {
        faqItems = faqElements.map(faqElement => (
            <div className="faq-item" key={faqElement.ID}>
                <div className="faq-question" onClick={(event) => clickFaqQuestionHandler(event, faqElement.ID)}>
                    <p>{faqElement.Question}</p>
                </div>
                <Collapse isOpen={faqElement.showAnswer}>
                    <div className="faq-answer" style={{display: "block"}} >
                        <p dangerouslySetInnerHTML={{__html: faqElement.Answer}}/>
                        <p>Chcesz wiedzieć więcej? <Link to="/kontakt">Skontaktuj się z nami</Link></p>
                    </div>
                </Collapse>
            </div>
        ));
    }

    let title = (<h3 id={props.section.id}>{props.section.title}</h3>);
    if (props.section.level === 2) {
        title = (<h4 id={props.section.id}>{props.section.title}</h4>);
    }

    return (
        <Fragment>
            {title}
            <div className="faq-items">
                {faqItems}
            </div>

            {props.section.children.map(subSection => (<FaqElementsCollapseList key={subSection.id} section={subSection}/>))}
        </Fragment>
    );
}

export default FaqElementsCollapseList;

