import React from "react";
import {Link} from "react-router-dom";
import {Icon, Intent} from "@blueprintjs/core";
import {IconNames} from "@blueprintjs/icons";

function PageTitle(props) {

    let backgroundImage = "";

    if(props.backgroundImage != null){
        backgroundImage = props.backgroundImage;
    }

    let backgroundStyle = {
        background: `url(${backgroundImage}) center center no-repeat`
    }

    let content = null;
    if(props.content != null){
        content = (<div className="page-intro-text" dangerouslySetInnerHTML={{__html: props.content}}/>);
    }

    const deleteAllVafouriteVehiclesHandler = () => {
        localStorage.setItem('favouriteVehicles', JSON.stringify([]));
        window.location.href = "ulubione";
    }

    const showDeleteAllFavouriteVehiclesHandler = () => {
        if(props.favouriteList === true) {
            return (
                <span className="delete-all-favourite-vehicles-button">
                        <Icon
                            icon={IconNames.TRASH}
                            iconSize={Icon.SIZE_LARGE}
                            intent={Intent.PRIMARY}
                            onClick={deleteAllVafouriteVehiclesHandler}
                        />
                    </span>
            );
        }
    }

    return (
        <div id="page-intro" style={backgroundStyle}>

            <div className="container">
                <div className="breadcrumb">
                    <Link to="/"><i className="fa fa-home"></i></Link>
                    <span>/</span>
                    <span>{props.title}</span>
                </div>
                <h1>
                    {props.title}
                    {/*{showDeleteAllFavouriteVehiclesHandler()}*/}
                </h1>
                    {content}
            </div>
            <br/>
        </div>
    );
}

export default PageTitle;