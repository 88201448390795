import React from "react";
import foto_gwarancja_zwrotu from "../../assets/images/content/foto_gwarancja_zwrotu.jpg";
import foto_brak_limitu from "../../assets/images/content/foto_brak_limitu.jpg";
import foto_gps from "../../assets/images/content/foto_gps.jpg";
import foto_serwis from "../../assets/images/content/foto_serwis.jpg";

function Benefits() {
    const categories = [
        {
            name: "Gwarancja zwrotu",
            url: "/gwarancja-zwrotu",
            style: {background: `url(${foto_gwarancja_zwrotu}) center center no-repeat`, backgroundSize: `cover`}
        },
        {
            name: "Brak limitu km",
            url: "/brak-limitu-km",
            style: {background: `url(${foto_brak_limitu}) center center no-repeat`, backgroundSize: `cover`}
        },
        {
            name: "Monitoring GPS",
            url: "/monitoring-gps",
            style: {background: `url(${foto_gps}) center center no-repeat`, backgroundSize: `cover`}
        },
        {
            name: "Karta serwisowa",
            url: "/karta-serwisowa",
            style: {background: `url(${foto_serwis}) center center no-repeat`, backgroundSize: `cover`}
        },

    ];

    let benefitsView = categories.map(benefit => (
            <div key={benefit.name} className="usp-item"
                 style={benefit.style}>
                <h6>{benefit.name}</h6>
                <a href={benefit.url} className="btn offer-btn">Zobacz</a>
            </div>
        )
    );

    return (
        <div id="usp">
            <h3 className="center">Poznaj korzyści Go-Auto</h3>
            <div className="container flex">
                {benefitsView}
            </div>
        </div>
    );
}

export default Benefits;