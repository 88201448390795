import React from "react";
import {NumericInput, Tag} from "@blueprintjs/core";

function CurrencyInput({name, value, inputRef, onChange}){
    return (
        <NumericInput
            inputRef={inputRef}
            name={name}
            buttonPosition="none"
            locale="pl-PL"
            stepSize={1}
            min={0}
            max={1000000000}
            rightElement={<Tag>zł</Tag>}
            clampValueOnBlur={true}
            value={value}
            onValueChange={value => onChange(name,value)}
        />
    );
}

export default CurrencyInput;