import React from "react";
import OwlCarousel from "react-owl-carousel";

function Testimonials() {
    return (
        <div id="testimonials">
            <div className="container">
                <h3 className="center">Opinie naszych Klientów</h3>
                <OwlCarousel
                    id="testimonials-slider"
                    loop={true}
                    margin={60}
                    dots={true}
                    nav={true}
                    mouseDrag={false}
                    navText={["<", ">"]}
                    responsive={{
                        0: {items: 1, margin: 0},
                        480: {items: 2},
                        768: {items: 2},
                        1024: {items: 2},
                        1199: {items: 4}
                    }}>
                    <div className="testimonial">
                        <p>Bardzo polecam Panią Joannę Matuszewską. Bardzo szybka i rzetelna obsługa. Szybka nie
                            znaczy
                            pierwsza lepsza oferta. Na pewno w najbliższym czasie skorzystam ponownie z usług Pani
                            Joanny.</p>
                        <strong>Modern Bud Lach Maciej<br/>
                            Nip 5532352274</strong>
                    </div>
                    <div className="testimonial">
                        <p>Współpracę z Panem Dariuszem Żabą jak i z firmą Go-leasing oceniam bardzo dobrze. Pan
                            Dariusz
                            profesjonalnie podszedł do tematu, jest bardzo pomocny. Jakość firmy na bardzo wysokim
                            poziomie. Zachęcam do współpracy.</p>
                        <strong>AMW-CATERING WALDEMAR GACA<br/>
                            NIP 6321515053</strong>
                    </div>
                    <div className="testimonial">
                        <p>Bardzo sprawnie, ekspresowo, jestem bardzo zadowolona.</p>
                        <strong>Usługi Księgowe Gama Jolanta Bielecka<br/>
                            52-204 Wrocław<br/>
                            NIP 692-19-90-822</strong>
                    </div>
                    <div className="testimonial">
                        <p>Nie spodziewałem się że dostanę tak szybko odpowiedź odnośnie mojego zapytania.
                            Formalności
                            załatwiłem błyskawicznie w ciągu jednego dnia. Sprzęt otrzymałem w mniej niż 3 dni od
                            podpisania umowy u kuriera. Zdecydowanie polecam tą firmę gdyż wykazała się ogromnym
                            profesjonalizmem, szybkością i skutecznością!</p>
                        <strong>KRJ Rafał Janczak<br/>
                            02-527 Warszawa<br/>
                            NIP: 5213048795</strong>
                    </div>
                    <div className="testimonial">
                        <p>Jestem zadowolony z usług GO-leasing. Wszystko przebiegło sprawnie, nie mam zastrzeżeń.
                            Rzetelna firma i doradca. Chętnie nawiążę współpracę na tych samych warunkach, bo są dla
                            mnie satysfakcjonujące.</p>
                        <strong>PIOTR KOZIOŁ JAROSŁAW BYKOWSKI TECHEM SPÓŁKA CYWILNA<br/>
                            NIP: 6272681721</strong>
                    </div>
                    <div className="testimonial">
                        <p>Współpraca z firmą GO-leasing przebiegła bardzo fajnie. Doradca przeprowadził wszystko
                            prawidłowo i sprawnie. Chętnie skorzystam ponownie z ofert firmy GO-leasing.</p>
                        <strong>Grzegorz G. BIURO FINANSOWE "PROFIT" JAKONIUK DANUTA<br/>
                            NIP: 9690400181</strong>
                    </div>
                    <div className="testimonial">
                        <p>Wszystko super w porządku. Zadzwoniłem, powiedziałem jakie auto chcę. Pani doradca szybko
                            przygotowała stosowną ofertę, którą zaakceptowałem. Poproszono mnie o przygotowanie
                            konkretnych dokumentów. Sprawy potoczyły się w bardzo szybkim tempie. Wystarczył podpis
                            i
                            jestem bardzo zadowolony. Profesjonalnie i rzetelnie. Polecam!</p>
                        <strong>EURO-AS ARTUR DERDAS,SŁAWOMIR SOKOŁOWSKI S.C. <br/>
                            NIP:6080106642</strong>
                    </div>
                </OwlCarousel>
            </div>
        </div>
    );
}

export default Testimonials;