import axios from 'axios';

export const goVehisService = axios.create({
    baseURL: process.env.REACT_APP_GO_VEHIS_API_URL
});

export const goKredytService = axios.create({
    baseURL: process.env.REACT_APP_GO_KREDYT_API_URL
})

//got rid of authorization to improve performance
export const cmsService = axios.create({
    baseURL: process.env.REACT_APP_CMS_API_URL,
    // auth: {
    //     username: process.env.REACT_APP_CMS_USERNAME,
    //     password: process.env.REACT_APP_CMS_PASSWORD
    // },
    headers: {'Accept': 'application/json'}
});


export const leasingOnlineService = axios.create({
    baseURL: process.env.REACT_APP_LEASING_ONLINE_API_URL
});
