import React, {Fragment} from "react";
import {Card, Elevation} from "@blueprintjs/core";
import withFormErrorHandler from "../../Reservation/withFormErrorHandler";
import RepresentativeList from "../../Reservation/AdditionalData/Company/RepresentativeList";
import Agreements from "../../Reservation/Agreements";
import PersonAdditionalDataForm from "../../Reservation/AdditionalData/Person/PersonAdditionalDataForm";
import RepresentativeListForCredit from "./Company/RepresentativeListForCredit";
import PersonAdditionalDataFormForCredit from "./Person/PersonAdditionalDataFormForCredit";

function StepForAdditionalDataForCredit(props) {

    const agreementsChangeHandler = agreements => props.onChange({...props.proposal, agreements: agreements});
    const representativesChangeHandler = representatives => props.onChange({...props.proposal, representatives: representatives});
    const proposalChangeHandler = proposal => props.onChange(proposal);

    const onNextClickHandler = () => {
        props.onCheckErrorHandler(() => (props.onNextClick()));
    }

    let formFields = props.isCompany ? (
        <RepresentativeListForCredit
            formValidator={props.formValidator}
            company={props.proposal.company}
            representatives={props.proposal.representatives}
            proposal={props.proposal}
            onChange={representativesChangeHandler}
            onAverageSalaryChange={proposalChangeHandler}
        />
    ) : (
        <PersonAdditionalDataFormForCredit
            formValidator={props.formValidator}
            proposal={props.proposal}
            onChange={proposalChangeHandler}/>
    );

    return (
        <Fragment>

            <div className="config-modal-body">

                <form className="order-form">
                    <Card elevation={Elevation.ONE} className="form-section">

                        {formFields}

                    </Card>
                    <Card elevation={Elevation.ONE} className="form-section">
                        <Agreements onChange={agreementsChangeHandler} formValidation={props.formValidator} checkedAgreements={props.proposal.agreements} />
                    </Card>
                </form>
                <div className="clearfix"></div>

            </div>


            <div className="config-modal-footer">

                <div className="flex">
                    <button type="button" className="back" onClick={props.onBackClick}><i className="fa fa-angle-left"></i>Wstecz</button>
                    <button type="button" className="send-proposal" onClick={onNextClickHandler}>Wyślij wniosek</button>
                </div>

            </div>
        </Fragment>
    );
}

export default withFormErrorHandler(StepForAdditionalDataForCredit);