import React, {useEffect, useState} from "react";

import banner_1 from "../../assets/images/content/home_banners/home_banner_1.jpg";
import banner_2 from "../../assets/images/content/home_banners/home_banner_2.jpg";
import banner_3 from "../../assets/images/content/home_banners/home_banner_3.jpg";
import banner_4 from "../../assets/images/content/home_banners/home_banner_4.jpg";
import banner_5 from "../../assets/images/content/home_banners/home_banner_5.jpg";
import banner_6 from "../../assets/images/content/home_banners/home_banner_6.jpg";
import banner_7 from "../../assets/images/content/home_banners/home_banner_7.jpg";
import banner_8 from "../../assets/images/content/home_banners/home_banner_8.jpg";
import {Link} from "react-router-dom";
import {goVehisService} from "../../utils/axios";

function OfferCategories() {
    const [minimumCategoryPrices, setMinimumCategoryPrices] = useState({
        "crossovery_bestsellery": "626",
        "od_reki": "551",
        "na_kazda_kieszen": "458",
        "pakujesz_i_dostarczasz": "560",
        "tylko_premium": "966",
        "sportowy_duch": "1374",
        "e_jazda": "526",
        "rodzinny_towarzysz": "594"
    });

    useEffect(() => {
        goVehisService("/categoryPrices").then(response => {
            if(response.data) {
                setMinimumCategoryPrices(response.data);
            }
        }).catch(error => {
            console.log("Error with displaying minimum prices for categories!")
        });

    },[]);
    const categories = [
        //parseInt przy price: jest po to, by nie bylo cyfer po przecinku
        {
            name: "Dostępne od ręki",
            price: parseInt(minimumCategoryPrices.od_reki),
            style: {background: `url(${banner_1}) center center no-repeat`, backgroundSize: `cover`},
            url: "lista-ofert?availability=IN_STOCK"
        },
        {
            name: "Rodzinny towarzysz",
            price: parseInt(minimumCategoryPrices.rodzinny_towarzysz),
            style: {background: `url(${banner_2}) center center no-repeat`, backgroundSize: `cover`},
            url: "lista-ofert?bodyTypes=Kombi%2CVAN+%2F+Bus&vehicleTypes=OSOBOWE"
        },
        {
            name: "Na każdą kieszeń",
            price: parseInt(minimumCategoryPrices.na_kazda_kieszen),
            style: {background: `url(${banner_3}) center center no-repeat`, backgroundSize: `cover`},
            url: "lista-ofert?installmentNetTo=1000&vehicleTypes=OSOBOWE"
        },
        {
            name: "Crossovery bestsellery",
            price: parseInt(minimumCategoryPrices.crossovery_bestsellery),
            style: {background: `url(${banner_4}) center center no-repeat`, backgroundSize: `cover`},
            url: "lista-ofert?bodyTypes=SUV+%2F+Crossover&segments=SUVy+%2F+terenowe+małe%2CSUVy+%2F+terenowe+średnie"
        },
        {
            name: "Tylko premium",
            price: parseInt(minimumCategoryPrices.tylko_premium),
            style: {background: `url(${banner_5}) center center no-repeat`, backgroundSize: `cover`},
            url: "lista-ofert?isPremium=true&vehicleTypes=OSOBOWE"
        },
        {
            name: "Pakujesz i dostarczasz",
            price: parseInt(minimumCategoryPrices.pakujesz_i_dostarczasz),
            style: {background: `url(${banner_6}) center center no-repeat`, backgroundSize: `cover`},
            url: "lista-ofert?vehicleTypes=DOSTAWCZE"
        },
        {
            name: "Sportowy duch",
            price: parseInt(minimumCategoryPrices.sportowy_duch),
            style: {background: `url(${banner_7}) center center no-repeat`, backgroundSize: "cover"},
            url: "lista-ofert?bodyTypes=Coupe+%2F+Roadster%2CHatchback%2CKabriolet%2CSedan&enginePowerHpFrom=240&vehicleTypes=OSOBOWE"
        },
        {
            name: "e-Jazda",
            price: parseInt(minimumCategoryPrices.e_jazda),
            style: {background: `url(${banner_8}) center center no-repeat`, backgroundSize: `cover`},
            url: "lista-ofert?fuelTypes=Elektryczny%2CHybryda"
        },
    ];

    let categoriesView = categories.map(category => (
            <Link key={category.name} to={category.url} className="home_banner"
               style={category.style}>
                <span>{category.name}</span>
                <small>od {category.price} zł</small>
            </Link>
        )
    );

    return (
        <div id="home-banners">
            <h3 className="center">Dopasuj do siebie</h3>
            <div className="container">
                <div className="home_banners_wrapper flex flex-wrap">
                    {categoriesView}
                </div>
            </div>
        </div>
    );
}

export default OfferCategories;