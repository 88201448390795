class ProposalRepository{

    static PROPOSAL_KEY = "proposal";
    static OFFER_KEY = "offer";

    read(key) {
        const object = sessionStorage.getItem(key);

        try {
            return JSON.parse(object);
        } catch (e) {
            return null;
        }
    };

    readProposal() {
        return this.read(ProposalRepository.PROPOSAL_KEY);
    };

    readOffer() {
        return this.read(ProposalRepository.OFFER_KEY);
    };

    persistProposal(proposal) {
        return sessionStorage.setItem(ProposalRepository.PROPOSAL_KEY, JSON.stringify(proposal));
    };

    persistOffer(offer) {
        return sessionStorage.setItem(ProposalRepository.OFFER_KEY, JSON.stringify(offer));
    };

    removeAll() {
        sessionStorage.removeItem(ProposalRepository.OFFER_KEY);
        sessionStorage.removeItem(ProposalRepository.PROPOSAL_KEY);
    };


}

export default ProposalRepository;