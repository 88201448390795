import React, {Fragment, useEffect, useState} from "react";
import MenuCheckbox from "../ProductMenu/MenuCheckbox";

function CheckboxColumnList({columnCount, items, onChange, selectedItems, resetItems}){

    const [checkedItems, setCheckedItems] = useState(selectedItems ? selectedItems : []);
    const [checkedAllItems, setCheckedAllItems] = useState(false);

    useEffect(() => { if(resetItems) setCheckedItems([]);},[resetItems]);


    const allItemsCheckedHandler = (event) => {
        let allChecked = event.target.checked;

        setCheckedAllItems(allChecked);

        if(allChecked){
            setCheckedItems([...items]);
            onChange([...items]);
        } else {
            setCheckedItems([]);
            onChange([]);
        }


    }

    const itemCheckedHandler = (event) => {
        const checkedItems = [];

        for (let item of items) {
            let isItemSelected = isSelected(item.name);

            if (item.name === event.target.value) {
                isItemSelected = event.target.checked;
            }

            if (isItemSelected){
                checkedItems.push(item);
            }
        }

        setCheckedItems(checkedItems);
        onChange(checkedItems)
    }

    const isSelected = itemName =>
        (checkedItems.filter(selectedItem => selectedItem.name === itemName).length > 0);

    const columnsOfCheckboxes = () => {
        let itemsPerColumn = Math.ceil(items.length / columnCount);
        let columns = [];
        let columnWidthStyle = {width: 100/columnCount + "%"};


        if (items.length > 0) {
            for (let columnIndex = 0; columnIndex < columnCount; columnIndex++) {
                let startItemIndex = columnIndex * itemsPerColumn;
                let columnOfItems = items.slice(startItemIndex, startItemIndex + itemsPerColumn);

                let checkboxes = columnOfItems.map(item => {

                    return (
                        <MenuCheckbox
                            key={item.name}
                            image={(<img src={item.image} alt={item.name}/>)}
                            name={item.name}
                            selected={itemCheckedHandler}
                            checked={isSelected(item.name)}>
                            {item.name}
                        </MenuCheckbox>
                    )
                });

                columns[columnIndex] = (
                    <div className="sub-menu-column" key={columnIndex} style={columnWidthStyle}>
                        {checkboxes}
                    </div>
                );
            }
        }

        return columns;
    }


    return (
        <Fragment>
            <div className="form-line">
                <label htmlFor="allItems" className="checkbox-label all-items-label">
                    <input name="allItems"
                           type="checkbox"
                           checked={checkedAllItems}
                           onChange={allItemsCheckedHandler}/>Zaznacz wszystkie
                </label>
            </div>
            <div className="form-line">
                {columnsOfCheckboxes()}
            </div>
        </Fragment>
    );
}

export default CheckboxColumnList;