import React, {Fragment, useContext} from 'react'
import {SellerContext} from "../../context/seller-context";

const PrintSellerContact = () => {
    const seller = useContext(SellerContext);
    const firstAndLastName = seller.firstName ? (<Fragment><p>{seller.firstName} {seller.lastName}</p></Fragment>) : null;

    return(
        <div id="seller-print-contact">
            <p><strong>Zapraszam do kontaktu.</strong></p>
            <p>{seller.email}</p>
            <p>+48 {seller.phone}</p>
            {firstAndLastName}
            <p>{seller.street}</p>
            <p>{seller.postalCode} {seller.city}</p>
        </div>
    )
}

export default PrintSellerContact;