import React, {useState} from "react";
import BrandAndModelSearch from "../ProductSearchField/BrandAndModelSearch";
import URLSearchParamsConverter from "../../utils/URLSearchParamsConverter";
import {useHistory} from "react-router";

function ModelsSubmenu(props) {

    const [selectedBrands, setSelectedBrands] = useState([]);
    const [selectedModels, setSelectedModels] = useState([]);

    const history = useHistory();

    const changeBrandHandler = (brands) => {
        setSelectedBrands(brands);
    };

    const changeModelHandler = (models) => {
        setSelectedModels(models);
    };

    const searchButtonClicked = () => {
        let searchParameters = {
            brands: selectedBrands,
            models: selectedModels
        }

        history.push({
            pathname: "/lista-ofert",
            search: URLSearchParamsConverter.createURLSearchString(searchParameters)
        });

        setSelectedBrands([]);
        props.onClose();
    }

    return (

        <form action="">
            <BrandAndModelSearch resetBrands={selectedBrands.length === 0} brandsAndModels={props.brandsAndModels} onBrandChange={changeBrandHandler} onModelChange={changeModelHandler} />

            <button type="button" onClick={searchButtonClicked}>Szukaj</button>
        </form>
    );
}

export default ModelsSubmenu;