import React, {useContext, useEffect, useState} from "react";
import MetaTagsManipulator from "../../utils/MetaTagsManipulator";
import backgroundImage from "../../assets/images/content/konfigurator.jpg";
import {CustomerTypeContext, isCustomerCompany} from "../../context/customer-type-context";
import SellerCodeReader from "../../utils/SellerCodeReader";
import StepForBasicData from "./BasicData/StepForBasicData";
import ReservationHeader from "./ReservationHeader";
import StepForInitialData from "./InitialData/StepForInitialData";
import StepForAdditionalData from "./AdditionalData/StepForAdditionalData";
import DecisionInfo from "./DecisionInfo";
import Session from "../../utils/Session";
import {goVehisService} from "../../utils/axios";
import sleeper from "../../utils/sleeper";
import AppToaster from "../../components/AppToaster";
import SpinnerOverlay from "../../components/SpinnerOverlay/SpinnerOverlay";
import ProposalRepository from "../../utils/ProposalRepository";
import analytics from "../../utils/analytics";

function Reservation({offer, onShowOffer}) {

    MetaTagsManipulator.setTitle("Wniosek online");

    const customerContext = useContext(CustomerTypeContext);
    const isCompany = isCustomerCompany(customerContext);

    let proposalRepository = new ProposalRepository();
    let sellerCodeReader = new SellerCodeReader();

    const [proposal, setProposal] = useState({
        "vehicleId": offer.vehicle.id,
        "sellerCode": sellerCodeReader.readSellerCode(),
        "offerUrl": window.location.href,
        "customerType": customerContext[0],
        "employmentForm": "EMPLOYMENT_CONTRACT",
        "employer": {
            "nip": "",
            "phoneNumber": ""
        },
        "company": {
            "nip": "",
            "creationDate": null,
            "name": "",
            "accountingForm": "",
            "pkd": "",
            "bikStatus": "",
            "location": "",
            "voivodeship": "",
            "legalForm": "",
            "employeesNumber": "",
            "loadedFromRegon": false,
            "incomePreviousYear": null,
        },
        "calculation": offer.calculation,
        "representatives": [{
            "pesel": "",
            "email": "",
            "phoneNumber": "",
            "location": "",
            "firstName": "",
            "lastName": "",
            "identityCardType": "ID_CARD",
            "identityCardNumber": "",
            "identityCardExpirationDate": null,
            "identityCardAuthority": "",
            "maritalStatus": null,
            "propertySeparation": null,
            "address": "",
            "postalCode": "",
            "spouse": null,
            "nationality": "Polska",
            "companyNip": ""
        }],
        "agreements": [],
        "financingCompany": offer.financingCompany,
    });

    const [calculationOffer, setCalculationOffer] = useState({
        "vehicleId": offer.vehicle.id,
        "sellerCode": sellerCodeReader.readSellerCode(),
        "offerUrl": window.location.href,
        "customerType": customerContext[0],
        "calculation": offer.calculation,
        "agreements": [],
        "email": "",
        "nip": "",
        "phoneNumber": "",
        "firstName": "",
        "financingCompany": offer.financingCompany,
    });

    useEffect(() => {
        let persistedOffer = proposalRepository.readOffer();
        if(persistedOffer){
            setCalculationOffer(prevState => ({
                ...prevState,
                email: persistedOffer.email,
                nip:  isCompany ? persistedOffer.nip : "",
                phoneNumber: persistedOffer.phoneNumber,
                firstName: isCompany ? "" : persistedOffer.firstName,
                agreements: persistedOffer.agreements
            }));
        }

        let persistedProposal = proposalRepository.readProposal();
        if(persistedProposal){
            setProposal(prevState => ({
                ...prevState,
                company: persistedProposal.company,
                representatives: persistedProposal.representatives,
                agreements: persistedProposal.agreements
            }));
        }

    },[]);

    const [decision, setDecision] = useState(null);
    const [isOpenSpinner, setIsOpenSpinner] = useState(false);

    let backgroundStyle = {
        background: `url(${backgroundImage}) center center no-repeat`,
        backgroundSize: "cover",
        paddingTop: "70px"
    };


    const [currentStepNumber, setCurrentStepNumber] = useState(1);

    function scrollToTop(){
        window.scrollTo({behavior: 'smooth', top: 150});
    }

    const backToOfferHandler = () => {
        proposalRepository.persistOffer(calculationOffer);
        proposalRepository.persistProposal(proposal);
        onShowOffer();
    }

    const goToNextStep = () => {
        scrollToTop();
        analytics.sendEvent({
            category: 'Rezerwacja  - Krok ' + currentStepNumber,
            action: 'Klikniecie',
            label: 'Kliknieto w dalej',
            value: 1
        });

        setCurrentStepNumber(prevState => prevState + 1);
    }
    const goToPreviousStep = () => {
        scrollToTop();
        setCurrentStepNumber(prevState => prevState > 0 ? prevState - 1 : 0);
    }

    function getProposalUrl() {
        let sessionId = Session.readSessionId();

        let endpoint = "/proposals";

        if(!isCompany){
            endpoint = "/personproposals"
        }

        return endpoint + "?sessionId=" + sessionId;
    }

    function getProposal() {
        let proposalToSend = {...proposal};

        if(!isCompany){
            proposalToSend = {...proposal, representative: proposal.representatives[0], representatives: null};
        }

        return proposalToSend;
    }

    const sendProposalButtonClickHandler = () => {
        setIsOpenSpinner(true);

        goVehisService.post(getProposalUrl(), getProposal()).then(sleeper(5000)).then(response => {
            setDecision(response.data);
            Session.resetSession();
            proposalRepository.removeAll();
            goToNextStep();
        }).catch(error => {
            AppToaster.show({message: "Ups! Wystąpił jakiś błąd. Spróbuj ponownie.", intent: "danger"})
        }).finally(() => {
            setIsOpenSpinner(false);
        });
    };


    const sendCalculationOffer = () => {

        let sessionId = Session.readSessionId();

        goVehisService.post("/calculationOffer?sessionId=" + sessionId, calculationOffer);
    };

    const goToBasicDataStep = () => {
        if (calculationOffer.nip) {
            setProposal(prevState => ({
                ...prevState,
                company: {
                    ...prevState.company,
                    nip: calculationOffer.nip,
                    loadedFromRegon: false
                },
                representatives: [{
                    ...prevState.representatives[0],
                    email: calculationOffer.email,
                    phoneNumber: calculationOffer.phoneNumber,
                }],
                agreements: [...calculationOffer.agreements]
            }))
        }

        if (calculationOffer.firstName) {
            setProposal(prevState => ({
                ...prevState,
                company: null,
                representatives: [{
                    ...prevState.representatives[0],
                    firstName: calculationOffer.firstName,
                    email: calculationOffer.email,
                    phoneNumber: calculationOffer.phoneNumber,
                }],
                agreements: [...calculationOffer.agreements]
            }))
        }

        sendCalculationOffer();

        goToNextStep();
    }

    const initialDataChangeHandler = initialData => setCalculationOffer(prevState => ({...prevState, ...initialData}));
    const proposalChangeHandler = proposal => setProposal(prevState => ({...prevState, ...proposal}));



    let currentStep = null;

    switch (currentStepNumber) {
        case 1:
            currentStep = (<StepForInitialData
                isCompany={isCompany}
                offerWithCalculation={calculationOffer}
                checkedAgreements={proposal.agreements}
                onChange={initialDataChangeHandler}
                onNextClick={goToBasicDataStep}
            />);
            break;
        case 2:
            currentStep = (<StepForBasicData
                    isCompany={isCompany}
                    proposal={proposal}
                    onChange={proposalChangeHandler}
                    onNextClick={goToNextStep}
                    onBackClick={goToPreviousStep}
                />
            );
            break;
        case 3:
            currentStep = (<StepForAdditionalData
                    isCompany={isCompany}
                    proposal={proposal}
                    onChange={proposalChangeHandler}
                    onNextClick={sendProposalButtonClickHandler}
                    onBackClick={goToPreviousStep}
                />
            );
            break;
        case 4:
            currentStep = (
                <div className="config-modal-body">
                    <DecisionInfo
                        decision={decision}
                    />
                </div>
            );
            break;
    }

    return (
        <div id="config" style={backgroundStyle}>
            <h2>Wniosek online</h2>
            <h3>{offer.vehicle.brandName + " " + offer.vehicle.versionName}</h3>
            <div id="config-modal">

                <ReservationHeader
                    currentNumber={currentStepNumber}
                    onStepChange={number => setCurrentStepNumber(number)}
                    onBackToOffer={backToOfferHandler}
                />

                {currentStep}

            </div>
            <SpinnerOverlay isOpen={isOpenSpinner} usePortal={true}/>

        </div>
    );
}

export default Reservation;