
class MetaTagsManipulator {

    static setDefaultTitle() {
        document.title = "GO-auto";
    }

    static setTitle(title){
        document.title = title + " - GO-auto ";
    }

    static setDescription(description){
        if(description != null) {
            document.description = description;
        }
    }

    static getTitle() {
        return document.title;
    }

    static getDescription() {
        return document.description;
    }

}

export default MetaTagsManipulator;