import React, {useState} from "react";
import {useHistory} from "react-router";
import URLSearchParamsConverter from "../../utils/URLSearchParamsConverter";
import CheckboxColumnList from "../CheckboxColumnList/CheckboxColumnList";
import SegmentIcons from "../../utils/SegmentIcons";

function SegmentsSubmenu(props){

    const [selectedSegments, setSelectedSegments] = useState([]);

    const history = useHistory();


    const segmentSelectedHandler = selectedSegments => setSelectedSegments(selectedSegments.map(segment => segment.name));

    const searchButtonClickHandler = () => {
        let searchParameters = {
            segments: selectedSegments
        }

        history.push({
            pathname: "/lista-ofert",
            search: URLSearchParamsConverter.createURLSearchString(searchParameters)
        });

        setSelectedSegments([]);
        props.onClose();
    }

    const allSegments =  props.segments.map(segment => {
        return {name:segment, image: SegmentIcons[segment].icon}
    });


    return (
        <form id="szukaj-segmentu-form" action="">
            <CheckboxColumnList
                selectedItems={selectedSegments}
                resetItems={selectedSegments.length === 0}
                columnCount={2}
                items={allSegments}
                onChange={segmentSelectedHandler}
            />

            <div className="form-line">
                <button type="button" onClick={searchButtonClickHandler}>Szukaj</button>
            </div>
        </form>

    );
}

export default SegmentsSubmenu;