import React, {useEffect, useState} from "react";
import {Checkbox, Classes, Dialog} from "@blueprintjs/core";

function RepresentativesToChooseDialog(props) {

    const [representatives, setRepresentatives] = useState([]);

    useEffect(() => {
        setRepresentatives(props.items.map(item => ({
            ...item,
            selected: false
        })));
    }, [props.items]);

    const selectedItemChangeHandler = (event, index) => {
        const {checked} = event.target;

        let updatedRepresentatives = [...representatives];
        updatedRepresentatives[index].selected = checked;

        setRepresentatives(updatedRepresentatives);
    };

    const chooseButtonClickHandler = () => {
        let selectedRepresentatives = representatives.filter(item => item.selected);

        props.onChoose(selectedRepresentatives);
        props.onClose();
    }


    return (
        <Dialog
            isOpen={props.isOpen}
            usePortal={true}
            onClose={props.onClose}
            title="Wybierz reprezentantów"
        >
            <div className={Classes.DIALOG_BODY}>
                {representatives.map((representative, index) => (
                    <Checkbox
                        style={{fontSize: "1rem"}}
                        key={index}
                        value={representative.firstName + representative.lastName}
                        checked={representative.selected}
                        onChange={event => selectedItemChangeHandler(event, index)}
                    >
                        {representative.firstName} {representative.lastName} - {representative.function}
                    </Checkbox>
                ))}
            </div>

            <div className={Classes.DIALOG_FOOTER}>
                <button type="button" className="dialog-btn reverse-btn" onClick={props.onClose}>Anuluj</button>
                <button type="button" className="dialog-btn" onClick={chooseButtonClickHandler}>Wybierz</button>
            </div>
        </Dialog>
    );
}

export default RepresentativesToChooseDialog;