import React, {Fragment, useEffect, useState} from "react";
import SimpleMultiSelect from "../MultiSelect/SimpleMultiSelect";
import GroupedMultiSelect from "../MultiSelect/GroupedMultiSelect";

function BrandAndModelSearch(props) {

    const [selectedBrands, setSelectedBrands] = useState(props.selectedBrands ? props.selectedBrands : []);
    const [availableModels, setAvailableModels] = useState([]);
    const [selectedModels, setSelectedModels] = useState(props.selectedModels ? props.selectedModels : []);

    useEffect(() => {
        if(props.resetBrands) {
            setSelectedModels([]);
            setSelectedBrands([]);
        }},[props.resetBrands]);

    let placeholderForBrands = "Wybierz markę";
    if(props.placeholderForBrands != null){
        placeholderForBrands = props.placeholderForBrands;
    }

    let placeholderForModels = "Wybierz model";
    if(props.placeholderForModels != null){
        placeholderForModels = props.placeholderForModels;
    }

    const {onBrandChange, onModelChange} = props;

    useEffect(() => {
        onBrandChange(selectedBrands);
        addAvailableModels(selectedBrands);
    },[selectedBrands, onBrandChange]);

    useEffect(() => {
        onModelChange(selectedModels);
    },[selectedModels, onModelChange]);

    const deselectBrandHandler = (brandName) => {
        let deselectedBrandIndex = selectedBrands.map((brand) => brand.name).indexOf(brandName);

        if (deselectedBrandIndex > -1) {
            let updatedSelectedBrands = [...selectedBrands];
            updatedSelectedBrands.splice(deselectedBrandIndex,1);
            setSelectedBrands(updatedSelectedBrands);
        }
    }

    const addAvailableModels = (brands) => {
        let modelsToAdd = [];

        brands.map( (brand) => {
            for (let model of brand.models) {
                modelsToAdd.push({
                    name: model,
                    groupName: brand.name
                });
            }
        });

        setAvailableModels(modelsToAdd);
    }

    const selectBrandsHandler = (brand) => {
        let selectedBrandIndex = selectedBrands.indexOf(brand);

        if (selectedBrandIndex === -1) {
            let brands = [...selectedBrands, brand];
            setSelectedBrands(brands);
        } else {
            deselectBrandHandler(brand.name);
        }
    }

    const selectAllBrandsHandler = (event) => {
        if (event.target.checked) {
            setSelectedBrands(props.brandsAndModels);
        } else {
            setSelectedBrands([]);
            setAvailableModels([]);
            setSelectedModels([]);
        }
    }

    const selectAllModelsHandler = (event) => {
        if (event.target.checked) {
            setSelectedModels(availableModels);
        } else {
            setSelectedModels([]);
        }
    }

    const changeSelectedModelHandler = (model, event) => {
        if (event.target.checked) {
            selectModelHandler(model);
        } else {
            deselectModelHandler(model.name)
        }
    }

    const deselectModelHandler = (modelName) => {
        let deselectedModelIndex = selectedModels.map((model) => model.name).indexOf(modelName);

        if (deselectedModelIndex > -1) {
            let updatedSelectedModels = [...selectedModels];
            updatedSelectedModels.splice(deselectedModelIndex,1);
            setSelectedModels(updatedSelectedModels);
        }
    }

    const selectModelHandler = (model) => {
        let selectedModelIndex = selectedModels.indexOf(model);

        if (selectedModelIndex === -1) {
            setSelectedModels([...selectedModels, model]);
        } else {
            deselectModelHandler(model.name);
        }
    }

    return (

        <Fragment>
            <SimpleMultiSelect
                itemSelected={selectBrandsHandler}
                itemDeselected={deselectBrandHandler}
                items={props.brandsAndModels}
                selectedItems={selectedBrands}
                allItemsSelected={selectAllBrandsHandler}
                placeholder={placeholderForBrands}
            />
            <GroupedMultiSelect
                itemSelected={selectModelHandler}
                itemDeselected={deselectModelHandler}
                allItemsSelected={selectAllModelsHandler}
                itemChanged={changeSelectedModelHandler}
                items={availableModels}
                selectedItems={selectedModels}
                placeholder={placeholderForModels}
            />
        </Fragment>
    );
}

export default BrandAndModelSearch;