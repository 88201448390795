import ReactGA from "react-ga"

const TRACKING_ID = process.env.REACT_APP_TRACKING_ID;

function init() {
    ReactGA.initialize(TRACKING_ID, { debug: false });
}

function sendEvent(payload) {
    ReactGA.event(payload)
}

function sendPageview(path) {
    ReactGA.set({ page: path })
    ReactGA.pageview(path)
}

export default {
    init,
    sendEvent,
    sendPageview,
}
