import React from "react";
import carImage from "../../assets/images/content/glowna_foto.png";
import closeIcon from "../../assets/images/icons/close.svg";
import {Icon, Intent, Position, Tooltip} from "@blueprintjs/core";
import {IconNames} from "@blueprintjs/icons";

function SoldOfferListItem(props){

    let title = "Sprzedano";
    let temp = "Pojazd został sprzedany.";

    const deleteSoldVehicleHandler = () => {
        let retrievedVehicles = localStorage.getItem('favouriteVehicles');
        let retrievedVehiclesToArray = JSON.parse(retrievedVehicles);
        let offerId = props.vehicleId;
        retrievedVehiclesToArray = retrievedVehiclesToArray.filter( r => r !== offerId.toString());
        let afterEliminatedDuplicates=[...new Set(retrievedVehiclesToArray)];
        localStorage.setItem('favouriteVehicles', JSON.stringify(afterEliminatedDuplicates));
        window.location.href = "ulubione";
    }

    return (
        <div className={"undefined offer"}>
            <div className="close-icon-sold-offer">
                <Icon icon={IconNames.CROSS} iconSize={Icon.SIZE_LARGE} intent={Intent.PRIMARY} onClick={deleteSoldVehicleHandler} />
            </div>
            <div className="offer-top">
                <span className="offer-title" >{title}
                </span>
                <span className="offer-points">{temp}</span>
                <br />
            </div>
            <div className="offer-img">
                <div >
                    <img src={carImage} alt={title}/>
                </div>
            </div>
            <div className="offer-list-view flex">
            </div>

            <div className="offer-bottom flex">
                            <span className="full-price">
                            </span>

            </div>
        </div>
    );
}

export default SoldOfferListItem;