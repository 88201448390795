import React, {Fragment} from "react";
import {Card, Elevation} from "@blueprintjs/core";
import withFormErrorHandler from "../../Reservation/withFormErrorHandler";
import PersonBasicDataForm from "../../Reservation/BasicData/Person/PersonBasicDataForm";
import PersonAddressFormForCredit from "./Person/PersonAddressFormForCredit";
import CompanyBasicDataFormForCredit from "./CompanyBasicDataFormForCredit";

function StepForBasicDataForCredit(props) {

    const companyChangeHandler = company => props.onChange({...props.proposal, company: company});
    const personChangeHandler = person => props.onChange({
        ...props.proposal,
        representatives: [person]
    });

    const onNextClickHandler = () => {
        props.onCheckErrorHandler(() => (props.onNextClick()));
    }

    let formFields = props.isCompany ? (
        <CompanyBasicDataFormForCredit
            formValidator={props.formValidator}
            company={props.proposal.company}
            onChange={companyChangeHandler}/>
    ) : (
        <Fragment>
            <PersonBasicDataForm
                formValidator={props.formValidator}
                person={props.proposal.representatives[0]}
                onChange={personChangeHandler}/>
            <PersonAddressFormForCredit
                formValidator={props.formValidator}
                person={props.proposal.representatives[0]}
                onChange={personChangeHandler}/>
        </Fragment>
    );

    return (
        <Fragment>

            <div className="config-modal-body">

                <form className="order-form">
                    <Card elevation={Elevation.ONE} className="form-section">

                        {formFields}

                    </Card>
                </form>
                <div className="clearfix"></div>

            </div>


            <div className="config-modal-footer">

                <div className="flex">
                    <button type="button" className="back" onClick={props.onBackClick}><i
                        className="fa fa-angle-left"></i>Wstecz
                    </button>
                    <button type="button" onClick={onNextClickHandler}>Dalej</button>
                </div>

            </div>
        </Fragment>
    );
}

export default withFormErrorHandler(StepForBasicDataForCredit);