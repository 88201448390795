import React, {Fragment,useContext, useEffect, useState} from "react";
import {goVehisService} from "../../utils/axios";
import {sortTypes} from "./SortTypes";
import VehicleOfferListItem from "../../components/VehicleOfferListItem/VehicleOfferListItem";
import {useHistory, withRouter} from "react-router";
import {CustomerTypeContext} from "../../context/customer-type-context";
import ConvertUtils from "../../utils/ConvertUtils";
import SoldOfferListItem from "../../components/VehicleOfferListItem/SoldOfferListItem";
import {Link} from 'react-router-dom';
import UrlDecorator from "../../utils/UrlDecorator";
import {FavouriteVehiclesContext} from "../../context/favourite-vehicles-context";

function FavouriteList(props) {

    const initialSearchParams = () => {
        const urlSearchParams = new URLSearchParams();

        if (urlSearchParams.get("sortingType") == null) {
            urlSearchParams.set("sortingType", sortTypes[0].value);
        }

        let vehicleId = urlSearchParams.get('vehicleId');
        if(vehicleId) {
            localStorage.setItem('favouriteVehicles', JSON.stringify(vehicleId.split(',')));
        } else {
            let vehicleIds = getVehicleIdFromLocalStorage();

            if(vehicleIds !== null && vehicleIds.length > 0) {
                urlSearchParams.set('vehicleId', vehicleIds);
                UrlDecorator.addFavouriteVehicles([...new Set(vehicleIds)]);
            } else {
                urlSearchParams.set('vehicleId', null);
            }
        }
        return urlSearchParams;
    }

    const [offers, setOffers] = useState([]);
    const [customerType] = useContext(CustomerTypeContext);
    const amountOfVehicles = useContext(FavouriteVehiclesContext);

    const limit = 100;

    useEffect(() => {
        addVehiclesFromCurrentUrl()
    }, [])

    useEffect(() => {
        refreshVehicles()
    }, [props.location.search, localStorage.getItem('favouriteVehicles')])

    let offerItems = offers.map(offer => (
            <VehicleOfferListItem
                showRibbons={true}
                key={offer.uuid}
                url={ConvertUtils.stringToUrl(offer.brandName + "/" + offer.versionName + "/" + offer.vehicleIds[0])}
                offer={offer}
                deleteButton={true}
                refreshVehicles={() => refreshVehicles()}
            />
        )
    );

    const getVehicleIdFromLocalStorage = () => {
        let retrievedVehicles = localStorage.getItem('favouriteVehicles');
        let retrievedVehiclesToArray = JSON.parse(retrievedVehicles);
        return retrievedVehiclesToArray ? retrievedVehiclesToArray : [];
    }

    const getOffersIds = () => {
        let offerIds = [];
        for(let i=0; i < offers.length; i++) {
            offerIds.push(offers[i].vehicleIds[0].toString());
        }
        return offerIds;
    }

    const parseOfferItems = () => {

        let retrievedVehiclesToArray = getVehicleIdFromLocalStorage();
        if(offers !== null && retrievedVehiclesToArray !== null) {
            if(offers.length < retrievedVehiclesToArray.length) {

                let tempOffers = getOffersIds();

                let difference = retrievedVehiclesToArray.filter( x => !tempOffers.includes(x));

                let result = difference.map(offer => (
                    <SoldOfferListItem
                        key={offer}
                        vehicleId={offer}
                    />
                ))

                let afterEliminateDuplicates=[...new Set(retrievedVehiclesToArray.filter( r => r !== difference))];
                localStorage.setItem('favouriteVehicles', JSON.stringify(afterEliminateDuplicates));

                return result;
            }
        }
    }

    const areThereNoOffers = () => {
        return ((offers === null || offers.length === 0) && parseOfferItems() === undefined)
    }

    if (areThereNoOffers()) {
        offerItems = (
            <h4>Nie dodano żadnego pojazdu do ulubionych.<br/>
                <Link to="/lista-ofert" >Zobacz wszystkie oferty</Link>
            </h4>
        );
    }

    const refreshVehicles = () => {

        if(localStorage.getItem('favouriteVehicles') === '[]'){
            setOffers([])
            amountOfVehicles.setAmount(0);
        }else{

            let urlSearchParams = initialSearchParams();
            urlSearchParams.set("start", 0);
            urlSearchParams.set("limit", limit);
            urlSearchParams.set("customerType", customerType);

            goVehisService.get("/vehicles?" + urlSearchParams.toString()).then(response => {
                let receivedOffers = response.data;
                setOffers(receivedOffers);
                amountOfVehicles.setAmount(response.data.length);
            });

        }

    }

    const getVehicleIdsFromCurrentUrl = () => {
        const currentUrlParams = new URLSearchParams(props.location.search)

        return currentUrlParams.get('vehicleId') ? currentUrlParams.get('vehicleId').split(',') : []
    }

    const addVehiclesFromCurrentUrl = () => {
        let vehicleIds = getVehicleIdFromLocalStorage().concat(getVehicleIdsFromCurrentUrl())
        vehicleIds = [...new Set(vehicleIds)]

        localStorage.setItem('favouriteVehicles', JSON.stringify(vehicleIds))
    }

    return (
        <div id="content">
            <div className="container">

                    <div id="offers-list" className={props.viewType}>
                        {offerItems}
                        {parseOfferItems()}
                    </div>
            </div>
        </div>
    );
}

export default withRouter(FavouriteList);