import React, {useContext, useEffect, useState} from "react";
import FormatUtils from "../../../utils/FormatUtils";
import {CustomerTypeContext, isCustomerCompany} from "../../../context/customer-type-context";
import CheckboxButton from "../../../components/CheckboxButton/CheckboxButton";
import LabelWithInfo from "../../../components/LabelWithInfo/LabelWithInfo";

function InsuranceOptions(props) {

    const customerContext = useContext(CustomerTypeContext);

    const [insuranceLength, setInsuranceLength] = useState(props.isIndividual ? 0 : 12);

    useEffect(() => {
        setInsuranceLength(props.insuranceLength);
    },[props.insuranceLength]);

    const insuranceLengthChangeHandler = (newInsuranceLength) => {

        if (insuranceLength === newInsuranceLength) {
            setInsuranceLength(0);
            newInsuranceLength = 0;
        } else {
            setInsuranceLength(newInsuranceLength);
        }

        props.onChange(newInsuranceLength);
    }

    return (
        <div className="form-item form-ubezpieczenie">
            <p>
                <LabelWithInfo infoCode="oc-ac" strongTitle={true}/> {props.isIndividual && " - Wycena indywidualna"}
            </p>
            {props.isIndividual === false && (
            <div className="form-options flex flex-wrap">
                {props.oneYearVisible &&
                <CheckboxButton
                    checked={insuranceLength === 12}
                    title="Na 12 miesięcy"
                    label={"+" + FormatUtils.formatMoney(isCustomerCompany(customerContext) ?
                        props.insuranceFees.ocAcInsuranceFor12MonthsNet : props.insuranceFees.ocAcInsuranceFor12MonthsGross)}
                    onChange={() => insuranceLengthChangeHandler(12)}
                    disabled={props.disabled}
                />
                }
                {props.allMonthsVisible &&
                <CheckboxButton
                    checked={insuranceLength === props.contractLength}
                    title="Na cały okres umowy"
                    label={"+" + FormatUtils.formatMoney(isCustomerCompany(customerContext) ?
                        props.insuranceFees.ocAcInsuranceForAllMonthsNet : props.insuranceFees.ocAcInsuranceForAllMonthsGross)}
                    onChange={() => insuranceLengthChangeHandler(props.contractLength)}
                    disabled={props.disabled}
                />
                }
            </div>
            )}
        </div>
    );
}

export default InsuranceOptions;