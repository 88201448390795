import React from "react";

export const CustomerTypeContext = React.createContext();

/**
 * Checks if customer in context is company
 * @param customerContext customer context array
 * @returns {boolean}
 */
export const isCustomerCompany = (customerContext) => {

    return customerContext[0] === customerContext[2].company;
}