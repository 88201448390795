class MathUtils{
    static valueOfPercentage(baseValue, percentage, roundTo = 0){
        let value = Math.round(baseValue * percentage / 100);

        if(roundTo > 0){
            value = Math.round(value/roundTo) * roundTo;
        }

        return value;
    }

    static percentageOfValue(baseValue, valueForPercentage){
        baseValue = Math.ceil(baseValue);

        return (Math.round((valueForPercentage/baseValue)*100*100000) / 100000);
    }

    static calculateOfferDiscountPercentage(offer) {
        const basicPrice = offer.basicPriceNet
        const ourPrice = offer.minimumPriceNet

        return Math.floor(((basicPrice - ourPrice) / basicPrice) * 100);
    }

}

export default MathUtils;