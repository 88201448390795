import React from 'react'

class ScrollUtils {

    //Function scrolls window to desired view, it takes defined id of string type as an argument

    //Working examples:
    //ScrollUtils.scrollToViewPosition("phone-input"), ScrollUtils.scrollToViewPosition("contact-form")
    //"phone-input" and "contact-form" exists as a real components ids

    //Not working examples:
    //ScrollUtils.scrollToViewPosition() - function must take an string argument (components id name)
    //ScrollUtils.scrollToViewPosition(250) - function does not take Y or X desired to be scrolled to,
    //it takes only string value of components id

    static scrollToViewPosition(viewId){
        if(document.getElementById(viewId)){
            const scrollView = document.getElementById(viewId);
            scrollView.scrollIntoView({behavior: "smooth"})
        }
    }
}

export default ScrollUtils;