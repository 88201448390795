import React, {useEffect, useState} from 'react';
import Skeleton from "@material-ui/lab/Skeleton";

import ReactHtmlParser from 'react-html-parser';

import goAutoLogo from "../../assets/images/content/logos/goauto_logo.svg";

import {
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
} from "react-share";

import {Link} from "react-router-dom";

function FullBlogPost({blogPost}) {

    return (
        <div>
            <div className="singleMeta flex flex-wrap">
                <div className="metaLabels">
                    <div className="metaLabel">{blogPost ? blogPost.label : <Skeleton variant="text" width={80}/>}</div>
                </div>

                <div className="metaTags">

                    <div className="blogTags">
                        {blogPost ? blogPost.tagLinks.map(tagLink => {
                                return <Link to={`../../blog/tag/${tagLink}`} className="blogTag">{tagLink.replace(/-/g, ' ')}</Link>
                            }) : <Skeleton variant="text" width={180}/>}
                    </div>

                    <div className="metaDate">
                        <span>{blogPost ? `Dodano: ${new Date(blogPost.creationDate).toLocaleString()}` : <Skeleton variant="text" width={70}/>} &nbsp;</span>
                    </div>

                </div>

            </div>

            <div className="clearfix" />

            <div className="singleIntro flex flex-wrap">

                <div className="singleCover">
                    {blogPost ?
                        <img src={blogPost.backgroundUrl ? blogPost.backgroundUrl : goAutoLogo} alt="" /> :
                        <Skeleton variant="rect" width={350} height={263} />
                    }
                    <span className="coverLabel">{blogPost ? `Źródło: ${blogPost.imageSource}` : <Skeleton variant="text" width={50}/>}</span>

                </div>

                <div className="singleIntroText">
                    {blogPost ? <p style={{textAlign: 'justify'}}>{ReactHtmlParser(blogPost.lead)}</p> : <Skeleton variant="text" width={"80%"} height={200} />}
                </div>
            </div>

            <div className="clearfix" />

            <div className="singleContent">
                {blogPost ? <p style={{textAlign: 'justify'}}>{ReactHtmlParser(blogPost.content)}</p> : <Skeleton variant="text" width={"100%"} height={200} />}

                <div className="clearfix" />

                <div className="singleBottom flex flex-wrap">
                    <a className="printThis"onClick={() => window.print()}><i className="fa fa-print"/> Drukuj artykuł</a>
                    <div className="shareThis">
                        <div className="flex">
                            <span>Udostępnij:</span>

                            <a onClick={() => navigator.clipboard.writeText(window.location.href)} className="copyLink">
                                <i className="fa fa-link" /> Kopiuj link
                            </a>

                            <FacebookShareButton url={window.location.href}>
                                <a className="smLink"><i className="fa fa-facebook" /></a>
                            </FacebookShareButton>

                            <TwitterShareButton url={window.location.href}>
                                <a  className="smLink"><i className="fa fa-twitter" /></a>
                            </TwitterShareButton>

                            <LinkedinShareButton url={window.location.href}>
                                <a className="smLink"><i className="fa fa-linkedin" /></a>
                            </LinkedinShareButton>

                            <a className="smLink">
                                <i className="fa fa-envelope-o" onClick={() => window.open(`mailto:?subject=${blogPost ? blogPost.title : "Artykuł"}&body=${window.location.href}`)} />
                            </a>

                        </div>

                    </div>

                </div>

            </div>

        </div>
    )
}

export default FullBlogPost;