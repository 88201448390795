class Validator{

    /**
     * Validate PESEL number by control sum
     * @param {type} value Value of PESEL given by user
     * @returns {Boolean} True if PESEL is valid
     */
    static validatePesel(value) {
        let pesel = value.replace(/[\ \-]/gi, '');
        if (pesel.length != 11) {
            return false;
        } else {
            var steps = new Array(1, 3, 7, 9, 1, 3, 7, 9, 1, 3);
            var sum_nb = 0;
            for (var x = 0; x < 10; x++) {
                sum_nb += steps[x] * pesel[x];
            }
            let sum_m = 10 - sum_nb % 10;
            let sum_c = 0;
            if (sum_m == 10) {
                sum_c = 0;
            } else {
                sum_c = sum_m;
            }
            if (sum_c != pesel[10]) {
                return false;
            }
        }
        return true;
    }

    /**
     * Validate NIP by control sum
     * @param {type} value Value of NIP given by user
     * @returns {Boolean} True if NIP is valid
     */
    static validateNip(value) {
        let verificator_nip = new Array(6, 5, 7, 2, 3, 4, 5, 6, 7);
        let nip = value.replace(/[\ \-]/gi, '');
        if (nip.length != 10) {
            return false;
        } else {
            var n = 0;
            for (var i = 0; i < 9; i++) {
                n += nip[i] * verificator_nip[i];
            }
            n %= 11;
            if (n != nip[9]) {
                return false;
            }
        }
        return true;
    }

    /*checks if string contains anything that is not a number,
    * useful for phone number validators, installment prices etc*/

    static nonNumbersExistsInString(string){
        if(string){
            return /[^0-9]/.test(string);
        }
    }
}

export default Validator;