/**
 * Mapper for values in REGON service. Maps REGON values to dictionary values in GO-auto
 */

class RegonDataMapper {

    static mapVoivodeship(regonVoivodeship) {
        const map = {
            "DOLNOŚLĄSKIE": "DOLNOSLASKIE",
            "KUJAWSKO-POMORSKIE": "KUJAWSKO_POMORSKIE" ,
            "LUBELSKIE": "LUBELSKIE",
            "LUBUSKIE": "LUBUSKIE",
            "ŁÓDZKIE": "LODZKIE",
            "MAŁOPOLSKIE": "MALOPOLSKIE",
            "MAZOWIECKIE": "MAZOWIECKIE",
            "OPOLSKIE": "OPOLSKIE",
            "PODKARPACKIE": "PODKARPACKIE",
            "PODLASKIE": "PODLASKIE",
            "POMORSKIE": "POMORSKIE",
            "ŚLĄSKIE": "SLASKIE",
            "ŚWIĘTOKRZYSKIE": "SWIETOKRZYSKIE",
            "WARMIŃSKO-MAZURSKIE": "WARMINSKO_MAZURSKIE",
            "WIELKOPOLSKIE": "WIELKOPOLSKIE",
            "ZACHODNIOPOMORSKIE": "ZACHODNIOPOMORSKIE"
        }

        return map[regonVoivodeship];
    }

    static mapLegalForm(regonLegalFormCode) {
        const map = {
            "099": "DZIALALNOSC_JEDNOOSOBOWA",
            "019": "SPOLKA_CYWILNA",
            "118": "SPOLKA_JAWNA",
            "117": "SPOLKA_Z_OGRANICZONA_ODPOWIEDZIALNOSCIA",
            "116": "SPOLKA_AKCYJNA",
            "120": "SPOLKA_KOMANDYTOWA",
            "121": "SPOLKA_KOMANDYTOWO_AKCYJNA",
            "115": "SPOLKA_PARTNERSKA",
            "155": "STOWARZYSZENIE",
            "049": "FUNDACJA",
            "124": "PRZEDSIEBIORSTWO_PANSTWOWE"
        }

        return map[regonLegalFormCode];
    }
}

export default RegonDataMapper;