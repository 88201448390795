import React, {Fragment, useEffect, useState} from "react";
import PersonFormFields from "../../PersonFormFields";
import Company from "./Company";
import {leasingOnlineService} from "../../../../utils/axios";
import RepresentativesToChooseDialog from "./RepresentativesToChooseDialog";

function RepresentativeList(props) {

    let emptyRepresentative = {
        "pesel": "",
        "email": "",
        "phoneNumber": "",
        "location": "",
        "firstName": "",
        "lastName": "",
        "identityCardType": "ID_CARD",
        "identityCardNumber": "",
        "identityCardExpirationDate": null,
        "identityCardAuthority": "",
        "maritalStatus": null,
        "propertySeparation": null,
        "address": "",
        "postalCode": "",
        "spouse": null
    };



    const company = new Company(props.company);

    const minRepresentativeCount = company.isSpolkaCywilna() ? 2 : 1;

    const [representatives, setRepresentatives] = useState(props.representatives);
    const [representativesToChoose, setRepresentativesToChoose] = useState([]);
    const [isOpenRepresentativesDialog, setIsOpenRepresentativesDialog] = useState();


    const {onChange} = props;

    useEffect(() => {
        let representativesToChange = representatives.map(representative => ({...representative, companyNip: props.company.nip}))

        onChange(representativesToChange);
    }, [representatives]);

    function isRepresentativesNotSet(){
        return props.company.nip !== props.representatives[0].companyNip;
    }

    const chooseRepresentativesHandler = (selectedRepresenatives) => {
        let newRepresentatives = selectedRepresenatives.map(representative => ({
            ...emptyRepresentative,
            firstName: representative.firstName,
            lastName: representative.lastName
        }));

        if(newRepresentatives.length > 0){
            newRepresentatives[0].phoneNumber = props.representatives[0].phoneNumber;
            newRepresentatives[0].email = props.representatives[0].email;
        }

        setRepresentatives(newRepresentatives);
    };

    useEffect(() => {
        if(isRepresentativesNotSet()) {
            let newRepresentatives = [];

            for (let i = 0; i < minRepresentativeCount; i++) {

                if(i === 0){
                    newRepresentatives.push(props.representatives[0]);
                } else {
                    newRepresentatives.push(emptyRepresentative);
                }
            }
            setRepresentatives(newRepresentatives);
        }
    }, [minRepresentativeCount]);



    useEffect(() => {
        if (props.company.nip && props.company.legalForm && isRepresentativesNotSet()) {
            leasingOnlineService("getRepresentatives?nip=" + props.company.nip + "&legalForm=" + company.getLegalFormCode())
                .then(response => {
                    let representativeData = response.data;

                    if (representativeData.length === 1) {
                        setRepresentatives([{
                            ...props.representatives[0],
                            firstName: representativeData[0].firstName,
                            lastName: representativeData[0].lastName
                        }]);
                    } else if (representativeData.length > 1) {
                        setRepresentativesToChoose(representativeData);
                        setIsOpenRepresentativesDialog(true);
                    }
                });
        }

        setRepresentativesToChoose([]);
    }, []);

    const personAddHandler = () => setRepresentatives(prevState => [...prevState, emptyRepresentative]);

    const personChangeHandler = (person, index) => {
        let updatedRepresentatives = [...representatives];
        updatedRepresentatives[index] = person;

        setRepresentatives(updatedRepresentatives);
    };

    const personRemoveHandler = (index) => {
        let updatedRepresentatives = [...representatives];
        updatedRepresentatives.splice(index, 1);

        setRepresentatives(updatedRepresentatives);
        if(representativesToChoose.length > 0) {
            setRepresentativesToChoose(updatedRepresentatives);
        }
    };

    let representativeItems = representatives.map((representative, index) => {
        return (
            <PersonFormFields
                key={index}
                title={index + 1 + ". reprezentant"}
                person={representative}
                showMaritalStatus={company.isCompanyIndividual()}
                canRemove={index + 1 > minRepresentativeCount}
                index={index}
                onChange={personChangeHandler}
                onRemove={personRemoveHandler}
                formValidator={props.formValidator}
            />
        );
    });

    let addNewButton = null;
        if (representativesToChoose.length > 1) {
            addNewButton = (<button type="button"
                                    className="add-representative-button gold"
                                    onClick={() => setIsOpenRepresentativesDialog(true)}>
                Wybierz reprezentantów</button>);
        } else if (!company.isCompanyIndividual()) {
            addNewButton = (<button type="button" className="add-representative-button gold" onClick={personAddHandler}>
                Dodaj kolejnego reprezentanta</button>);
        }


    return (
        <Fragment>
            {representativeItems}
            {addNewButton}
            <RepresentativesToChooseDialog
                isOpen={isOpenRepresentativesDialog}
                items={representativesToChoose}
                onClose={() => setIsOpenRepresentativesDialog(false)}
                onChoose={chooseRepresentativesHandler}
            />
        </Fragment>
    );
}

export default RepresentativeList;