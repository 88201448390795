import React, {useEffect, useState} from "react";
import {cmsService} from "../../utils/axios";
import {Collapse} from "@blueprintjs/core";
import {Link} from "react-router-dom";

function FAQSection() {

    const [faqElements, setFaqElements] = useState([]);

    useEffect(() => {
        cmsService.get("/FAQElement?OnHomePage=1&fields=ID,Question,Answer")
            .then(response => {
                let rawFaqElements = [...response.data.items];

                rawFaqElements = rawFaqElements.map(element => {
                    return {
                        ...element,
                        showAnswer: false
                    }
                })

                setFaqElements(rawFaqElements);
            })
    },[]);

    const clickFaqQuestionHandler = (event, id) => {

        let clickedFaqQuestion = faqElements.filter(element => (element.ID === id))[0];
        let indexOfClickedFaqElement = faqElements.indexOf(clickedFaqQuestion);

        let updatedFaqElements = [...faqElements];
        updatedFaqElements[indexOfClickedFaqElement] = {...clickedFaqQuestion, showAnswer: !clickedFaqQuestion.showAnswer};

        setFaqElements(updatedFaqElements);
    }

    const faqItems = faqElements.map(faqElement => (
        <div className="faq-item" key={faqElement.ID}>
            <div className="faq-question" onClick={(event) => clickFaqQuestionHandler(event, faqElement.ID)}>
                <p>{faqElement.Question}</p>
            </div>
            <Collapse isOpen={faqElement.showAnswer}>
                <div className="faq-answer" style={{display: "block"}} >
                    <p dangerouslySetInnerHTML={{__html: faqElement.Answer}}/>
                    <p>Chcesz wiedzieć więcej? <Link to="/kontakt">Skontaktuj się z nami</Link></p>
                </div>
            </Collapse>
        </div>
    ));

    if (faqElements.length > 0) {
        return (
            <div id="faq">
                <h3 className="center">Częste pytania</h3>
                <div className="container">
                    <div className="faq-items">
                        {faqItems}

                    </div>
                </div>
            </div>
        );

    }

    return null;
}

export default FAQSection;