import React from 'react'
import MathUtils from "./MathUtils";

class DiscountPercentageRibbonFactory {
    static styleClassName = "undefined_class_name";
    static offer = "undefined_offer";
    static isCompany = false;

    static create() {
        let discountPercentage, discountPercentageString;

        discountPercentage = this.calculateDiscountPercentage();

        return (
            <div className={this.styleClassName}>
                <p>OFERTA SPECJALNA</p>
            </div>
        )
    }

    static calculateDiscountPercentage() {
        if (this.offer.discount) {
            return this.offer.discount;
        } else {
            return MathUtils.calculateOfferDiscountPercentage(this.offer);
        }
    }

    static setStyleClassName(styleClassName) {
        if (styleClassName) {
            this.styleClassName = styleClassName;
        }
    }

    static setOffer(offer) {
        if (offer) {
            this.offer = offer;
        }
    }

    static setIsCompany(isCompany) {
        if (isCompany) {
            this.isCompany = isCompany;
        }
    }

    static getStyleClassName() {
        return this.styleClassName;
    }

    static getOffer() {
        return this.offer;
    }

}

export default DiscountPercentageRibbonFactory;