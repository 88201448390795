import React, {useContext, useEffect, useState} from "react";
import {goVehisService} from "../../utils/axios";
import AppToaster from "../AppToaster";
import {SellerContext} from "../../context/seller-context";
import SellerCodeReader from "../../utils/SellerCodeReader";
import Validator from "../../utils/Validator";
import ScrollUtils from "../../utils/ScrollUtils";
import analyitics from "../../utils/analytics";
import {Icon} from "@blueprintjs/core";
import phoneIcon from "../../assets/images/icons/phone.svg";

function NotFoundContactForm(props) {

    const seller = useContext(SellerContext);
    let sellerCodeReader = new SellerCodeReader();

    const [contactForm, setContactForm] = useState({
        name: "",
        email: "",
        phone: "",
        message: "",
        agreement: false,
        sourceUrl: window.location.href,
        sellerEmail: seller.email,
        sellerPhone: seller.phone,
        sellerCode: sellerCodeReader.readSellerCode(),
    });

    useEffect(() => {
        setContactForm(prevState => (
            {
                ...prevState,
                sellerEmail: seller.email,
                sellerPhone: seller.phone,
                sellerCode: sellerCodeReader.readSellerCode(),
            }
        ));
    }, [seller])

    useEffect(() => {
        if(Validator.nonNumbersExistsInString(contactForm.phone)){
            setContactForm(prevState => ({
                    ...prevState,
                    phone: contactForm.phone.slice(0, -1)
                }
            ));
        }
    }, [contactForm.phone])

    const valueChangeHandler = (event) => {
        const {name, value} = event.target;

        setContactForm(prevState => ({
                ...prevState,
                [name]: value
            })
        );
    }

    const agreementChangeHandler = (event) => {
        const checked = event.target.checked;
        setContactForm(prevState => ({
                ...prevState,
                agreement: checked
            }
        ));
    }

    const phoneClickHandler = () => {
        analyitics.sendEvent({
            category: 'Telefon',
            action: 'Klikniecie',
            label: 'Kliknieto w nr telefonu',
            value: 1
        });
    }

    const submitFormHandler = (event) => {
        event.preventDefault();
        let contactFormData = {...contactForm};
        let additionalMessage = props.additionalMessage ? props.additionalMessage + " - " : "";
        contactFormData.message = additionalMessage + contactForm.message;

        goVehisService.post("/sellers/contact", contactFormData)
            .then(response => {
                AppToaster.show({message: response.data, intent: "primary"})
                if (props.onSuccess) {
                    analyitics.sendEvent({
                        category: 'Kontakt',
                        action: 'Wysłanie wiadomości'
                    });

                    props.onSuccess();
                }
            })
            .catch(error => {
                AppToaster.show({message: "Ups! Wystąpił jakiś błąd. Spróbuj ponownie.", intent: "danger"})
            })
    }

    return (
        <React.Fragment>

            <div id="contact-form" style={{marginTop: "-20px", marginBottom:"-5px"}}>

                <form onSubmit={submitFormHandler}>

                    <label htmlFor="message">
                        <span>Opis poszukiwanego samochodu</span>
                        <textarea required={true} cols="30" rows="5"
                                  name="message"
                                  value={contactForm.message}
                                  onChange={valueChangeHandler}
                                  onClick={() => ScrollUtils.scrollToViewPosition("contact-form")}
                                  placeholder="Wpisz treść zapytania...">
                        </textarea>
                    </label>

                    <label htmlFor="name">
                        <span>Imię i nazwisko</span>
                        <input required={true} type="text"
                               name="name"
                               value={contactForm.name}
                               onChange={valueChangeHandler}
                               onClick={() => ScrollUtils.scrollToViewPosition("contact-form")}
                               placeholder="Wpisz imię i nazwisko..."/>
                    </label>

                    <label htmlFor="email">
                        <span>Adres e-mail</span>
                        <input required={true} type="email"
                               name="email"
                               value={contactForm.email}
                               onChange={valueChangeHandler}
                               onClick={() => ScrollUtils.scrollToViewPosition("contact-form")}
                               placeholder="Wpisz poprawny adres email..."/>
                    </label>

                    <label htmlFor="phone">
                        <span>Numer telefonu</span>
                        <input required={true} type="text"
                               name="phone"
                               minLength="9"
                               maxLength="9"
                               pattern="[0-9]{9}"
                               value={contactForm.phone}
                               onChange={valueChangeHandler}
                               onClick={() => ScrollUtils.scrollToViewPosition("contact-form")}
                               placeholder="Np. 123456789 (9 cyfr)"/>
                    </label>

                    <div id="phone-not-found-form">
                        <div id="phone-not-found">
                            <a href={"tel:" + seller.phone} className="flex" onClick={phoneClickHandler}>
                                <span>{"lub zadzwoń: "+seller.phone}</span>
                                <img src={phoneIcon} alt=""/>
                            </a>
                        </div>
                        <span>Pracujemy w dni robocze od 8:00 do 20:00</span>
                    </div>

                    <label htmlFor="agreement" className="rodo-checkbox-wrapper">
                        <input required={true} type="checkbox" name="agreement" id="rodo-checkbox"
                               value={contactForm.agreement}
                               onChange={agreementChangeHandler}/>
                        <span style={{textAlign:"left"}}>Wyrażam zgodę na przetwarzanie przez GO LEASING Sp. z o.o., ul. Towarowa 9F lok.49, 10-416 Olsztyn,
                            powyższych danych osobowych w celu umożliwienia kontaktu drogą telefoniczną lub elektroniczną.
                            Jestem świadoma/y, że zgoda może być odwołana w każdym czasie poprzez poinformowanie
                            GO LEASING Sp. z o.o. mailowo na adres biuro@go-leasing.pl lub pisemnie na adres podany w zakładce kontakt.
                            <a href="/rodo" target="_blank">Więcej informacji – sprawdź</a>.</span>
                    </label>

                    <button type="submit">Wyślij wiadomość</button>
                </form>

            </div>
        </React.Fragment>
    );
}

export default NotFoundContactForm;