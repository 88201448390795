import React from "react";
import CreditCalculator from "./CreditCalculator";

function CreditInstallmentCalculator(props) {

    const calculationEndPoints = {
        calculatorUrl: "/credit/calculate",
        allowedCalculationParametersUrl: "/credit/allowed-calculation-parameters/?priceNet="
            + props.offer.minimumPriceGross + "&manufactureYear=" + props.offer.manufactureYear
    }

    return (
          <CreditCalculator
              {...props}
              {...calculationEndPoints}
          />
    );
}

export default CreditInstallmentCalculator;