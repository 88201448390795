import React, {Fragment, useEffect} from "react";
import {FormGroup} from "@blueprintjs/core";
import {DateInput} from "@blueprintjs/datetime";
import Dictionary from "../Reservation/Dictionary";
import MomentFormatter from "../../utils/MomentFormatter";
import InputErrorMessage from "../Reservation/InputErrorMessage";
import Validator from "../../utils/Validator";
import PersonFormFields from "../Reservation/BasicData/Person/PersonBasicDataForm";
import FormattedDateInput from "../../components/FormattedDateInput/FormattedDateInput";

function PersonFormFieldsForCredit(props) {

    const MARRIAGE = "MARRIAGE";
    const PROPERTY_SEPARATION_NOT_APPLICABLE = "NOT_APPLICABLE";
    const PROPERTY_SEPARATION_NO = "NO";

    let {formValidator} = props;

    let emptySpouse = {
        "pesel": "",
        "email": "",
        "phoneNumber": "",
        "location": "",
        "firstName": "",
        "lastName": "",
        "identityCardType": "ID_CARD",
        "identityCardNumber": "",
        "identityCardExpirationDate": null,
        "identityCardAuthority": "",
        "address": "",
        "postalCode": "",
        "birthDate": "",
        "nationality": "Polska"
    };


    const personChangeHandler = (event) => {
        let {name, value} = event.target;
        let nameWithoutIndex = name.split("_")[0];

        props.onChange({...props.person, [nameWithoutIndex]: value}, props.index);
    };

    const personRemoveHandler = () => {
        props.onRemove(props.index);
    };

    const birthDateChangeHandler = (date) =>
        props.onChange({...props.person, birthDate: date}, props.index);


    const IDExpirationDateChangeHandler = (date) =>
        props.onChange({...props.person, identityCardExpirationDate: date}, props.index);

    const onChange = props.onChange;

    useEffect(() => {
        if (props.person.propertySeparation) {
            if (props.person.propertySeparation === PROPERTY_SEPARATION_NO) {
                onChange({
                    ...props.person,
                    spouse: {
                        ...emptySpouse,
                        location: props.person.location,
                        address: props.person.address,
                        postalCode: props.person.postalCode
                    }
                }, props.index);
            } else {
                onChange({
                    ...props.person,
                    spouse: null
                }, props.index);
            }
        }
    }, [props.person.propertySeparation]);

    const maritalStatusChangeHandler = (event) => {
        let {value} = event.target;

        if (value === MARRIAGE) {
            props.onChange({
                ...props.person,
                maritalStatus: value,
                propertySeparation: PROPERTY_SEPARATION_NO
            }, props.index);
        } else {
            props.onChange({
                ...props.person,
                maritalStatus: value,
                propertySeparation: PROPERTY_SEPARATION_NOT_APPLICABLE
            }, props.index);
        }
    };

    const spouseChangeHandler = (spouse) => {
        props.onChange({
            ...props.person,
            spouse: {
                ...spouse,
            }
        }, props.index);
    };


    let maritalStatusFields = null;

    if (props.showMaritalStatus) {
        let separationTypeOptions = Dictionary.createDictionaryOptionsValueAsKey(Dictionary.SEPARATION_TYPE);

        // rmeove last option for MARRIAGE marital status
        if (props.person.maritalStatus === MARRIAGE) {
            separationTypeOptions.splice(separationTypeOptions.length - 1, 1);
        }

        let spouseFields = null;
        if (props.person.spouse) {
            spouseFields = (
                <Fragment>
                    <hr/>
                    <PersonFormFields
                        title="Dane małżonka"
                        person={props.person.spouse}
                        showMaritalStatus={false}
                        canRemove={false}
                        index={props.index + 100}
                        onChange={spouseChangeHandler}
                        formValidator={props.formValidator}
                    />
                </Fragment>
            );
        }

        maritalStatusFields = (
            <Fragment>
                <FormGroup
                    className="form-group"
                    label="Stan cywilny"
                    labelFor="maritalStatus"
                >
                    <select className="like-btn"
                            ref={formValidator.register({required: true})}
                            name={"maritalStatus_" + props.index}
                            value={props.person.maritalStatus === null ? "" : props.person.maritalStatus}
                            onChange={maritalStatusChangeHandler}>
                        <option value=""></option>
                        {Dictionary.createDictionaryOptionsValueAsKey(Dictionary.MARITAL_STATUS)}
                    </select>
                    <InputErrorMessage error={formValidator.errors["maritalStatus_" + props.index]}/>
                </FormGroup>


                <FormGroup
                    className="form-group"
                    label="Rozdzielność majątkowa"
                    labelFor="propertySeparation"
                >
                    <select className="like-btn"
                            ref={formValidator.register({required: true})}
                            name={"propertySeparation_" + props.index}
                            disabled={props.person.maritalStatus !== MARRIAGE}
                            value={props.person.propertySeparation}
                            onChange={personChangeHandler}>
                        {separationTypeOptions}
                    </select>
                    <InputErrorMessage error={formValidator.errors["propertySeparation_" + props.index]}/>
                </FormGroup>

                {spouseFields}
            </Fragment>
        );
    }

    let removeButton = null;
    if (props.canRemove) {
        removeButton = (
            <button type="button" className="remove-representative-button gold" onClick={personRemoveHandler}>Usuń</button>);
    }

    let bikStatusField = null;
    if (props.showBikStatus) {
        bikStatusField = (
            <FormGroup
                className="form-group"
                label="Status w bazach BIK/KRD"
                labelFor="bikStatus"
            >
                <select className="like-btn"
                        ref={formValidator.register({required: true})}
                        name="bikStatus"
                        value={props.person.bikStatus === null ? "" : props.person.bikStatus}
                        onChange={personChangeHandler}>
                    <option value=""></option>
                    {Dictionary.createDictionaryOptionsValueAsKey(Dictionary.BIK_KRD_STATUS)}
                </select>
                <InputErrorMessage error={formValidator.errors.bikStatus} />
            </FormGroup>
        );
    }

    const isPESELRequired = () => props.person.nationality === "Polska";
    const isBirthDateRequired = () => props.person.nationality !== "Polska";

    return (
        <Fragment>

            <h6 className="header-with-line">Dane reprezentantów</h6>

            <div className="representative-title">
                {props.index > 0 ? <hr/> : null}
                <strong>{props.title}</strong>
                {removeButton}
            </div>

            <FormGroup
                className="form-group"
                label="Obywatelstwo"
                labelFor="nationality"
            >
                <select className="like-btn"
                        ref={formValidator.register({required: true})}
                        name={"nationality"}
                        value={props.person.nationality}
                        onChange={personChangeHandler}>
                    {Dictionary.createDictionaryOptions(Dictionary.ALL_COUNTRIES)}
                </select>
                <InputErrorMessage error={formValidator.errors["nationality"]}/>
            </FormGroup>

            {isPESELRequired() && <FormGroup
                className={"form-group"}
                label="PESEL"
                labelFor="pesel"
            >
                <input type="text"
                       ref={formValidator.register({
                           required: true,
                           pattern: /[0-9]{11}/,
                           maxLength: 11,
                           validate: value => Validator.validatePesel(value)
                       })}
                       name={"pesel_" + props.index}
                       value={props.person.pesel}
                       onChange={personChangeHandler}/>
                <InputErrorMessage error={formValidator.errors["pesel_" + props.index]}
                                   message="Podaj poprawny numer PESEL"/>
            </FormGroup>}

            {isBirthDateRequired() && <FormGroup
                className="form-group"
                label="Data urodzenia"
                labelFor="birth-date"
            >
                <FormattedDateInput
                    name={"birthDate_" + props.index}
                    showOnlyPast={true}
                    inputRef={formValidator.register({required: true})}
                    value={props.person.birthDate}
                    onChange={birthDateChangeHandler}
                />
                <InputErrorMessage error={formValidator.errors["birthDate_" + props.index]}
                                   message="Wprowadź poprawną datę w formacie rrrr-mm-dd"/>
            </FormGroup>}

            <FormGroup
                className="form-group"
                label="Imię"
                labelFor="firstName"
            >
                <input type="text"
                       ref={formValidator.register({required: true})}
                       name={"firstName_" + props.index}
                       value={props.person.firstName}
                       onChange={personChangeHandler}/>

                <InputErrorMessage error={formValidator.errors["firstName_" + props.index]}/>
            </FormGroup>

            <FormGroup
                className="form-group"
                label="Nazwisko"
                labelFor="lastName"
            >
                <input type="text"
                       ref={formValidator.register({required: true})}
                       name={"lastName_" + props.index}
                       value={props.person.lastName}
                       onChange={personChangeHandler}/>
                <InputErrorMessage error={formValidator.errors["lastName_" + props.index]}/>
            </FormGroup>

            {bikStatusField}

            <FormGroup
                className="form-group"
                label="Rodzaj dowodu tożsamości"
                labelFor="identityCardType"
            >
                <select className="like-btn"
                        ref={formValidator.register({required: true})}
                        name={"identityCardType_" + props.index}
                        value={props.person.identityCardType}
                        onChange={personChangeHandler}>
                    {Dictionary.createDictionaryOptionsValueAsKey(Dictionary.IDENTITY_CARD_TYPE)}
                </select>
                <InputErrorMessage error={formValidator.errors["identityCardType_" + props.index]}/>
            </FormGroup>

            <FormGroup
                className="form-group"
                label="Nr dowodu tożsamości"
                labelFor="identityCardNumber"
            >
                <input type="text"
                       ref={formValidator.register({required: true})}
                       name={"identityCardNumber_" + props.index}
                       value={props.person.identityCardNumber}
                       onChange={personChangeHandler}/>
                <InputErrorMessage error={formValidator.errors["identityCardNumber_" + props.index]}/>
            </FormGroup>
            <FormGroup
                className="form-group"
                label="Data ważności dowodu tożsamości"
                labelFor="identity-card-expiration-date"
            >
                <DateInput
                    {...MomentFormatter.datePickerFormatProps()}
                    popoverProps={{
                        usePortal: false
                    }}
                    inputProps={{
                        inputRef: formValidator.register({required: true}),
                        name: "identityCardExpirationDate_" + props.index
                    }}
                    minDate={new Date()}
                    maxDate={(d => new Date(d.getFullYear() + 100, d.getMonth(), d.getDate()))(new Date())}
                    onChange={IDExpirationDateChangeHandler}
                    placeholder={"Wprowadź datę w formacie rrrr-mm-dd"}
                    value={props.person.identityCardExpirationDate}
                />
                <InputErrorMessage error={formValidator.errors["identityCardExpirationDate_" + props.index]}
                                   message="Wprowadź poprawną datę w formacie rrrr-mm-dd"/>
            </FormGroup>

            <FormGroup
                className="form-group"
                label="Organ wydający dowód tożsamości"
                labelFor="identityCardAuthority"
            >
                <input type="text"
                       ref={formValidator.register({required: true})}
                       name={"identityCardAuthority_" + props.index}
                       value={props.person.identityCardAuthority}
                       onChange={personChangeHandler}/>
                <InputErrorMessage error={formValidator.errors["identityCardAuthority_" + props.index]}/>
            </FormGroup>

            <FormGroup
                className="form-group"
                label="Telefon"
                labelFor="phoneNumber"
            >
                <input type="text"
                       ref={formValidator.register({
                           required: true,
                           pattern: /[0-9]{9}/,
                           maxLength: 9
                       })}
                       name={"phoneNumber_" + props.index}
                       value={props.person.phoneNumber}
                       onChange={personChangeHandler}
                       placeholder="Np. 753421869 (9 cyfr)"/>
                <InputErrorMessage error={formValidator.errors["phoneNumber_" + props.index]}
                                   message="Podaj poprawny numer telefonu (9 cyfr)"/>
            </FormGroup>

            <FormGroup
                className="form-group"
                label="E-mail"
                labelFor="email"
            >
                <input type="email"
                       ref={formValidator.register({required: true})}
                       name={"email_" + props.index}
                       value={props.person.email}
                       onChange={personChangeHandler}/>
                <InputErrorMessage error={formValidator.errors["email_" + props.index]}
                                   message="Podaj poprawny adres e-mail"/>
            </FormGroup>

            <h6 className="header-with-line">Adres zamieszkania</h6>

            <FormGroup
                className="form-group"
                label="Kod pocztowy"
                labelFor="postalCode"
            >
                <input type="text"
                       ref={formValidator.register({required: true})}
                       name={"postalCode_" + props.index}
                       value={props.person.postalCode}
                       onChange={personChangeHandler}/>
                <InputErrorMessage error={formValidator.errors["postalCode_" + props.index]}/>
            </FormGroup>

            <FormGroup
                className="form-group"
                label="Miejscowość"
                labelFor="location"
            >
                <input type="text"
                       ref={formValidator.register({required: true})}
                       name={"location_" + props.index}
                       value={props.person.location}
                       onChange={personChangeHandler}/>
                <InputErrorMessage error={formValidator.errors["location_" + props.index]}/>
            </FormGroup>


            <FormGroup
                className="form-group fill"
                label="Ulica, nr domu, nr lokalu, dodatkowa miejscowość"
                labelFor="address"
            >
                <input type="text"
                       ref={formValidator.register({required: true})}
                       name={"address_" + props.index}
                       value={props.person.address}
                       onChange={personChangeHandler}/>
                <InputErrorMessage error={formValidator.errors["address_" + props.index]}/>
            </FormGroup>

            {maritalStatusFields}

        </Fragment>
    );
}

export default PersonFormFieldsForCredit;