import React, {useContext, useState} from "react";

import backgroundImage from "../../assets/images/content/konfigurator.jpg";
import {CustomerTypeContext, isCustomerCompany} from "../../context/customer-type-context";
import ConfiguratorHeader from "./ConfiguratorHeader";
import StepForCustomerType from "./StepForCustomerType";
import StepForBudget from "./StepForBudget";
import URLSearchParamsConverter from "../../utils/URLSearchParamsConverter";
import StepForGearbox from "./StepForGearbox";
import {useHistory} from "react-router";
import StepForBodyType from "./StepForBodyType";
import MetaTagsManipulator from "../../utils/MetaTagsManipulator";
import analyitics from "../../utils/analytics";

function SearchConfigurator() {

    MetaTagsManipulator.setTitle("Konfigurator wyszukiwania");
    MetaTagsManipulator.setDescription("Konfigurator wyszukiwania");

    let backgroundStyle = {
        background: `url(${backgroundImage}) center center no-repeat`,
        backgroundSize: "cover"
    };

    const customerContext = useContext(CustomerTypeContext);
    const history = useHistory();

    const [searchParameters, setSearchParameters] = useState({
        bodyTypes: [],
        gearboxTypes: [],
        installmentNetFrom: null,
        installmentNetTo: null,
        installmentGrossFrom: null,
        installmentGrossTo: null,
    });

    const budget = () => {

        let budget = {from: 0, to: 0};

        if (isCustomerCompany(customerContext)) {
            budget.from = searchParameters.installmentNetFrom ? searchParameters.installmentNetFrom : 0;
            budget.to = searchParameters.installmentNetTo ? searchParameters.installmentNetTo : 0;

        } else {
            budget.from = searchParameters.installmentGrossFrom ? searchParameters.installmentGrossFrom : 0;
            budget.to = searchParameters.installmentGrossTo ? searchParameters.installmentGrossTo : 0;
        }

        return budget;
    };

    const budgetChangeHandler = (budget) => {
        if (isCustomerCompany(customerContext)) {
            setSearchParameters(prevState => ({
                ...prevState,
                installmentNetFrom: budget.from,
                installmentNetTo: budget.to

            }));
        } else {
            setSearchParameters(prevState => ({
                ...prevState,
                installmentGrossFrom: budget.from,
                installmentGrossTo: budget.to

            }));
        }
    };

    const gearboxChangeHandler = (gearboxType) =>
        setSearchParameters(prevState => ({...prevState, gearboxTypes: [gearboxType]}));

    const bodyTypesSelectedHandler = (selectedBodyTypes) =>
        setSearchParameters(prevState => ({...prevState, bodyTypes: [...selectedBodyTypes]}));

    const [currentStepNumber, setCurrentStepNumber] = useState(1);

    const goToNextStep = () => {
        let step = currentStepNumber + 1;

        analyitics.sendEvent({
            category: 'Konfiguruj',
            action: 'Przejście do kroku ' + step
        });

        setCurrentStepNumber(prevState => prevState + 1);
    }
    const goToPreviousStep = () => setCurrentStepNumber(prevState => prevState > 0 ? prevState - 1 : 0);

    const searchButtonClickHandler = () => {

        analyitics.sendEvent({
            category: 'Konfiguruj',
            action: 'Kliknięcie w Zobacz wybrane'
        });

        history.push({
            pathname: "/lista-ofert",
            search: URLSearchParamsConverter.createURLSearchString(searchParameters)
        });

    }

    let currentStep = null;

    switch (currentStepNumber) {
        case 1:
            currentStep = (<StepForCustomerType onNextClick={goToNextStep}/>);
            break;
        case 2:
            currentStep = (
                <StepForBudget
                    budget={budget()}
                    onNextClick={goToNextStep}
                    onBackClick={goToPreviousStep}
                    onChange={budgetChangeHandler}
                />
            );
            break;
        case 3:
            currentStep = (
                <StepForGearbox
                    gearboxType={searchParameters.gearboxTypes.length > 0 ? searchParameters.gearboxTypes[0] : null}
                    onNextClick={goToNextStep}
                    onBackClick={goToPreviousStep}
                    onChange={gearboxChangeHandler}
                />
            );
            break;
        case 4:
            currentStep = (
                <StepForBodyType
                    bodyTypes={searchParameters.bodyTypes}
                    onSearchClick={searchButtonClickHandler}
                    onBackClick={goToPreviousStep}
                    onChange={bodyTypesSelectedHandler}
                />
            );
            break;
    }

    return (
        <div id="config" style={backgroundStyle}>

            <div id="config-modal">

                <ConfiguratorHeader
                    stepTitles={["Klient", "Rata", "Skrzynia biegów", "Nadwozie"]}
                    currentNumber={currentStepNumber}
                    onStepChange={number => setCurrentStepNumber(number)}/>

                {currentStep}

            </div>

        </div>
    );
}

export default SearchConfigurator;