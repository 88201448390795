import React, {Fragment, useState} from "react";
import {FormGroup} from "@blueprintjs/core";
import {DateInput} from "@blueprintjs/datetime";
import Dictionary from "../../Dictionary";
import MomentFormatter from "../../../../utils/MomentFormatter";
import InputErrorMessage from "../../InputErrorMessage";
import Validator from "../../../../utils/Validator";
import BikKrdTooltip from "../BikKrdTooltip";
import FormattedDateInput from "../../../../components/FormattedDateInput/FormattedDateInput";

function PersonFormFields(props) {

    let {formValidator} = props;

    const personChangeHandler = (event) => {
        let {name, value} = event.target;

        props.onChange({...props.person, [name]: value});
    };

    const isDriverLicenceChangeHandler = (event) => {
        let {name, value} = event.target;
        setIsDriverLicence(value);
    }

    const IDExpirationDateChangeHandler = (date) =>
        props.onChange({...props.person, identityCardExpirationDate: date});

    const birthDateChangeHandler = (date) =>
        props.onChange({...props.person, birthDate: date});

    const driveLicenseExpirationDateChangeHandler = (date) =>
        props.onChange({...props.person, driveLicenseExpirationDate: date});

    const [isDriverLicence, setIsDriverLicence] = useState("");

    const isPESELRequired = () => props.person.nationality === "Polska";
    const isBirthDateRequired = () => props.person.nationality !== "Polska";
    const isDriverLicenceRequired = () => isDriverLicence !== "Nie";

    return (
        <Fragment>

            <FormGroup
                className="form-group"
                label="Obywatelstwo"
                labelFor="nationality"
            >
                <select className="like-btn"
                        ref={formValidator.register({required: true})}
                        name={"nationality"}
                        value={props.person.nationality}
                        onChange={personChangeHandler}>
                    {Dictionary.createDictionaryOptions(Dictionary.ALL_COUNTRIES)}
                </select>
                <InputErrorMessage error={formValidator.errors["nationality"]}/>
            </FormGroup>

            {isPESELRequired() && <FormGroup
                className="form-group"
                label="PESEL"
                labelFor="pesel"
            >
                <input type="text"
                       ref={formValidator.register({
                           required: true,
                           pattern: /[0-9]{11}/,
                           maxLength: 11,
                           validate: value => Validator.validatePesel(value)
                       })}
                       name={"pesel"}
                       value={props.person.pesel}
                       onChange={personChangeHandler}/>
                <InputErrorMessage error={formValidator.errors["pesel"]}
                                   message="Podaj poprawny numer PESEL"/>
            </FormGroup>}

            {isBirthDateRequired() && <FormGroup
                className="form-group"
                label="Data urodzenia"
                labelFor="birth-date"
            >
                <FormattedDateInput
                    name="birthDate"
                    showOnlyPast={true}
                    inputRef={formValidator.register({required: true})}
                    value={props.person.birthDate}
                    onChange={birthDateChangeHandler}
                />
                <InputErrorMessage error={formValidator.errors["birthDate"]}
                                   message="Wprowadź poprawną datę w formacie rrrr-mm-dd"/>
            </FormGroup>}

            <FormGroup
                className="form-group"
                label="Imię"
                labelFor="firstName"
            >
                <input type="text"
                       ref={formValidator.register({required: true})}
                       name={"firstName"}
                       value={props.person.firstName}
                       onChange={personChangeHandler}/>

                <InputErrorMessage error={formValidator.errors["firstName"]}/>
            </FormGroup>

            <FormGroup
                className="form-group"
                label="Nazwisko"
                labelFor="lastName"
            >
                <input type="text"
                       ref={formValidator.register({required: true})}
                       name={"lastName"}
                       value={props.person.lastName}
                       onChange={personChangeHandler}/>
                <InputErrorMessage error={formValidator.errors["lastName"]}/>
            </FormGroup>

            <FormGroup
                className="form-group"
                label="Status w bazach BIK/KRD"
                labelFor="bikStatus"
            >
                <BikKrdTooltip>
                    <select className="like-btn"
                            ref={formValidator.register({required: true})}
                            name="bikStatus"
                            value={props.person.bikStatus === null ? "" : props.person.bikStatus}
                            onChange={personChangeHandler}>
                        <option value=""></option>
                        {Dictionary.createDictionaryOptionsValueAsKey(Dictionary.BIK_KRD_STATUS)}
                    </select>
                </BikKrdTooltip>
                <InputErrorMessage error={formValidator.errors.bikStatus}/>
            </FormGroup>

            <FormGroup
                className="form-group"
                label="Rodzaj dowodu tożsamości"
                labelFor="identityCardType"
            >
                <select className="like-btn"
                        ref={formValidator.register({required: true})}
                        name={"identityCardType"}
                        value={props.person.identityCardType}
                        onChange={personChangeHandler}>
                    {Dictionary.createDictionaryOptionsValueAsKey(Dictionary.IDENTITY_CARD_TYPE)}
                </select>
                <InputErrorMessage error={formValidator.errors["identityCardType"]}/>
            </FormGroup>

            <FormGroup
                className="form-group"
                label="Nr dowodu tożsamości"
                labelFor="identityCardNumber"
            >
                <input type="text"
                       ref={formValidator.register({required: true})}
                       name={"identityCardNumber"}
                       value={props.person.identityCardNumber}
                       onChange={personChangeHandler}/>
                <InputErrorMessage error={formValidator.errors["identityCardNumber"]}/>
            </FormGroup>
            <FormGroup
                className="form-group"
                label="Data ważności dowodu tożsamości"
                labelFor="identity-card-expiration-date"
            >
                <FormattedDateInput
                    name="identityCardExpirationDate"
                    showOnlyFuture={true}
                    inputRef={formValidator.register({required: true})}
                    value={props.person.identityCardExpirationDate}
                    onChange={IDExpirationDateChangeHandler}
                />
                <InputErrorMessage error={formValidator.errors["identityCardExpirationDate"]}
                                   message="Wprowadź poprawną datę w formacie rrrr-mm-dd"/>
            </FormGroup>

            <FormGroup
                className="form-group"
                label="Organ wydający dowód tożsamości"
                labelFor="identityCardAuthority"
            >
                <input type="text"
                       ref={formValidator.register({required: true})}
                       name={"identityCardAuthority"}
                       value={props.person.identityCardAuthority}
                       onChange={personChangeHandler}/>
                <InputErrorMessage error={formValidator.errors["identityCardAuthority"]}/>
            </FormGroup>

            <FormGroup
                className="form-group"
                label="Prawo jazdy"
                labelFor="isDriverLicence"
            >
                    <select className="like-btn"
                            ref={formValidator.register({required: true})}
                            name="isDriverLicence"
                            value={isDriverLicence}
                            onChange={isDriverLicenceChangeHandler}>
                        {Dictionary.createDictionaryOptionsValueAsKey(Dictionary.IS_DRIVING_LICENCE)}
                    </select>
                <InputErrorMessage error={formValidator.errors.isDriverLicence}/>
            </FormGroup>

            {isDriverLicenceRequired() &&
            <FormGroup
                className="form-group"
                label="Nr prawa jazdy"
                labelFor="driveLicenseNumber"
            >
                <input type="text"
                       ref={formValidator.register({required: true})}
                       name={"driveLicenseNumber"}
                       value={props.person.driveLicenseNumber}
                       onChange={personChangeHandler}/>
                <InputErrorMessage error={formValidator.errors.driveLicenseNumber}/>
            </FormGroup>}
            {isDriverLicenceRequired() &&
            <FormGroup
                className="form-group"
                label="Data ważności prawa jazdy"
                labelFor="driveLicenseExpirationDate"
            >
                <FormattedDateInput
                    name="driveLicenseExpirationDate"
                    inputRef={formValidator.register({required: true})}
                    showOnlyFuture={true}
                    value={props.person.driveLicenseExpirationDate}
                    onChange={driveLicenseExpirationDateChangeHandler}
                />
                <InputErrorMessage error={formValidator.errors["driveLicenseExpirationDate"]}
                                   message="Wprowadź poprawną datę w formacie rrrr-mm-dd"/>
            </FormGroup>}

            <FormGroup
                className="form-group"
                label="Telefon"
                labelFor="phoneNumber"
            >
                <input type="text"
                       ref={formValidator.register({
                           required: true,
                           pattern: /[0-9]{9}/,
                           maxLength: 9
                       })}
                       name={"phoneNumber"}
                       value={props.person.phoneNumber}
                       onChange={personChangeHandler}
                       placeholder="Np. 753421869 (9 cyfr)"/>
                <InputErrorMessage error={formValidator.errors["phoneNumber"]}
                                   message="Podaj poprawny numer telefonu (9 cyfr)"/>
            </FormGroup>

            <FormGroup
                className="form-group"
                label="E-mail"
                labelFor="email"
            >
                <input type="email"
                       ref={formValidator.register({required: true})}
                       name={"email"}
                       value={props.person.email}
                       onChange={personChangeHandler}/>
                <InputErrorMessage error={formValidator.errors["email"]}
                                   message="Podaj poprawny adres e-mail"/>
            </FormGroup>

        </Fragment>
    );
}

export default PersonFormFields;