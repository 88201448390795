import React, {Fragment, useEffect} from "react";
import {FormGroup} from "@blueprintjs/core";
import Dictionary from "../../../Reservation/Dictionary";
import InputErrorMessage from "../../../Reservation/InputErrorMessage";
import PersonFormFields from "../../../Reservation/PersonFormFields";
import PersonFormFieldsForCredit from "../../PersonFormFieldsForCredit";

function PersonAddressFormForCredit(props) {

    const MARRIAGE = "MARRIAGE";
    const PROPERTY_SEPARATION_NOT_APPLICABLE = "NOT_APPLICABLE";
    const PROPERTY_SEPARATION_NO = "NO";

    let {formValidator} = props;

    let emptySpouse = {
        "pesel": "",
        "email": "",
        "phoneNumber": "",
        "location": "",
        "firstName": "",
        "lastName": "",
        "identityCardType": "ID_CARD",
        "identityCardNumber": "",
        "identityCardExpirationDate": null,
        "identityCardAuthority": "",
        "address": "",
        "postalCode": "",
        "nationality": "Polska",
        "birthDate": "",
    };

    const personChangeHandler = (event) => {
        let {name, value} = event.target;

        props.onChange({...props.person, [name]: value});
    };

    const onChange = props.onChange;

    useEffect(() => {
        if (props.person.propertySeparation) {
            if (props.person.propertySeparation === PROPERTY_SEPARATION_NO) {
                onChange({
                    ...props.person,
                    spouse: {
                        ...emptySpouse,
                        location: props.person.location,
                        address: props.person.address,
                        postalCode: props.person.postalCode
                    }
                });
            } else {
                onChange({
                    ...props.person,
                    spouse: null
                });
            }
        }
    }, [props.person.propertySeparation]);

    const maritalStatusChangeHandler = (event) => {
        let {value} = event.target;

        if (value === MARRIAGE) {
            props.onChange({
                ...props.person,
                maritalStatus: value,
                propertySeparation: PROPERTY_SEPARATION_NO
            });
        } else {
            props.onChange({
                ...props.person,
                maritalStatus: value,
                propertySeparation: PROPERTY_SEPARATION_NOT_APPLICABLE
            });
        }
    };

    const spouseChangeHandler = (spouse) => {
        props.onChange({
            ...props.person,
            spouse: {
                ...spouse,
            }
        });
    };

    let separationTypeOptions = Dictionary.createDictionaryOptionsValueAsKey(Dictionary.SEPARATION_TYPE);

    // remove last option for MARRIAGE marital status
    if (props.person.maritalStatus === MARRIAGE) {
        separationTypeOptions.splice(separationTypeOptions.length - 1, 1);
    }

    let spouseFields = null;
    if (props.person.spouse) {
        spouseFields = (
            <Fragment>
                <hr/>
                <PersonFormFieldsForCredit
                    title="Dane małżonka"
                    person={props.person.spouse}
                    showMaritalStatus={false}
                    canRemove={false}
                    index={100}
                    onChange={spouseChangeHandler}
                    formValidator={props.formValidator}
                />
            </Fragment>
        );
    }


    return (
        <Fragment>

            <h6 className="header-with-line">Adres zamieszkania</h6>

            <FormGroup
                className="form-group"
                label="Kod pocztowy"
                labelFor="postalCode"
            >
                <input type="text"
                       ref={formValidator.register({required: true})}
                       name={"postalCode"}
                       value={props.person.postalCode}
                       onChange={personChangeHandler}/>
                <InputErrorMessage error={formValidator.errors["postalCode"]}/>
            </FormGroup>

            <FormGroup
                className="form-group"
                label="Miejscowość"
                labelFor="location"
            >
                <input type="text"
                       ref={formValidator.register({required: true})}
                       name={"location"}
                       value={props.person.location}
                       onChange={personChangeHandler}/>
                <InputErrorMessage error={formValidator.errors["location"]}/>
            </FormGroup>


            <FormGroup
                className="form-group fill"
                label="Ulica, nr domu, nr lokalu, dodatkowa miejscowość"
                labelFor="address"
            >
                <input type="text"
                       ref={formValidator.register({required: true})}
                       name={"address"}
                       value={props.person.address}
                       onChange={personChangeHandler}/>
                <InputErrorMessage error={formValidator.errors["address"]}/>
            </FormGroup>

            <FormGroup
                className="form-group"
                label="Stan cywilny"
                labelFor="maritalStatus"
            >
                <select className="like-btn"
                        ref={formValidator.register({required: true})}
                        name={"maritalStatus"}
                        value={props.person.maritalStatus === null ? "" : props.person.maritalStatus}
                        onChange={maritalStatusChangeHandler}>
                    <option value=""></option>
                    {Dictionary.createDictionaryOptionsValueAsKey(Dictionary.MARITAL_STATUS)}
                </select>
                <InputErrorMessage error={formValidator.errors["maritalStatus"]}/>
            </FormGroup>


            <FormGroup
                className="form-group"
                label="Rozdzielność majątkowa"
                labelFor="propertySeparation"
            >
                <select className="like-btn"
                        ref={formValidator.register({required: true})}
                        name={"propertySeparation"}
                        disabled={props.person.maritalStatus !== MARRIAGE}
                        value={props.person.propertySeparation}
                        onChange={personChangeHandler}>
                    {separationTypeOptions}
                </select>
                <InputErrorMessage error={formValidator.errors["propertySeparation"]}/>
            </FormGroup>

            {spouseFields}

        </Fragment>
    );
}

export default PersonAddressFormForCredit;