import React, {Fragment, useState} from "react";
import {FormGroup, NumericInput, Position, Radio, RadioGroup, Tag, Tooltip} from "@blueprintjs/core";
import Dictionary from "../../Dictionary";
import InputErrorMessage from "../../InputErrorMessage";
import Validator from "../../../../utils/Validator";
import FormattedDateInput from "../../../../components/FormattedDateInput/FormattedDateInput";
import CurrencyInput from "../../../../components/CurrencyInput/CurrencyInput";

function PersonAdditionalDataForm(props) {

    let {formValidator} = props;

    const [contractLengthType, setContractLengthType] = useState("limited");

    const contractLengthTypeChangeHandler = event => setContractLengthType(event.currentTarget.value);

    const proposalChangeHandler = (event) => {
        let {name, value} = event.target;

        props.onChange({...props.proposal, [name]: value});
    };


    const employerChangeHandler = (event) => {
        let {name, value} = event.target;

        props.onChange({
            ...props.proposal,
            employer: {...props.proposal.employer, [name]: value}
        });
    };

    const personChangeHandler = (event) => {
        let {name, value} = event.target;

        props.onChange({
            ...props.proposal,
            representatives: [{...props.proposal.representatives[0], [name]: value}]
        });
    };

    const contractDatesChangeHandler = (name, date) =>
        props.onChange({
            ...props.proposal,
            [name]: date
        });

    const currencyChangeHandler = (name, value) =>
        props.onChange({
            ...props.proposal,
            [name]: value
        });


    let employerTypeLabel = "zakładu pracy";

    const contractFromDateField = (
        <FormattedDateInput
            name="contractFrom"
            showOnlyPast={true}
            inputRef={formValidator.register({required: true})}
            value={props.proposal.contractFrom}
            onChange={date => contractDatesChangeHandler("contractFrom", date)}
        />
    );

    const contractToDateField = (
        <FormattedDateInput
            name="contractTo"
            showOnlyFuture={true}
            inputRef={formValidator.register({required: true})}
            value={props.proposal.contractTo}
            onChange={date => contractDatesChangeHandler("contractTo", date)}
        />
    );

    let contractLengthFields = (<Fragment>

        <RadioGroup
            className="form-group "
            label="Umowa o pracę"
            onChange={contractLengthTypeChangeHandler}
            selectedValue={contractLengthType}
        >
            <Radio label="na czas określony" value="limited"/>
            <Radio label="na czas nieokreślony" value="unlimited"/>

        </RadioGroup>
        {contractLengthType === "limited" ?
            <FormGroup className="form-group">
                <FormGroup
                    className="form-group fill"
                    labelFor="contractFrom"
                    label="Data zawarcia umowy:">
                    {contractFromDateField}
                    <InputErrorMessage error={formValidator.errors["contractFrom"]}/>
                </FormGroup>

                <FormGroup
                    className="form-group fill"
                    labelFor="contractTo"
                    label="Data zakończenia umowy:">
                    {contractToDateField}
                    <InputErrorMessage error={formValidator.errors["contractTo"]}/>
                </FormGroup>

            </FormGroup>
            :
            <FormGroup
                className="form-group"
                labelFor="contractFrom"
                label="Data zawarcia umowy:"
            >
                {contractFromDateField}
                <InputErrorMessage error={formValidator.errors["contractFrom"]}/>
            </FormGroup>
        }

    </Fragment>);

    if (props.proposal.employmentForm === "MANDATORY_CONTRACT") {
        employerTypeLabel = "zleceniodawcy";

        contractLengthFields = (<Fragment><FormGroup
            className="form-group"
            label="Data zawarcia umowy:"
            labelFor="contractFrom"
        >
            {contractFromDateField}
            <InputErrorMessage error={formValidator.errors["contractFrom"]}/>
        </FormGroup>
            <FormGroup className="form-group"></FormGroup></Fragment>);
    }


    return (
        <Fragment>

            <h6 className="header-with-line">Dane dotyczące zatrudnienia</h6>

            <FormGroup
                className="form-group"
                label="Forma zatrudnienia"
                labelFor="employmentForm"
            >
                <select className="like-btn"
                        ref={formValidator.register({required: true})}
                        name="employmentForm"
                        value={props.proposal.employmentForm}
                        onChange={proposalChangeHandler}>
                    {Dictionary.createDictionaryOptionsValueAsKey(Dictionary.EMPLOYMENT_FORM)}
                </select>
                <InputErrorMessage error={formValidator.errors["employmentForm"]}/>
            </FormGroup>
            <FormGroup className="form-group"></FormGroup>

            {contractLengthFields}

            <FormGroup
                className="form-group"
                label="Wynagrodzenie netto z 3 miesięcy"
                labelFor="averageSalary"
            >
                <Tooltip
                    content="Wpisz przeciętne wynagrodzenie miesięczne netto liczone jako średnia arytmetyczna z 3 ostatnich miesięcy. Podaj kwotę z zaokrągleniem do pełnych złotych."
                    position={Position.TOP}>

                    <CurrencyInput
                        value={props.proposal.averageSalary}
                        onChange={currencyChangeHandler}
                        inputRef={formValidator.register({required: true})}
                        name="averageSalary"/>
                </Tooltip>

                <InputErrorMessage error={formValidator.errors["averageSalary"]}/>
            </FormGroup>

            <FormGroup
                className="form-group"
                label="Miesięczne zobowiązania kredytowe"
                labelFor="creditObligationSum"
            >
                <Tooltip
                    content="Wpisz łączną kwotę wszystkich miesięcznych  zobowiązań kredytowych (kredyty, leasingi, pożyczki). Podaj kwotę z zaokrągleniem do pełnych złotych."
                    position={Position.TOP}>
                    <CurrencyInput
                        value={props.proposal.creditObligationSum}
                        onChange={currencyChangeHandler}
                        inputRef={formValidator.register({required: true})}
                        name="creditObligationSum"/>
                </Tooltip>
                <InputErrorMessage error={formValidator.errors["creditObligationSum"]}/>
            </FormGroup>

            <h6 className="header-with-line">Dane dotyczące {employerTypeLabel}</h6>

            <FormGroup
                className="form-group"
                label={"NIP " + employerTypeLabel}
                labelFor="nip"
            >
                <input type="text"
                       ref={formValidator.register({
                           required: true,
                           pattern: /[0-9]{10}/,
                           maxLength: 10,
                           validate: value => Validator.validateNip(value)
                       })}
                       name="nip"
                       value={props.proposal.employer.nip}
                       onChange={employerChangeHandler}
                       placeholder="Np. 1234567890 (10 cyfr)"/>
                <InputErrorMessage error={formValidator.errors.nip} message="Wprowadź poprawny numer NIP (10 cyfr)"/>
            </FormGroup>

            <FormGroup
                className="form-group"
                label="Telefon kontaktowy"
                labelFor="phoneNumber"
            >
                <input type="text"
                       ref={formValidator.register({
                           required: true,
                           pattern: /[0-9]{9}/,
                           maxLength: 9
                       })}
                       name={"phoneNumber"}
                       value={props.proposal.employer.phoneNumber}
                       onChange={employerChangeHandler}
                       placeholder="Np. 753421869 (9 cyfr)"/>
                <InputErrorMessage error={formValidator.errors["phoneNumber"]}
                                   message="Podaj poprawny numer telefonu (9 cyfr)"/>
            </FormGroup>


            <FormGroup
                className="form-group"
                label="Stanowisko"
                labelFor="employeePosition"
            >
                <input type="text"
                       ref={formValidator.register({required: true})}
                       name={"employeePosition"}
                       value={props.proposal.representatives[0].employeePosition}
                       onChange={personChangeHandler}/>
                <InputErrorMessage error={formValidator.errors["employeePosition"]}/>
            </FormGroup>


        </Fragment>
    );
}

export default PersonAdditionalDataForm;