import SellerCodeReader from "./SellerCodeReader";

class UrlDecorator {

    static addFavouriteVehicles(vehicleIds) {
        const url = new URL(window.location.href);
        url.searchParams.set('vehicleId', vehicleIds);
        window.history.replaceState(null, null, url);
    }

    static addSellerCode() {
        let currentSellerCode = this.readSellerCode();

        if (currentSellerCode !== SellerCodeReader.DEFAULT_SELLER_CODE) {

            const url = new URL(window.location.href);
            url.searchParams.delete('sel_code');
            url.searchParams.set('s', currentSellerCode);
            window.history.replaceState(null, null, url);
        }
    }

    static addCustomerType(currentCustomerType) {
        const url = new URL(window.location.href);
        url.searchParams.set('customerType', currentCustomerType);

        window.history.replaceState(null, null, url);
    }

    static addUtmParameters(utm_source, utm_medium, utm_campaign) {
        const url = new URL(window.location.href);

        if(utm_source !== "none" && utm_source !== "null" && utm_source !== null){
            url.searchParams.set('utm_source', utm_source);
        }

        if(utm_medium !== "none" && utm_medium !== "null" && utm_medium !== null){
            url.searchParams.set('utm_medium', utm_medium);
        }

        if(utm_campaign !== "none" && utm_campaign !== "null" && utm_campaign !== null){
            url.searchParams.set('utm_campaign', utm_campaign);
        }

        window.history.replaceState(null, null, url);
    }

    static readSellerCode() {
        let sellerCodeReader = new SellerCodeReader();

        return sellerCodeReader.readSellerCode();
    }
}

export default UrlDecorator;