import React, {useState, useEffect} from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import goAutoLogo from "../../assets/images/content/logos/goauto_logo.svg";
import PromiseUtils from "../../utils/PromiseUtils";
import {Link} from "react-router-dom";
import ReactHtmlParser from "react-html-parser";

function BlogPostsGrid({categoryName, categoryLink, blogPosts}) {
    const [posts, setPosts] = useState(null);

    useEffect(() => {
        if(PromiseUtils.isPromise(blogPosts)) {
            blogPosts.then(setPosts)
        }else{
            setPosts(blogPosts)
        }
    }, [blogPosts])

    useEffect(() => {
        if(posts !== null && posts.length === 0){
            setLoadingPosts("Brak wpisów w tej kategorii.");
        }
    }, [posts])

    const [loadingPosts, setLoadingPosts] = useState([...Array(8)].map((i) =>
                                                        <div className="blogItem">
                                                            <Skeleton variant="rect" width={270} height={180} />
                                                            <Skeleton variant="text" width={270} />

                                                            <div className="blogTag">
                                                                <Skeleton variant="text" width={270}/>
                                                            </div>
                                                        </div>
                                                    ))

    return (
        <div className="blogPostsWrapper">
            <hr />
            <div className="blogPostsHeading flex">
                <h3>{categoryName}</h3>
                {categoryName === "Czytaj więcej" ? null : <Link to={`../../blog/category/${categoryLink}`}>Więcej <i className="fa fa-angle-right"></i></Link>}
            </div>
            <div className="blogPosts flex flex-wrap">
                {posts && posts.length > 0 ? posts.map(post => {
                    return (
                        <div className="blogItem">
                            <Link to={`../../blog/post/${post.link}`} className="blogCover"><img src={post.backgroundUrl ? post.backgroundUrl : goAutoLogo} style={{width:"100%", height:"180px"}} alt=""/></Link>
                            <Link to={`../../blog/post/${post.link}`} className="blogTitle">{<b>{ReactHtmlParser(post.title)}</b>}</Link>
                            <Link to={`../../blog/post/${post.link}`} className="blogTitle">{<p style={{textAlign: 'justify'}}>{ReactHtmlParser(post.lead)}</p>}</Link>

                            <div className="blogTags">
                                {post.tagLinks ? post.tagLinks.map(tagLink => {
                                    return <Link to={`../../blog/tag/${encodeURI(tagLink)}`} className="blogTag">{tagLink.replace(/-/g, ' ')}</Link>
                                }) : null}
                            </div>
                        </div>
                    )
                    }) : loadingPosts
                }
            </div>

        </div>
    );
}

export default BlogPostsGrid;