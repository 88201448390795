import React, {useState} from "react";

function CookiesInfo(){

    const ACCEPTED_COOKIES_KEY = "acceptedCookies";

    const [show, setShow] = useState(sessionStorage.getItem(ACCEPTED_COOKIES_KEY) === "true");

    const acceptCookiesHandler = () => {
        sessionStorage.setItem(ACCEPTED_COOKIES_KEY, "true");
        setShow(true);
    }

    if(show !== true) {

        return (
            <div id="cookies">
                <div id="cookies-box" className="container">
                    <div className="cookies-inner"><p className="cookies-text">
                        Nasz serwis wykorzystuje pliki cookies. Korzystając z naszej strony zgadasz się
                        na ich użycie zgodnie z ustawieniami swojej przeglądarki.</p>
                        <span className="as-link cookies-close" onClick={acceptCookiesHandler}>Akceptuję</span></div>
                </div>
            </div>
        );
    }

    return null;
}

export default CookiesInfo;