import React from "react";
import InstallmentCalculator from "./InstallmentCalculator";

function NajLeasingInstallmentCalculator(props) {

    const calculationEndPoints = {
        calculatorUrl: "/naj-leasing/calculation",
        allowedCalculationParametersUrl: "/naj-leasing/allowed-calculation-parameters/?priceNet="
            + props.offer.minimumPriceNet
    }

    const visibleInsuranceOptions = {
        ocAcInsurance12MonthsVisible: false,
        ocAcInsuranceAllMonthsVisible: true,
        gapInsuranceVisible: true
    }

    return (
        <InstallmentCalculator
            {...props}
            {...calculationEndPoints}
            {...visibleInsuranceOptions}
        />
    );
}

export default NajLeasingInstallmentCalculator;