import React, {Fragment, useState} from 'react';
import {Dialog, FormGroup, InputGroup, Intent} from "@blueprintjs/core";
import {goVehisService} from "../../utils/axios";
import Session from "../../utils/Session";
import sleeper from "../../utils/sleeper";
import AppToaster from "../AppToaster";

function SendEmailForm(props) {

    const [emailAddress, setEmailAddress] = useState('');
    const [agreement, setAgreement] = useState(false);

    const [formConfig, setFormConfig] = useState({
       disabled: false,
       helperText: false,
       inline:false,
       intent: Intent.NONE,
       label:true,
       requiredLabel: true
    });

    const [dialogConfig, setDialogConfig] = useState({
        autoFocus:true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        usePortal: true,

    });
    
    const handleChange = (event) => {
        setEmailAddress(event.target.value);
    }

    const handleClick = () => {
        let sessionId = Session.readSessionId();
        let vehicleOffer = props.offer;
        let email = emailAddress.toString();

        let regExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let isValid = regExp.test(emailAddress);

        if(isValid === true && agreement === true) {
            goVehisService.post("/vehicleOfferMailSender?sessionId="+sessionId, {vehicleOffer, email}).then(sleeper(5000))
                .then(response => {
                    Session.resetSession();
                }).catch(error => {
                AppToaster.show({message: "Ups! Wystąpił jakiś błąd. Spróbuj ponownie.", intent: "danger"})
            })
            AppToaster.show({message: "Pomyślnie wysłano wiadomość!", intent: "success", icon: "info-sign", timeout: 4000});
            props.close();
        } else if (isValid === false) {
            AppToaster.show({message: "Podano niepoprawny adres email!", intent: "warning", icon: "info-sign", timeout: 3000});
        } else if (isValid === true && agreement === false) {
            AppToaster.show({message: "Aby wysłać wiadomość proszę zaakceptować postanowienia RODO", intent: "warning", icon: "info-sign", timeout: 3000});
        }
    }

    const {disabled, helperText, inline, intent, label, requiredLabel} = formConfig;

    const agreementChangeHandler = (event) => {
        const checked = event.target.checked;
        setAgreement(!agreement);
    }

    return (
        <Fragment>
            <Dialog
                className="send-mail-dialog"
                title="Wyślij specyfikację na e-mail"
                {...dialogConfig}
                isOpen={props.isOpen}
                onClose={() => props.close()}
            >
                <div>
                    <FormGroup
                        disabled={disabled}
                        helperText={helperText && "Helper text with details..."}
                        inline={inline}
                        intent={intent}
                        label={label && "Adres email odbiorcy"}
                        labelFor="text-input"
                        labelInfo={requiredLabel && "(wymagane)"}>
                        <InputGroup
                            id="text-input"
                            placeholder="Podaj adres email"
                            disabled={disabled}
                            intent={intent}
                            type="email"
                            pattern="/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/"
                            required
                            value={emailAddress}
                            onChange={handleChange}/>

                            <div className="rodo">
                                <label htmlFor="agreement" className="rodo-checkbox-wrapper">
                                    <input required="required" type="checkbox" name="agreement" id="rodo-checkbox"
                                           onChange={agreementChangeHandler}
                                           value={agreement}
                                            />
                                    <span style={{textAlign:"left"}}>Wyrażam zgodę na przetwarzanie przez GO LEASING Sp. z o.o., ul. Towarowa 9F lok.49, 10-416 Olsztyn,
                                powyższych danych osobowych w celu umożliwienia kontaktu drogą telefoniczną lub elektroniczną.
                                Jestem świadoma/y, że zgoda może być odwołana w każdym czasie poprzez poinformowanie
                                GO LEASING Sp. z o.o. mailowo na adres biuro@go-leasing.pl lub pisemnie na adres podany w zakładce kontakt.
                                <a href="/rodo" target="_blank">Więcej informacji – sprawdź</a>.</span>
                                </label>
                            </div>

                            <button
                                className="bp3-button-send"
                                onClick={handleClick}
                                type="submit">
                                Wyślij
                            </button>

                    </FormGroup>
                </div>
            </Dialog>
        </Fragment>
    );
}

export default SendEmailForm;
