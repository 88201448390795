import {goVehisService} from "./axios";
import AppToaster from "../components/AppToaster";

class CallpageManager {

    static performCallOrSchedule (phoneNumber) {
        const params = {
            "tel": phoneNumber,
        };

        goVehisService.get(`/callpage/call-or-schedule?clientPhone=${phoneNumber}`, params).then(response => {
            AppToaster.show({message: "Dziękujemy! Zadzwonimy niebawem.", intent: "success"})
        }).catch(error => {
            AppToaster.show({message: "Ups! Wystąpił jakiś błąd. Spróbuj ponownie.", intent: "danger"})
        })
    }

}

export default CallpageManager;