import React, {useContext} from "react";
import CheckboxButton from "../../../components/CheckboxButton/CheckboxButton";
import FormatUtils from "../../../utils/FormatUtils";
import {CustomerTypeContext, isCustomerCompany} from "../../../context/customer-type-context";
import LabelWithInfo from "../../../components/LabelWithInfo/LabelWithInfo";

function GapCheckbox(props){

    const customerContext = useContext(CustomerTypeContext);

    return (
        props.visible && <div className="form-item form-gap">
                <p>
                    <LabelWithInfo infoCode="gap" strongTitle={true}/>
                </p>
                <div className="form-options flex">
                    <CheckboxButton
                        checked={props.checked}
                        title="Na cały okres umowy"
                        disabled={props.disabled}
                        label={"+" + FormatUtils.formatMoney(isCustomerCompany(customerContext) ?
                            props.insuranceFees.gapInsuranceNet : props.insuranceFees.gapInsuranceGross)}
                        onChange={props.onChange}
                    />
                </div>
            </div>

    );
}

export default GapCheckbox;