import React, {Fragment} from "react";
import {Card, Elevation} from "@blueprintjs/core";
import Agreements from "../Agreements";
import CompanyInitialForm from "./CompanyInitialForm";
import withFormErrorHandler from "../withFormErrorHandler";
import PersonInitialForm from "./PersonInitialForm";

function StepForInitialData(props) {

    const agreementsChangeHandler = agreements => props.onChange({...props.offerWithCalculation, agreements: agreements});

    const onNextClickHandler = () => {
        props.onCheckErrorHandler(props.onNextClick);
    }

    let formFields = props.isCompany ? (<CompanyInitialForm {...props}/>) : (<PersonInitialForm {...props}/>);

    return (
        <Fragment>

            <div className="config-modal-body">

                <form className="order-form">
                    <Card elevation={Elevation.ONE} className="form-section">
                        <p className="first">Cieszymy się, że zainteresowałeś się naszą ofertą.<br/>
                        Teraz szybko, prosto, wygodnie i bezpiecznie:</p>
                        <ul>
                            <li><strong>zarezerwujesz</strong> na naszym stocku wybrany przez Ciebie
                                samochód</li>
                            <li><strong>złożysz wniosek</strong> leasingowy w 5 min.</li>
                            <li><strong>otrzymasz wstępną decyzję leasingową</strong> w 10 s.</li>
                            <li><strong>skontaktujemy się z Tobą</strong>, aby potwierdzić Twoje zamówienie i umówić się na podpisanie umowy .</li>
                        </ul>
                        <p><strong><span style={{color: "rgb(255, 86, 48)"}}>PAMIĘTAJ:</span></strong><br/>
                        <strong>Do momentu zawarcia umowy możesz zrezygnować z rezerwacji samochodu i dalszych działań.</strong><br/>
                        </p>

                        <p className="last">Zaczynamy!</p>

                    </Card>
                    <Card elevation={Elevation.ONE} className="form-section three-col">
                        {formFields}
                        <Agreements
                            onChange={agreementsChangeHandler}
                            formValidation={props.formValidator}
                            checkedAgreements={props.checkedAgreements}
                            hiddenAgreements={[1]}
                        />
                    </Card>
                </form>
                <div className="clearfix"></div>

            </div>


            <div className="config-modal-footer">

                <div className="flex">
                    <button type="button" className="lastButton" onClick={onNextClickHandler}>Dalej</button>
                </div>

            </div>
        </Fragment>
    );
}

export default withFormErrorHandler(StepForInitialData);