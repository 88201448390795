import React from "react";

import Routes from "./containers/Layout/Routes"
import DynamicSitemap from "react-dynamic-sitemap";

export default function Sitemap() {

    return (
        <DynamicSitemap routes={Routes}
                        prettify={true}/>
    );
}