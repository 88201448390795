import React, {useEffect, useRef, useState} from "react";


function ProductSubmenu(props){

    const [active,setActive] = useState(false);
    const wrapperRef = useRef(null);

    const closeChildHandler = () => {
        setActive(false);
        props.onClose();
    }

    /**
     * Hook that alerts clicks outside of the passed ref
     */
    function useOutsideAlerter(ref) {
        useEffect(() => {
            let allowedClassNames = ["checkbox-label","bp3-menu","bp3-text-overflow-ellipsis bp3-fill"];

            /**
             * Alert if clicked on outside of element
             */
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target) && event.target.parentElement != null) {

                    if(allowedClassNames.indexOf(event.target.className) === -1 && allowedClassNames.indexOf(event.target.parentElement.className) === -1) {
                        setActive(false);
                   }

                }
            }

            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);

    }

    useOutsideAlerter(wrapperRef);

    return (
        <div ref={wrapperRef} className={"product-menu-item " + (active ? "active" : "")} id={props.id}>
            <div onClick={() => {setActive(prevState => !prevState)}}>{props.name}</div>
            <div className="product-sub-menu">
                {React.cloneElement(props.children, { onClose: closeChildHandler })}
            </div>
        </div>
    );
}

export default ProductSubmenu;