import React, {useContext} from "react";
import CustomerTypeSwitch from "../../../components/CustomerTypeSwitch/CustomerTypeSwitch";
import {CustomerTypeContext, isCustomerCompany} from "../../../context/customer-type-context";
import FormatUtils from "../../../utils/FormatUtils";
import MathUtils from "../../../utils/MathUtils";

function BaseInstallment({installment, offer, label, isCreditCalculation}) {

    const customerContext = useContext(CustomerTypeContext)

    let discountRate = 1 + MathUtils.calculateOfferDiscountPercentage(offer) / 100;

    let basicInstallment = isCustomerCompany(customerContext) ? installment.net * discountRate : installment.gross * discountRate;
    let minInstallment = isCustomerCompany(customerContext) ? installment.net : installment.gross;
    let installmentDiscount = basicInstallment - minInstallment;

    let taxType = isCreditCalculation ? "brutto" : isCustomerCompany(customerContext) ? "netto" : "brutto";
    let showDiscount = offer.discountVisible && !offer.rental;

    let specialOfferClassName = showDiscount ? "special-offer" : "";

    return (
        <div className="before-form ">
            <div className="full-price grid">
                <span className="before-price"><strong>{label}</strong></span>
                <span className="basic-installment">{showDiscount && FormatUtils.formatMoney(basicInstallment, 0)}</span>
                <span className="min-installment">{FormatUtils.formatMoney(minInstallment, 0)}</span>
                <small>{taxType} / mc</small>
                <span
                    className={"installment-discount " + specialOfferClassName}>{showDiscount && <> Taniej o {FormatUtils.formatMoney(installmentDiscount, 0)}</>}</span>

            </div>
        </div>
    );
}

export default BaseInstallment;