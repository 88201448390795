/**
 * Map of contract length and buyout percentage
 */
const contractLengthBuyoutMap = {
    24: 50,
    36: 40,
    48: 30,
    60: 25,
    72: 15,
    84: 5
}

export default contractLengthBuyoutMap;