import React from "react";
import InstallmentCalculator from "./InstallmentCalculator";

function LeasingOnlineInstallmentCalculator(props) {

    const calculationEndPoints = {
        calculatorUrl: "/leasingonline/calculation",
        allowedCalculationParametersUrl: "/leasingonline/allowed-calculation-parameters/" +
            "?vehicleType=" + props.offer.vehicleType +
            "&netValue=" + props.offer.minimumPriceNet
    }

    const visibleInsuranceOptions = {
        ocAcInsurance12MonthsVisible: false,
        ocAcInsuranceAllMonthsVisible: false,
        gapInsuranceVisible: false
    }

    return (
        <InstallmentCalculator
            {...props}
            {...calculationEndPoints}
            {...visibleInsuranceOptions}
        />
    );
}

export default LeasingOnlineInstallmentCalculator;