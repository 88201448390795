import React, {useState} from "react";
import {Drawer, Position, Radio, RadioGroup} from "@blueprintjs/core";
import {sortTypes} from "./SortTypes";
import {withRouter} from "react-router";

function SortDrawer(props) {

    const initialSearchParams = () => {
        let urlSearchParams = new URLSearchParams(props.location.search);

        if (urlSearchParams.get("sortingType") == null) {
            return sortTypes[0].value;
        }

        return urlSearchParams.get("sortingType");
    }

    const [selectedValue, setSelectedValue] = useState(() => {return initialSearchParams();});

    const sortOrderChangedHandler = event => setSelectedValue(event.currentTarget.value);

    const sortButtonClickedHandler = () => {

        let urlSearchParams = new URLSearchParams(props.location.search);
        urlSearchParams.set("sortingType",selectedValue);

        props.history.push({
            pathname: props.location.pathname,
            search: urlSearchParams.toString()
        });

        props.onClose();
    }

    let radioButtons = sortTypes.map(sortType => (
        <Radio key={sortType.value} label={sortType.label} value={sortType.value}/>
    ));

    return (
        <Drawer
            {...props}
            size={Drawer.SIZE_SMALL}
            position={Position.RIGHT}
            canOutsideClickClose={true}
        >
            <div className="offer-list-drawer">
                <RadioGroup
                    onChange={sortOrderChangedHandler}
                    selectedValue={selectedValue}
                >
                    {radioButtons}
                </RadioGroup>

                <button type="button" onClick={sortButtonClickedHandler}>Wybierz sortowanie</button>
            </div>

        </Drawer>
    );
}

export default withRouter(SortDrawer);