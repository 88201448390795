import React, {useState} from "react";
import {useHistory} from "react-router";
import URLSearchParamsConverter from "../../utils/URLSearchParamsConverter";
import CheckboxColumnList from "../CheckboxColumnList/CheckboxColumnList";

function BrandsSubmenu(props) {

    const [selectedBrands, setSelectedBrands] = useState([]);

    const history = useHistory();


    const brandSelectedHandler = selectedBrands => setSelectedBrands(selectedBrands.map(brand => brand.name));

    const searchButtonClickHandler = () => {
        let searchParameters = {
            brands: selectedBrands
        }

        history.push({
            pathname: "/lista-ofert",
            search: URLSearchParamsConverter.createURLSearchString(searchParameters)
        });

        setSelectedBrands([])
        props.onClose();
    }



    return (
        <form id="szukaj-marki-form" action="">
            <CheckboxColumnList
                resetItems={selectedBrands.length === 0}
                columnCount={4}
                items={props.brands}
                onChange={brandSelectedHandler}
            />

            <div className="form-line">
                <button type="button" onClick={searchButtonClickHandler}>Szukaj</button>
            </div>
        </form>

    );
}

export default BrandsSubmenu;