import React, {useContext, useEffect} from "react";
import {CustomerTypeContext, isCustomerCompany} from "../../context/customer-type-context";
import FormatUtils from "../../utils/FormatUtils";
import ConvertUtils from "../../utils/ConvertUtils";

import bodyType from "../../assets/images/icons/car/suv.svg";
import engine from "../../assets/images/icons/car/engine.svg";
import fuelType from "../../assets/images/icons/car/petrol.svg";
import gearbox from "../../assets/images/icons/car/gearbox.svg";
import {Icon, Intent} from "@blueprintjs/core";
import FavouriteVehicle from "../FavouriteVehicles/FavouriteVehicle";
import {IconNames} from "@blueprintjs/icons";
import DiscountPercentageRibbonFactory from "../../utils/DiscountPercentageRibbonFactory";
import {Link} from "react-router-dom";
import AppToaster from "../AppToaster";
import CalculationTypeLabel from "./CalculationTypeLabel";

function VehicleOfferListItem(props) {

    const customerContext = useContext(CustomerTypeContext);

    let title = props.offer.brandName + " " + props.offer.modelName;
    if (props.offer.versionName !== undefined) {
        title = props.offer.brandName + " " + props.offer.versionName;
    }

    let discountPercentageComponent = null;

    if(props.offer.discountVisible){
        DiscountPercentageRibbonFactory.setOffer(props.offer);
        DiscountPercentageRibbonFactory.setIsCompany(isCustomerCompany(customerContext));

        if(props.showRibbons){
            DiscountPercentageRibbonFactory.setStyleClassName("ribbon-hot-text-grid-view");
        }else{
            DiscountPercentageRibbonFactory.setStyleClassName("ribbon-hot-text-list-view");
        }

        discountPercentageComponent = DiscountPercentageRibbonFactory.create();
    }

    let inStockLabel = null;
    let inStockLabelOnImage = null;
    if (props.offer.availability === "IN_STOCK" || props.offer.availability.includes("IN_STOCK")) {
        inStockLabel = (<div className="ribbon od-reki-label">Od ręki</div>);
        inStockLabelOnImage = (<span className="ribbon-od-reki-grid"></span>);
    }

    let overlay = null;
    if (props.active === false) {
        overlay = <div onClick={() => props.onClick(props.offer, false)} className="offer-not-active"></div>;
    }

    const deleteSoldVehicleHandler = (event) => {
        event.preventDefault()

        let retrievedVehicles = localStorage.getItem('favouriteVehicles');
        let retrievedVehiclesToArray = JSON.parse(retrievedVehicles);

        retrievedVehiclesToArray = deleteAllVersionIds(retrievedVehiclesToArray, props.offer.vehicleIds)
        let afterEliminatedDuplicates=[...new Set(retrievedVehiclesToArray)];

        localStorage.setItem('favouriteVehicles', JSON.stringify(afterEliminatedDuplicates));

        AppToaster.show({message: 'Usunięto z ulubionych', intent: "warning", icon: "info-sign", timeout: 1500});

        props.refreshVehicles()
    }

    const deleteAllVersionIds = (vehiclesArray, vehicleIds) => {
        if(!vehicleIds){
            return vehiclesArray
        }

        if(!Array.isArray(vehicleIds)){
            vehiclesArray = vehiclesArray.filter( existingId => existingId !== vehicleIds.toString() )
        }else{
            vehicleIds.forEach(vehicleId => {
                vehiclesArray = vehiclesArray.filter( existingId => existingId !== vehicleId.toString() )
            })
        }

        return vehiclesArray
    }

    const displayDeleteButtonHandler = () => {
        if(props.deleteButton === true) {
            return (
                    <div className="close-icon-sold-offer">
                        <Icon icon={IconNames.CROSS} iconSize={Icon.SIZE_LARGE} intent={Intent.PRIMARY} onClick={deleteSoldVehicleHandler} />
                    </div>
            );
        }
    }

    const isOfferCredit = () => {
        const condition = props.offer.condition;
        const salesDocument = props.offer.salesDocument;

        if(condition === "USED" && salesDocument !== "FAKTURA_VAT" ||
            condition === "USED" && salesDocument === "FAKTURA_VAT" && !isCustomerCompany(customerContext) ||
            condition === "DEMO" && salesDocument !== "FAKTURA_VAT" ||
            condition === "DEMO" && salesDocument === "FAKTURA_VAT" && !isCustomerCompany(customerContext)) {
            return true;
        }

        return false;
    }

    const shouldShowNetto = () => {
        if(isOfferCredit()) {
            return false;
        }

        return isCustomerCompany(customerContext)
    }


    return (
        <Link to={{pathname: props.url}} className={props.className + " offer"} style={{textDecoration: "none"}}>
            {overlay}
            {displayDeleteButtonHandler()}
            <div className="offer-top">
                <span className="offer-title">
                    {title}
                </span>
                <span className="offer-points">
                    <span>
                        <img src={bodyType} className="description-icon" alt="Nadwozie"
                             title="Nadwozie"/>{props.offer.bodyType}
                    </span>
                    <span>
                        <img src={gearbox} className="description-icon" alt="Skrzynia biegów"
                             title="Skrzynia biegów"/> {props.offer.gearboxType}
                    </span>
                        <span>
                         <img src={engine} className="description-icon" alt="Silnik"
                              title="Silnik"/>{ConvertUtils.capacityFromCmToDm(props.offer.engineCapacity)} dm<sup>3</sup>
                            &nbsp;({props.offer.enginePowerHP} KM)
                    </span>
                    <span>
                        <img src={fuelType} className="description-icon" alt="Paliwo"
                             title="Paliwo"/> {props.offer.fuelType}
                    </span>
                </span>
            </div>
            <div className="offer-img">
                <div>
                    <img src={props.offer.imageUrl} alt={title}/>
                    {props.showRibbons && discountPercentageComponent}
                    {props.showRibbons && <CalculationTypeLabel calculationType={props.offer.calculationType} viewType="grid-view" /> }
                    {props.showRibbons && inStockLabelOnImage}
                </div>
            </div>
            <div className="offer-list-view">
                {props.isSliderOffer ? null : discountPercentageComponent}
                {!props.isSliderOffer && <CalculationTypeLabel calculationType={props.offer.calculationType} viewType="list-view" /> }
                {props.isSliderOffer ? null : inStockLabel}
            </div>

            <div className="offer-bottom flex">
                            <span className="full-price">
                                <span>{FormatUtils.formatMoney(shouldShowNetto() ? props.offer.installmentNet : props.offer.installmentGross)}</span>
                                <small>miesięcznie</small>
                            </span>

                <FavouriteVehicle
                    offer={props.offer}
                    refreshVehicles={() => props.refreshVehicles()}
                    deleteAllVersionIds={deleteAllVersionIds}
                />

                <button className="btn offer-btn">{props.isOpen ? "Ukryj" : "Zobacz"}</button>
            </div>
        </Link>
    );
}

export default VehicleOfferListItem;