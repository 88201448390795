import React, {Suspense, useEffect, useState} from 'react';
import {Redirect, Route, Switch} from "react-router";
import {Spinner} from "@blueprintjs/core";
import ContactPage from "../ContactPage/ContactPage";
import FaqPage from "../FaqPage/FaqPage";
import OfferListPage from "../OfferListPage/OfferListPage";
import FavouritesVehiclePage from "../OfferListPage/FavouritesVehiclePage";
import ChoosenVehiclePage from "../OfferListPage/ChoosenVehiclePage";
import SearchConfigurator from "../SearchConfigurator/SearchConfigurator";
import BlogPage from "../BlogPage/BlogPage";
import Sitemap from "../../Sitemap";
import BlogPost from "../BlogPage/BlogPost";
import BlogCategoryPage from "../BlogPage/BlogCategoryPage";
import BlogTagPage from "../BlogPage/BlogTagPage";
import OfferPage from "../OfferPage/OfferPage";
import HomePage from "../HomePage/HomePage";
import BlogDataAccess from "../../utils/BlogDataAccess";
import {goVehisService} from "../../utils/axios";
import ConvertUtils from "../../utils/ConvertUtils";
import AkolRedirectPage from "../AkolRedirectPage/AkolRedirectPage";
const SimplePage = React.lazy(() => import("../SimplePage/SimplePage"))


export default function Routes({seller, backgroundImage}) {
    const [blogCategories, setBlogCategories] = useState(null)
    const [blogTags, setBlogTags] = useState(null)
    const [blogPostsLinks, setBlogPostsLinks] = useState(null)
    const [offersLinkParams, setOffersLinkParams] = useState(null)

    const blogDataAccess = new BlogDataAccess();

    useEffect(() => {
        if(window.location.pathname === "/sitemap"){
            getDynamicLinkParams()
        }
    }, [window.location.pathname])

    const getDynamicLinkParams = () => {
        blogDataAccess.getAvailableTags().then(tags => {
            setBlogTags(mapToJsonSlug(tags, 'tagLink'))
        })

        blogDataAccess.getAvailableCategories().then(categories => {
            setBlogCategories(mapToJsonSlug(categories, 'categoryLink'))
        })

        blogDataAccess.getNewPosts().then(posts => {
            const postsLinks = new Array()

            posts.forEach(post => {
                postsLinks.push(post.link)
            })

            setBlogPostsLinks(arrayToJsonSlug(postsLinks, "postLink"))
        })

        goVehisService.get("/vehicles/linkParams").then(response => {
            const linkParams = new Array();

            response.data.forEach(params => linkParams.push(convertParamsToUrlForm(params)))

            setOffersLinkParams(linkParams)
        })
    }

    const convertParamsToUrlForm = (params) => {

        return {
            brand: ConvertUtils.stringToUrl(params.brand),
            version: ConvertUtils.stringToUrl(params.version),
            id: params.id,
        }

    }

    const arrayToJsonSlug = (arr, slugName) => {
        const jsonSlugsArray = new Array()

        arr.forEach(value => {
            jsonSlugsArray.push({[slugName]: value})
        })

        return jsonSlugsArray
    }

    const mapToJsonSlug = (map, slugName) => {
        const jsonSlugsArray = new Array()
        const mapIterator = map.keys()

        let value = mapIterator.next().value

        while(value) {
            jsonSlugsArray.push({[slugName]: value})
            value = mapIterator.next().value
        }

        return jsonSlugsArray
    }

    return (
        <Switch>
            <Redirect from={"/home"} to="/"/>
            <Route path="/kontakt" sitemapIndex={true} priority={1} changefreq={"monthly"}>
                <Suspense fallback={<Spinner/>}>
                    <ContactPage seller={seller}/>
                </Suspense>
            </Route>
            <Route path="/faq" sitemapIndex={true} priority={1} changefreq={"monthly"}>
                <Suspense fallback={<Spinner/>}>
                    <FaqPage/>
                </Suspense>
            </Route>
            <Route path="/lista-ofert" sitemapIndex={true} priority={1} changefreq={"daily"}>
                <Suspense fallback={<Spinner/>}>
                    <OfferListPage/>
                </Suspense>
            </Route>
            <Route path="/ulubione" sitemapIndex={true} priority={0.8} changefreq={"daily"}>
                <Suspense fallback={<Spinner/>}>
                    <FavouritesVehiclePage/>
                </Suspense>
            </Route>
            <Route path="/oferta" sitemapIndex={true} priority={1} changefreq={"daily"}>
                <Suspense fallback={<Spinner/>}>
                    <ChoosenVehiclePage/>
                </Suspense>
            </Route>
            <Route path="/konfiguruj" exact={true} sitemapIndex={true} priority={1} changefreq={"monthly"}>
                <Suspense fallback={<Spinner/>}>
                    <SearchConfigurator/>
                </Suspense>
            </Route>
            <Route path="/blog" exact={true} sitemapIndex={true} priority={1} changefreq={"daily"}>
                <Suspense fallback={<Spinner/>}>
                    <BlogPage backgroundImage={backgroundImage}/>
                </Suspense>
            </Route>
            <Route path="/blog/post/:postLink" exact={true} sitemapIndex={true} priority={0.8} changefreq={"daily"} slugs={blogPostsLinks}>
                <Suspense fallback={<Spinner/>}>
                    <BlogPost backgroundImage={backgroundImage}/>
                </Suspense>
            </Route>
            <Route path="/blog/category/:categoryLink" exact={true} sitemapIndex={true} priority={0.8} changefreq={"daily"} slugs={blogCategories}>
                <Suspense fallback={<Spinner/>}>
                    <BlogCategoryPage backgroundImage={backgroundImage}/>
                </Suspense>
            </Route>
            <Route path="/blog/tag/:tagLink" exact={true} sitemapIndex={true} priority={0.8} changefreq={"daily"} slugs={blogTags}>
                <Suspense fallback={<Spinner/>}>
                    <BlogTagPage backgroundImage={backgroundImage}/>
                </Suspense>
            </Route>
            <Route path="/akol" sitemapIndex={false}>
                <Suspense fallback={<Spinner/>}>
                    <AkolRedirectPage/>
                </Suspense>
            </Route>
            <Route path="/:brand/:version/:id" sitemapIndex={true} priority={0.5} changefreq={"daily"} slugs={offersLinkParams}>
                <Suspense fallback={<Spinner/>}>
                    <OfferPage/>
                </Suspense>
            </Route>
            <Route path="/:url" sitemapIndex={false}>
                <Suspense fallback={<Spinner/>}>
                    <SimplePage/>
                </Suspense>
            </Route>
            <Route path="/" sitemapIndex={true} priority={1} changefreq={"daily"}>
                <HomePage/>
            </Route>
        </Switch>
    )
}