import React, {Fragment,useState} from "react";
import ConfiguratorHeader from "../SearchConfigurator/ConfiguratorHeader";
import envelopeIcon from "../../assets/images/icons/envelope.svg";
import ContactForm from "../../components/ContactForm/ContactForm";
import {Dialog} from "@blueprintjs/core";
import ContactDialog from "../../components/ContactDialog/ContactDialog";

function ReservationHeader({currentNumber, onStepChange, onBackToOffer}){
    const [isOpenContactDialog, setIsOpenContactDialog] = useState(false);

    const contactClickHandler = () => {
        setIsOpenContactDialog(true);
    }

    return (
        <Fragment>
            <ConfiguratorHeader
                currentNumber={currentNumber}
                onStepChange={onStepChange}
                allowClickOnStep={false}
                stepTitles={["Start", "Dane podstawowe", "Dane dodatkowe", "Decyzja"]}>

                <button type="button"
                        className="back back-to-offer"
                        onClick={onBackToOffer}>
                    <i className="fa fa-angle-left"></i>Wróć do oferty</button>

                <button type="button"
                        onClick={contactClickHandler}
                        className="back back-to-offer request">
                    <i className="fa fa-comments"></i> Zapytaj
                </button>

            </ConfiguratorHeader>
            <ContactDialog isOpen={isOpenContactDialog}
                           onClose={() => setIsOpenContactDialog(false)} />
        </Fragment>
    );
}

export default ReservationHeader;