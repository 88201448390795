import React from 'react';
import menuIcon from "../../assets/images/icons/menu.svg";
import Navigation from "../Navigation/Navigation";

function MainMenu(props){


    return (
        <div id="main-menu-wrapper">
            <div id="menuToggle" onClick={props.clicked}><img src={menuIcon} alt="menu"/> Menu</div>
            <Navigation show={props.showNavigation} closeNavigationClicked={props.closeNavigationClicked}/>
        </div>
    );

}

export default MainMenu;