import React, {useContext, useEffect, useRef, useState} from "react";
import {goVehisService} from "../../utils/axios";
import OwlCarousel from "react-owl-carousel";
import ConvertUtils from "../../utils/ConvertUtils";
import {CustomerTypeContext} from "../../context/customer-type-context";
import VehicleOfferListItem from "../../components/VehicleOfferListItem/VehicleOfferListItem";
import {Link, useHistory} from "react-router-dom";
import {useMediaQuery} from "react-responsive";

function SpecialOffers() {

    const MAX_SPECIAL_OFFERS_COUNT = 12;

    const [specialOffers, setSpecialOffers] = useState([]);
    const owlCarouselRef = useRef(null);
    const currentCustomerType = useContext(CustomerTypeContext)[0];
    const isMobile = useMediaQuery({maxWidth: 767});
    const history = useHistory();

    useEffect(() => {
        goVehisService.get("vehicles/specialoffers?customerType=" + currentCustomerType)
            .then(response => setSpecialOffers(response.data.slice(0, MAX_SPECIAL_OFFERS_COUNT)))
    }, [currentCustomerType]);

    useEffect(() => {
        if (owlCarouselRef.current != null) {
            owlCarouselRef.current.forceUpdate();
        }
    }, [currentCustomerType]);

    if (specialOffers.length > 0) {
        let offers = specialOffers.map(offer => (
            <VehicleOfferListItem
                isSliderOffer={true}
                showRibbons={true}
                key={offer.id}
                url={"/" + ConvertUtils.stringToUrl(offer.brandName + "/" + offer.versionName + "/" + offer.id)}
                offer={offer}
                className="hot-offer"
            />
        ));

        return (
            <div id="hot-offers">
                <h3 className="center">Sprawdź gorące oferty miesiąca!</h3>
                <div id="hot-offers-bg">


                    <div className="container">
                        <OwlCarousel
                            ref={owlCarouselRef}
                            id="hot-offers-slider"
                            loop={true}
                            margin={30}
                            nav={true}
                            dots={!isMobile}
                            mouseDrag={false}
                            lazyLoad={true}
                            navText={["<", ">"]}
                            responsive={{
                                0: {items: 1, margin: 0},
                                480: {items: 1},
                                768: {items: 2},
                                1024: {items: 3},
                                1199: {items: 4}
                            }}
                        >
                            {offers}

                        </OwlCarousel>

                    </div>
                </div>
                <Link to={{pathname: "/lista-ofert", search: "specialOffer=true"}} className="btn" id="hot-offers-btn">
                    Wszystkie Hot oferty</Link>
            </div>
        );

    }

    return null;

}

export default SpecialOffers;